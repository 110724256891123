<div class="main class">
  <div class="container subDiv p-xl-3 p-1">
    <div class="innerDiv p-xl-4 p-2">
      <!-- <h3 class="titlebar">USAGE REPORT</h3> -->
      <div class="formTable row">
        <div class="col-md-2 mt-3"><p>Select School</p></div>
        <div class="col-md-5 mt-3">
          <ng-multiselect-dropdown
            [placeholder]="'Select School'"
            [settings]="dropdownSettings"
            [(ngModel)]="schoolDetails"
            [data]="schoolDisplayList"
          >
          </ng-multiselect-dropdown>
        </div>
        <div class="col-md-2 mt-3">
          <button class="btn btn-primary" (click)="getUsageReport()">
            Find
          </button>
        </div>
      </div>
    </div>
    <p class="blueTitle my-3">Usage Report</p>
    <!-- <div class="innerDiv p-xl-4 p-2 bg-white-table"> -->
    <div *ngIf="allUsage.length > 0" class="row-sm mb-3">
      <button class="btn btn-warning commonBtn" (click)="downloadExcel()">
        <i class="fa fa-solid fa-download"></i> Download Excel
      </button>
    </div>
    <div class="col-md-12 showhideTable1">
      <table class="display example1" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th>School Id</th>
            <th>School Name</th>
            <th>Student Name</th>
            <th>Student Id</th>
            <th>Total Series Read</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>
      <!-- </div> -->
    </div>
  </div>
</div>
<page-loader *ngIf="showLoader" [loaderMsg]="loaderMessage"></page-loader>

<div
  class="modal fade"
  id="errorModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="errorModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body" style="text-align: center">
        <p class="blueTitle my-3">Detailed Report</p>
        <div>
          <table style="width: 100%">
            <tr>
              <th>Series Title</th>
              <th>Series Count</th>
              <th>Series Last Seen</th>
              <th>Minutes Read</th>
              <!-- <th>Seconds Read</th> -->
            </tr>
            <tr *ngFor="let doc of details">
              <td>{{ doc.title }}</td>
              <td>{{ doc.count }}</td>
              <td>{{ doc.lastSeenDate.split("T")[0] }}</td>
              <td>{{ doc.min }}</td>
              <!-- <td>{{ doc.sec }}</td> -->
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
