<div class="br-pagebody" style="background-color:white;">

  <button type="button" class="btn btn-primary m-2" (click)="moveToSchoolInfo()"><i class="fa fa-list"
      aria-hidden="true"> </i>School Info</button>
      <button type="button" class="btn btn-primary m-2" (click)="userList()"><i class="fa fa-list"
        aria-hidden="true"> </i> User List</button>
  <!-- <button type="button" class="btn btn-primary m-2" (click)="moveToAttendeeList()"><i class="fa fa-child" aria-hidden="true"> Attendance</i></button> -->
  <br>

<div class="card ">
    <div class="card-body">
      <h5 class="card-title">User Information Page</h5>
      <div class="col-md-12">
        <form #f="ngForm">
            <div class="row">
                <div class="col-md-3"><strong>Email Id</strong></div>
                <!-- <div class="col-md-2"><strong>Zoom Free</strong></div> -->
                <div class="col-md-3"><strong>Client Id</strong></div>
                <div class="col-md-3"><strong>Client Secret</strong></div>
               
            </div>
      
            <div class="row loopRowDiv">  
      
                
                
                <div class="form-group col-md-3">
                    <input type="text" #email="ngModel" name="emailId" [(ngModel)]="user.email" class="form-group col-md-12">
                </div>
                <div class="form-group col-md-3">
                    <input type="text" #clientId="ngModel" name="clientid" [(ngModel)]="user.clientId" class="form-group col-md-12">
                </div>
                <div class="form-group col-md-3">
                    <input type="text" #clientSecret="ngModel" name="clientSecret" [(ngModel)]="user.clientSecret" class="form-group col-md-12">
                </div>
                <div class="form-group col-md-2">
                    <button type="button" class="btn btn-primary" (click)="saveDetails()"><i class="fas fa-save"> </i>Save</button>
                  </div>
            </div>
            
        </form>

      </div>
    </div>
  </div>
  </div>
  
