<div class="container">
  <div class="row mt-2 mb-3">
    <div class="col-12 col-md-12 parent d-flex">
      <div class="col-3 col-md-3">
        <h6 id="sub-heading">Exam Name</h6>
        <div class="btndiv">
          {{ searchObj.examName }}
        </div>
      </div>
      <div class="col-2 col-md-2">
        <h6 id="sub-heading">Maximum marks</h6>
        {{ searchObj.maxMarks }}
      </div>

      <div class="col-2 col-md-2">
        <h6 id="sub-heading">Maximum Time</h6>
        {{ searchObj.maxHours }}h
      </div>

      <div class="col-2 col-md-2">
        <h6 id="sub-heading">Total Questions</h6>
        {{ searchObj.totalQuestion }}
      </div>

      <div class="col-3 col-md-3">
        <h6 id="sub-heading">Marks Alloted</h6>
        {{ searchObj.marksAlloted }}
      </div>
    </div>
  </div>
</div>
<hr />
<div class="container question-section" *ngIf="!isEdit">
  <div class="row my-2 scroll question">
    <h5>Subject: {{ searchObj.subject }}, Topic: {{ searchObj.topic }}</h5>
    <div class="topbar">
      <h5 style="float: left">
        Select Questions
        <span *ngIf="searchObj.marksAlloted > searchObj.maxMarks"
          >Maximum marks crossed ({{ searchObj.maxMarks }}) - Remove some
          questions</span
        >
      </h5>
      <div class="editBtn">
        <button
          type="button"
          class="btn mr-2"
          *ngIf="searchObj.totalQuestion > 0"
          id="remove"
          (click)="editQuestion()"
        >
          Remove Question
        </button>
        <button
          type="button"
          class="btn me-2"
          id="qlistbtn"
          style="float: right"
          data-bs-toggle="modal"
          data-bs-target="#questionModal"
          (click)="addQuestionList()"
        >
          Add to Question Set
        </button>
        <button
          *ngIf="searchObj.marksAlloted == searchObj.maxMarks"
          type="button"
          class="btn btn-success me-2"
          style="float: right; margin-left: 5px"
          (click)="checkQuestionTotal()"
        >
          Create
        </button>
        <button
          type="button"
          class="btn"
          *ngIf="searchObj.marksAlloted < searchObj.maxMarks"
          id="remove"
          (click)="goToSelectMore()"
        >
          Change Topic/Chapter
        </button>
      </div>
    </div>
  </div>

  <div class="row mt-3 scroll">
    <div *ngIf="isMcq">
      <h5 class="mb-3">Multiple Choice Question</h5>
      <div *ngFor="let ques of questionNeList; let ind = index">
        <div *ngIf="ques.questionType == 'MCQ'" class="form-check">
          <div>
            <input
              *ngIf="!ques.selected"
              [class]="
                ques.questionType == 'MCQ'
                  ? 'hid form-check-input'
                  : 'form-check-input'
              "
              type="checkbox"
              id="flexCheckDefault"
              type="checkbox"
              value=""
              [id]="ques._id"
              (change)="markCheck(ques, $event)"
            />

            <span [class]="ques.selected ? 'grey-color fw-bold' : 'fw-bold'"
              >Q.{{ ind + 1 }}
            </span>
            <span
              [class]="
                ques.selected
                  ? 'grey-color form-check-label'
                  : 'form-check-label'
              "
              for="flexCheckDefault"
              [innerText]="ques.questionText"
            >
            </span>
            <br />
            <img
              *ngIf="ques.isImage && ques.imageUrl && ques.imageUrl != ''"
              [src]="ques.imageUrl"
            />

            <br *ngIf="ques.isImage && ques.imageUrl && ques.imageUrl != ''" />

            <ol type="A" style="margin-left: 20px">
              <li *ngFor="let option of ques.options">
                <img
                  *ngIf="option.optionText.includes('http')"
                  [src]="option.optionText"
                />
                <label
                  [class]="ques.selected ? 'grey-color' : ''"
                  *ngIf="!option.optionText.includes('http')"
                  >{{ option.optionText }}</label
                >
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isLa">
      <h5 class="mb-3">Long Question Answer</h5>
      <div *ngFor="let ques of questionNeList; let ind = index">
        <div *ngIf="ques.questionType == 'LA'" class="form-check">
          <div>
            <input
              *ngIf="!ques.selected"
              [class]="
                ques.questionType == 'LA'
                  ? 'hid form-check-input'
                  : 'form-check-input'
              "
              type="checkbox"
              id="flexCheckDefault"
              type="checkbox"
              value=""
              [id]="ques._id"
              (change)="markCheck(ques, $event)"
            />

            <span [class]="ques.selected ? 'grey-color fw-bold' : 'fw-bold'"
              >Q.{{ ind + 1 }}
            </span>
            <span
              [class]="
                ques.selected
                  ? 'grey-color form-check-label'
                  : 'form-check-label'
              "
              for="flexCheckDefault"
              [innerText]="ques.questionText"
            >
            </span>
            <br />
            <img
              *ngIf="ques.isImage && ques.imageUrl && ques.imageUrl != ''"
              [src]="ques.imageUrl"
            />

            <br *ngIf="ques.isImage && ques.imageUrl && ques.imageUrl != ''" />

            <ol type="A" style="margin-left: 20px">
              <li *ngFor="let option of ques.options">
                <img
                  *ngIf="option.optionText.includes('http')"
                  [src]="option.optionText"
                />
                <label
                  [class]="ques.selected ? 'grey-color' : ''"
                  *ngIf="!option.optionText.includes('http')"
                  >{{ option.optionText }}</label
                >
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isSa">
      <h5 class="mb-3">Short Question Answer</h5>
      <div *ngFor="let ques of questionNeList; let ind = index">
        <div *ngIf="ques.questionType == 'SA'" class="form-check">
          <div>
            <input
              *ngIf="!ques.selected"
              [class]="
                ques.questionType == 'SA'
                  ? 'hid form-check-input'
                  : 'form-check-input'
              "
              type="checkbox"
              id="flexCheckDefault"
              type="checkbox"
              value=""
              [id]="ques._id"
              (change)="markCheck(ques, $event)"
            />

            <span [class]="ques.selected ? 'grey-color fw-bold' : 'fw-bold'"
              >Q.{{ ind + 1 }}
            </span>
            <span
              [class]="
                ques.selected
                  ? 'grey-color form-check-label'
                  : 'form-check-label'
              "
              for="flexCheckDefault"
              [innerText]="ques.questionText"
            >
            </span>
            <br />
            <img
              *ngIf="ques.isImage && ques.imageUrl && ques.imageUrl != ''"
              [src]="ques.imageUrl"
            />

            <br *ngIf="ques.isImage && ques.imageUrl && ques.imageUrl != ''" />

            <ol type="A" style="margin-left: 20px">
              <li *ngFor="let option of ques.options">
                <img
                  *ngIf="option.optionText.includes('http')"
                  [src]="option.optionText"
                />
                <label
                  [class]="ques.selected ? 'grey-color' : ''"
                  *ngIf="!option.optionText.includes('http')"
                  >{{ option.optionText }}</label
                >
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isFb">
      <h5 class="mb-3">Fill In The Blanks</h5>
      <div *ngFor="let ques of questionNeList; let ind = index">
        <div *ngIf="ques.questionType == 'FB'" class="form-check">
          <div>
            <input
              *ngIf="!ques.selected"
              [class]="
                ques.questionType == 'FB'
                  ? 'hid form-check-input'
                  : 'form-check-input'
              "
              type="checkbox"
              id="flexCheckDefault"
              type="checkbox"
              value=""
              [id]="ques._id"
              (change)="markCheck(ques, $event)"
            />

            <span [class]="ques.selected ? 'grey-color fw-bold' : 'fw-bold'"
              >Q.{{ ind + 1 }}
            </span>
            <span
              [class]="
                ques.selected
                  ? 'grey-color form-check-label'
                  : 'form-check-label'
              "
              for="flexCheckDefault"
              [innerText]="ques.questionText"
            >
            </span>
            <br />
            <img
              *ngIf="ques.isImage && ques.imageUrl && ques.imageUrl != ''"
              [src]="ques.imageUrl"
            />

            <br *ngIf="ques.isImage && ques.imageUrl && ques.imageUrl != ''" />

            <ol type="A" style="margin-left: 20px">
              <li *ngFor="let option of ques.options">
                <img
                  *ngIf="option.optionText.includes('http')"
                  [src]="option.optionText"
                />
                <label
                  [class]="ques.selected ? 'grey-color' : ''"
                  *ngIf="!option.optionText.includes('http')"
                  >{{ option.optionText }}</label
                >
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="row finalList my-3"
  *ngIf="
    isEdit &&
    searchObj &&
    searchObj.questionGroups &&
    searchObj.questionGroups.length
  "
>
  <div *ngFor="let grp of searchObj.questionGroups; let grpI = index">
    <h5 class="my-3" *ngIf="grp && grp.questionList && grp.questionList.length">
      {{ grp?.groupTitleLeft
      }}<span style="float: right; padding-right: 20px; font-size: 18px"
        >{{ grp?.groupTitleRight }}
        <strong>Total Marks - {{ grp.groupTotal }}</strong></span
      >
    </h5>
    <ul
      style="list-style: none"
      *ngIf="grp && grp.questionList && grp.questionList.length"
    >
      <li *ngFor="let ques of grp.questionList; let ind = index">
        <span style="float: right; padding-right: 0px">
          <label class="fw-bold">Mark - {{ ques.marks }}</label>
          <button
            type="button"
            class="btn ms-1"
            id="remove"
            (click)="removeQuestion(grpI, ind)"
          >
            Remove
          </button>
          <button
            type="button"
            class="btn ms-1"
            id="remove"
            (click)="editMarks(grpI, ind)"
          >
            Edit Mark
          </button>
        </span>
        <span class="fw-bold">Q.{{ ind + 1 }}</span> -
        <span [innerText]="ques.questionText"></span>
        <br />
        <img
          *ngIf="ques.isImage && ques.imageUrl && ques.imageUrl != ''"
          [src]="ques.imageUrl"
        />

        <br *ngIf="ques.isImage && ques.imageUrl && ques.imageUrl != ''" />

        <ol type="A" style="margin-left: 20px">
          <li *ngFor="let option of ques.options">
            <img
              *ngIf="option.optionText.includes('http')"
              [src]="option.optionText"
            />
            <label *ngIf="!option.optionText.includes('http')">{{
              option.optionText
            }}</label>
          </li>
        </ol>
      </li>
    </ul>
  </div>
  <div>
    <button type="button" class="btn ms-2" id="remove" (click)="noEdit()">
      Back to Select Question
    </button>
    <button
      *ngIf="searchObj.marksAlloted == searchObj.maxMarks"
      type="button"
      class="btn btn-success me-2"
      style="float: right; margin-left: 5px; max-width: fit-content"
      (click)="checkQuestionTotal()"
    >
      Create
    </button>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="questionModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
  >
    <div class="modal-content">
      <div class="modal-header d-block mb-3">
        <h6 class="modal-title fw-bold" id="exampleModalLabel">
          How much marks you want to allocate to each questions?
        </h6>
        <button
          type="button"
          style="float: right"
          data-dismiss="modal"
          (click)="closeQuestionModel()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <br />
        <div class="btndiv modalbtn row">
          <select
            class="btn col-md-4 col-4"
            name="subject"
            (change)="addCurrentMark($event)"
          >
            <option [value]="" disabled>Select Marks</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
          </select>
          <button
            type="button"
            class="btn col-md-2 qlist col-12"
            id="qlistbtn2"
            style="right: 0"
            data-bs-toggle="modal"
            data-bs-target="#questionModal"
            (click)="addToExam()"
          >
            Add to Question Set
          </button>
        </div>
      </div>

      <div class="modal-body">
        <ul style="list-style: none">
          <li *ngFor="let selQues of currentQuestionList; let i = index">
            <span class="fw-bold">Q.{{ i + 1 }} </span>-
            <span [innerText]="selQues.questionText"></span>
            <br />
            <img
              *ngIf="
                selQues.isImage && selQues.imageUrl && selQues.imageUrl != ''
              "
              [src]="selQues.imageUrl"
            />

            <br
              *ngIf="
                selQues.isImage && selQues.imageUrl && selQues.imageUrl != ''
              "
            />
            <ol type="A" style="margin-left: 20px">
              <li *ngFor="let option of selQues.options">
                <img
                  *ngIf="option.optionText.includes('http')"
                  [src]="option.optionText"
                />
                <label *ngIf="!option.optionText.includes('http')">{{
                  option.optionText
                }}</label>
              </li>
            </ol>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- Alert Modal -->
<div
  class="modal fade"
  id="alertModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="alertModalLabel">
          You have reached maximum alloted marks ({{ searchObj.maxMarks }})
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeModelAlert()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body mx-auto mb-5">
        <button type="button" class="btn" id="goback" (click)="goBack()">
          Go Back
        </button>
        <button
          type="button"
          class="btn"
          id="qlistbtn"
          (click)="goToQuestionSet()"
        >
          Proceed
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Overflow Modal -->
<div
  class="modal fade"
  id="overflowModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="overflowModalLabel">
          Maximum marks where ({{ searchObj.maxMarks }}) but current total is
          ({{ searchObj.marksAlloted }}). Please remove some question or
          increase Maximum marks.
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeModel()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body mx-auto mb-5">
        <button
          type="button"
          class="btn me-2"
          id="qlistbtn"
          (click)="closeModel()"
        >
          Close
        </button>
        <button type="button" class="btn" id="goback" (click)="editQuestion()">
          Remove Questions
        </button>
        <button
          type="button"
          class="btn"
          id="qlistbtn"
          (click)="openMaximumMarksEditor()"
        >
          Edit Maximum Marks
        </button>
      </div>
    </div>
  </div>
</div>

<!--Update Maximum Marks Modal -->
<div
  class="modal fade"
  id="updateMaximumMarksModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="updateMaximumMarksModalLabel">
          Current Maximum marks are ({{ searchObj.maxMarks }}).
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeModelMaximumMark()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body mx-auto mb-5">
        <form class="row my-3">
          <div class="form-group col-md-5 mb-3">
            <label for="inputState" class="form-label">Maximum Marks</label>
            <input
              #examname_var="ngModel"
              name="examName"
              type="number"
              placeholder="eg. 100"
              [(ngModel)]="searchObj.maxMarks"
              class="form-control"
            />
          </div>
        </form>
        <button type="button" class="btn" id="goback" (click)="editQuestion()">
          Remove Questions
        </button>
        <button
          type="button"
          class="btn"
          id="qlistbtn"
          (click)="updateMaximumMarks()"
        >
          Edit Maximum Marks
        </button>
      </div>
    </div>
  </div>
</div>

<!-- edit marks -->
<div
  class="modal fade"
  id="editMarksModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="editMarksModal">
          Current mark are ({{
            searchObj?.questionGroups[editMarkGrpInd]?.questionList[
              editMarkQuesInd
            ]?.marks
          }}).
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeEditMarks()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body mx-auto mb-5">
        <form class="row my-3">
          <div class="form-group col-md-5 mb-3">
            <label for="inputState" class="form-label">Marks</label>
            <input
              #examname_var="ngModel"
              name="examName"
              type="number"
              placeholder="eg. 100"
              [(ngModel)]="searchObj.updatedMark"
              class="form-control"
            />
          </div>
        </form>
        <button
          type="button"
          class="btn"
          id="goback"
          (click)="closeEditMarks()"
        >
          Close
        </button>
        <button type="button" class="btn" id="qlistbtn" (click)="updateMarks()">
          Update Marks
        </button>
      </div>
    </div>
  </div>
</div>
