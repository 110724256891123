
<div>
  <div class="breadcrumb">
    <div class="col-md-12">
      <div class="row row-sm" style="padding-top: 10px; margin: 0 auto; text-align: center;">
        <div class="col-md-12 gradBack">
          <span style="float: left;padding-left: 12px;">
            <!-- <i class="fas fa-arrow-left left-arr-icon" (click)="backClicked()"></i> -->
          </span><h4 style="font-size: 20px;">{{schoolName}}<span style="float: right;" (click)="logOut()">Log Out</span></h4>
        </div>
      </div>
    </div>

    <!-- <li class="breadcrumb-item active">Webinar List</li> -->
  </div>
  <br>
</div>
<!-- extra -->
<div class="br-pagebody" style="background-color:white;">
  <div class="row row-sm" style="display:flex; height:50vh">
    <!-- <div class="col-md-12" style="text-align: center; font-size: xx-large;">
      <b><u>Admin Dashboard</u></b>
    </div> -->
    <div class="col-md-6">
      <div class="card" style="width: 18rem; margin: 0 auto; text-align: center;">
        <img class="card-img-top" src="/assets/img/work-from-home.png" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">Schedule Live Classes</h5>
          <button (click)="navigateToWebinarSetting()" class="btn btn-primary">Create Session</button>
        </div>
      </div>
    </div>

    <div class="col-md-6">

      <div class="card" style="width: 18rem; margin: 0 auto; text-align: center;">
        <img class="card-img-top" src="/assets/img/clipboard.png" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">View Reports</h5>
          <button  (click)="navigateToWebinarList()" class="btn btn-primary">Scheduled Session</button>
        </div>
      </div>
    </div>
    <div class="col-md-6">

      <div class="card" style="width: 18rem; margin: 0 auto; text-align: center;">
        <img class="card-img-top" src="/assets/img/clipboard.png" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title">View Principal Reports</h5>
          <button  (click)="navigateToPrincipalReports()" class="btn btn-primary">Reports</button>
        </div>
      </div>
    </div>
  </div>
</div>
  
  