<div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important">
    <div class="modal-dialog custom-alert" role="document">
      <div class="modal-content">
      
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">{{message?.header || 'Please Confirm!!!'}}</h4>
        
          <a (click)="message.noFn()">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </a>
        </div>
        
        <!-- Modal body -->
        <div *ngIf="message?.type == 'confirm'" class="modal-body">
            {{message?.text || 'Are you sure ?'}}
        </div>
        
        <!-- Modal footer -->
        <div class="modal-footer confirm-button">
            <a (click)="message.siFn()">
                <button type="button" class="btn btn-success btn-sm" data-dismiss="modal">Yes</button>
                </a>
            <a (click)="message.noFn()">
                <button type="button" class="btn btn-danger btn-sm" data-dismiss="modal">Cancel</button>
            </a>
         
        </div>
        
      </div>
    </div>
  </div>
  