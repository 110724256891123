<div class="br-pagebody" style="background-color: white">
  <div class="row row-sm" style="margin-left: 0; margin-right: 0">
    <div class="form-group col-md-2">
      <label>Session</label>
      <select
        [(ngModel)]="searchObj.session"
        name="newSubject.session"
        class="form-control form-control-sm"
        (ngModelChange)="selectSession()"
      >
        <option value="" disabled selected hidden>Select Session</option>
        <option value="2017-18">2017-18</option>
        <option value="2018-19">2018-19</option>
        <option value="2019-20">2019-20</option>
        <option value="22020-21">2020-21</option>
        <option value="2021-22">2021-22</option>
        <option value="2022-23">2022-23</option>
        <option value="2023-24">2023-24</option>
        <option value="2024-25">2024-25</option>
        <option value="2025-26">2025-26</option>
        <option value="2026-27">2026-27</option>
        <option value="2027-28">2027-28</option>
        <option value="2028-29">2028-29</option>
        <option value="2029-30">2029-30</option>
        <option value="2030-31">2030-31</option>
      </select>
    </div>
    <div class="form-group col-md-2">
      <label>Class</label>
      <select
        #stdName_var="ngModel"
        [(ngModel)]="searchObj.std"
        class="form-control form-control-sm"
        (change)="updateSubject()"
      >
        <option [value]="">Select Class</option>
        <option *ngFor="let std of stdList">{{ std }}</option>
      </select>
    </div>

    <div class="form-group col-md-2">
      <label>Subject</label>
      <select
        [(ngModel)]="searchObj.subject"
        class="form-control form-control-sm"
        (change)="updateCategoryList()"
      >
        <option [value]="">Select Subject</option>
        <option *ngFor="let sub of subjectList">{{ sub }}</option>
      </select>
    </div>

    <div class="form-group col-md-2">
      <label>Category</label>
      <select
        [(ngModel)]="searchObj.category"
        class="form-control form-control-sm"
        (change)="updateSeriesList()"
      >
        <option [value]="">Select Category</option>
        <option *ngFor="let cat of categoryList">{{ cat }}</option>
      </select>
    </div>

    <div class="form-group col-md-2">
      <label>Series</label>
      <select
        [(ngModel)]="searchObj.series"
        class="form-control form-control-sm"
      >
        <option [value]="">Select Series</option>
        <option *ngFor="let ser of seriesList">{{ ser }}</option>
      </select>
    </div>

    <div class="form-group col-md-2" style="padding-top: 23px">
      <button class="btn btn-warning commonBtn" (click)="getContentList()">
        Find
      </button>
    </div>

    <div *ngIf="selectedBook" class="row-sm" style="padding-top: 23px">
      <button class="btn btn-primary commonBtn" (click)="addManual()">
        <i class="fa fa-solid fa-plus"></i> Add Teacher Manual
      </button>
    </div>
    <hr />
    <br />

    <div
      class="container"
      *ngIf="
        selectedBook?.teacherManual && selectedBook?.teacherManual.length > 0
      "
    >
      <div *ngFor="let book of selectedBook?.teacherManual; let i = index">
        <label class="mt-3 content-label"> {{ i + 1 }}.Title:</label>
        <input
          class="form-control form-control-sm mb-3 content-input col-6"
          type="text"
          placeholder="Enter Title"
          name="url"
          [(ngModel)]="book.mainTitle"
        />
        <div class="form-group mb-3">
          <button
            type="button"
            (click)="open(book.url)"
            class="btn content-button"
          >
            View Manual
          </button>
          <input
            class="form-control form-control-sm mb-3 content-input col-6"
            type="text"
            placeholder="Manual URL"
            name="url"
            [(ngModel)]="book.url"
          />
        </div>
      </div>
      <div class="update-button-div">
        <button
          *ngIf="allowEdit"
          type="button"
          class="btn update-button col-3"
          (click)="updateBookDetails()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
  <page-loader *ngIf="showLoader" [loaderMsg]="loaderMessage"></page-loader>
</div>

<div class="modal" id="uploadBookModel" tabindex="-1">
  <div class="modal-dialog modal-md" role="document">
    <!-- style="width: 70%; margin-left: 15%" -->
    <div class="modal-content" id="modal-container">
      <div class="modal-header" id="modalHeader">
        <img src="../../assets/modal/upload.svg" class="main-title-image" />
        <h5 class="modal-title" id="modal-main-title">Add Content</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          id="modal-title-button"
          (click)="doneUploadBook()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="form-group mt-3 mb-3">
            <label for="currentContentVar">Content Type</label>
            <select
              [(ngModel)]="contentType"
              (change)="selectContentType()"
              name="currentContent.type"
              class="form-control form-control-sm"
              placeholder="Content Type"
            >
              <option value="" disabled hidden>Select Content Type</option>
              <option value="teacher">Teacher Manual</option>
              <option value="lesson">Lesson plan</option>
              <option value="worksheet">Worksheet Key</option>
              <option value="answer">Answer key</option>
            </select>
          </div>
          <div class="form-group mt-3 mb-3">
            <label for="currentContentVar">Content Title</label>
            <input
              type="text"
              #code_var="ngModel"
              [(ngModel)]="newContent.mainTitle"
              name="currentContent.title"
              class="form-control form-control-sm"
              placeholder="Content Title"
              id="currentContentVar"
            />
          </div>
          <span style="color: red" class="mt-3"
            >Only PDF file can be uploaded.</span
          ><br />
          <span style="color: red">Maximum file size is 100MB.</span>
          <div class="form-group">
            <label for="bookSubjectVar">Select Content To Upload</label>
            <div class="upload-btn-wrapper" style="cursor: pointer">
              <button class="btn" for="inputGroupFile3">Choose File</button>
              <input
                type="file"
                class="custom-file-input"
                id="inputGroupFile3"
                style="cursor: pointer"
                (change)="onFileChange($event)"
                (click)="$event.target.value = null"
              />
            </div>
            <div class="text-warning pt-2">
              {{ showErrorMessage }}
            </div>
          </div>

          <div class="mb-3">
            {{ fileToUpload?.name
            }}<span (click)="removeFile()" *ngIf="fileToUpload"
              ><i class="fa fa-times text-danger"> </i
            ></span>
          </div>

          <div class="update-button-div" style="margin-top: 25px; width: 100%">
            <button
              style="width: 100%"
              type="submit"
              class="btn update-button"
              (click)="saveContent()"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
