import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-lesson-plan",
  templateUrl: "./lesson-plan.component.html",
  styleUrls: ["./lesson-plan.component.css"],
})
export class LessonPlanComponent implements OnInit {
  lessonPlan = [];
  constructor() {}

  ngOnInit(): void {
    this.lessonPlan = JSON.parse(localStorage.getItem("lessonPlanData"));
    console.log("this lessonPlan", this.lessonPlan);
  }
}
