import { Component, OnInit } from "@angular/core";
import { AllApisService } from "../all-apis.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmDialogService } from "../shared/confirm-dialog/confirm-dialog.service";
import { NotifierService } from "angular-notifier";
import { YtplayerService } from "../shared/ytplayer/ytplayer.service";
import {
  QuestionListService,
  searchKeys,
} from "../master-admin/question-list/question-list.service";
import { viewClassName } from "@angular/compiler";
@Component({
  selector: "app-all-papers",
  templateUrl: "./all-papers.component.html",
  styleUrls: ["./all-papers.component.css"],
})
export class AllPapersComponent implements OnInit {
  allPapers: any;

  constructor(
    private serv: QuestionListService,
    private quesServ: QuestionListService,
    private allApiServ: AllApisService,
    private route: Router,
    public router: ActivatedRoute,
    private confirmBox: ConfirmDialogService,
    private notifier: NotifierService,
    private ytServ: YtplayerService
  ) {}

  ngOnInit() {
    this.getAllPapers();
    $("#tableID").DataTable({
      // Enable the horizontal scrolling
      // of data in DataTable
      scrollX: true,
    });
  }

  getAllPapers() {
    this.quesServ.getAllPapers().subscribe((res) => {
      this.allPapers = res.result;
      this.allPapers.forEach((element) => {
        let d = element.createdAt.split("T")[0];
        const [year, month, day] = d.split("-");
        const result = [day, month, year].join("/");
        element["date"] = result;
      });
      this.populateTable(true);
    });
  }

  populateTable(active: boolean) {
    let action_btn = "";
    action_btn =
      '<span class="download btn"><i style="padding-left:10px;position:pointer;"  class="fa fa-download fa-2x text-infox" title="Download"></i> Download</span>';
    let action_btn_doc = "";
    action_btn_doc =
      '<span class="downloaddoc btn"><i style="padding-left:10px;position:pointer;"  class="fa fa-download fa-2x text-infox" title="Download"></i> Download</span>';
    let action_btn_ans = "";
    action_btn_ans =
      '<span class="downloadans btn"><i style="padding-left:10px;position:pointer;"  class="fa fa-download fa-2x text-infox" title="Download"></i> Download</span>';
    let action = "";
    action =
      '<span class="edit"><i style="padding-left:10px;position:pointer;"  class="fas fa-edit fa-2x text-info" title="Edit Product"></i></span> <span class="delete"><i style="padding-left:10px;" class="far fa-trash-alt fa-2x text-danger" title="Delete"></i></span>';

    let self = this;
    let that = this;
    $(".showhideTable").css("display", "block");
    const example = $(".example").DataTable({
      scrollY: "300px",
      scrollX: true,
      scrollCollapse: true,
      responsive: false,
      destroy: true,
      data: this.allPapers,
      columns: [
        {
          data: "createdAt",
          defaultContent: "<i>Not Set</i>",
        },
        { data: "std", defaultContent: "<i>Not Set</i>" },
        { data: "subject", defaultContent: "<i>Not Set</i>" },
        { data: "name", defaultContent: "<i>Not Set</i>" },
        { data: "maxHours", defaultContent: "<i>Not Set</i>" },
        { data: "maxMarks", defaultContent: "<i>Not Set</i>" },
        { data: "marksAlloted", defaultContent: "<i>Not Set</i>" },
        { data: "totalQuestion", defaultContent: "<i>Not Set</i>" },
        { data: "date", defaultContent: "<i>Not Set</i>" },
        {
          data: null,
          className: "center",
          render: function (data) {
            return action_btn;
          },
          defaultContent: action_btn,
        },
        {
          data: null,
          className: "center",
          render: function (data) {
            return action_btn_doc;
          },
          defaultContent: action_btn_doc,
        },
        {
          data: null,
          className: "center",
          render: function (data) {
            return action_btn_ans;
          },
          defaultContent: action_btn_ans,
        },
        {
          data: null,
          className: "center",
          render: function (data) {
            return action;
          },
          defaultContent: action,
        },
      ],
      rowCallback: function (row, data: any = {}) {
        if (data.marksAlloted > data.maxMarks) {
          $("td", row).css("background-color", "red");
          $("td", row).css("color", "white");
        }
      },
      autoWidth: true,
      processing: true,
      order: [0, "des"],
      columnDefs: [{ targets: [0], visible: false }],
      dom: "Bfrtip",
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"],
      ],
    });

    $(".example tbody").on("click", "tr span.download", function () {
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p)[0];
      self.downloadPdf(self.allPapers[data]);
    });
    $(".example tbody").on("click", "tr span.downloaddoc", function () {
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p)[0];
      self.downloadDoc(self.allPapers[data]);
    });
    $(".example tbody").on("click", "tr span.downloadans", function () {
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p)[0];
      self.downloadAnswerPdf(self.allPapers[data]);
    });
    $(".example tbody").on("click", "tr span.edit", function () {
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p)[0];
      let paper = self.allPapers[data];
      self.confirmBox.confirmThis(
        "Are you sure you want to edit exam paper",
        "Edit Paper ?",
        function () {
          localStorage.setItem("searchObj", JSON.stringify(paper));
          self.editPaper(self.allPapers[data]);
        },
        function () {}
      );
    });
    $(".example tbody").on("click", "tr span.delete", function () {
      var p = this.closest("tr");
      var data = $(".example").DataTable().row(p)[0];
      self.confirmBox.confirmThis(
        "Are you sure you want to delete exam paper",
        "Delete Paper ?",
        function () {
          self.deletePaper(self.allPapers[data]);
        },
        function () {}
      );
    });
  }

  downloadAnswerPdf(paper) {
    window.location.href =
      this.allApiServ.baseurl +
      "/downloads/answerPaper?examPaperId=" +
      paper._id;
  }

  downloadDoc(paper) {
    window.location.href =
      this.allApiServ.baseurl +
      "/downloads/questionPaperDoc?examPaperId=" +
      paper._id;
  }

  downloadPdf(paper) {
    window.location.href =
      this.allApiServ.baseurl +
      "/downloads/questionPaper?examPaperId=" +
      paper._id;
  }

  deletePaper(paper) {
    let self = this;
    this.quesServ.deletePaper(paper._id).subscribe((res) => {
      if (res.status) {
        this.notifier.notify("success", "Paper Deleted Successfully.");
        self.getAllPapers();
      } else {
        this.notifier.notify("error", "Something went wrong!!!!.");
      }
    });
  }

  editPaper(paper) {
    let self = this;
    this.quesServ.deletePaper(paper._id).subscribe((res) => {
      if (res.status) {
        self.route.navigate(["/admin/save-exam"]);
      } else {
        this.notifier.notify("error", "Something went wrong!!!!.");
      }
    });
  }
}
