<br />
<div class="br-pagebody" style="background-color: white">
  <div class="row row-sm">
    <div class="col-md-12">
      <h3 class="title my-3 ms-3 mt-0" *ngIf="templateType == 'Book Content'">
        Upload Contents
      </h3>
      <h3 class="title my-3 ms-3 mt-0" *ngIf="templateType == 'Curriculum'">
        Upload Curriculum Ids
      </h3>
      <h3
        class="title my-3 ms-3 mt-0"
        *ngIf="templateType != 'Book Content' && templateType != 'Curriculum'"
      >
        Upload Questions
      </h3>
      <div class="row mb-3" *ngIf="templateType == 'Book Content'">
        <div class="col-2">
          <label style="font-size: larger; font-weight: bold; color: red"
            >Select Session</label
          >
        </div>
        <div class="col-6">
          <select
            #code_var="ngModel"
            [(ngModel)]="session"
            id="session"
            (change)="selectSession()"
            name="session"
            class="form-select"
          >
            <option value="2017-18">2017-18</option>
            <option value="2018-19">2018-19</option>
            <option value="2019-20">2019-20</option>
            <option value="22020-21">2020-21</option>
            <option value="2021-22">2021-22</option>
            <option value="2022-23">2022-23</option>
            <option value="2023-24">2023-24</option>
            <option value="2024-25">2024-25</option>
            <option value="2025-26">2025-26</option>
            <option value="2026-27">2026-27</option>
            <option value="2027-28">2027-28</option>
            <option value="2028-29">2028-29</option>
            <option value="2029-30">2029-30</option>
            <option value="2030-31">2030-31</option>
          </select>
        </div>
      </div>
      <div class="card text-center" *ngIf="instructions">
        <div class="card-header">
          <h5>Step 1: Assessment Template Download</h5>
          <div class="row row-sm" style="padding-bottom: 10px">
            <div class="col-md-12" style="text-align: left; padding-left: 4cm">
              <b
                >Kindly read the carefully below instructions before updating
                the template:</b
              >
              <h6>
                A) School Id will be provided by Team SchoolMitra and is
                mentioned on top of the assessment panel.
              </h6>
              <h6>B) Fill in the required excel with information such as:</h6>
              <h6>
                Class, Section, Subject, Topic, Question, OptionA, OptionB,
                Marks, Answer, Type, TestName, StartDate,EndDate
              </h6>
              <h6>C) For every class, separate excel should be used.</h6>
              <h6>D) For Multiple Section (comma) separator can be used.</h6>
              <h6>
                E) By default OptionA —> Option F are available (6 options max)
                per question.
              </h6>
              <h6>
                F) Marks column would hold the max. Marks for the specific
                question.
              </h6>
              <h6>
                G) Correct Answer for the specific question needs to be
                mentioned in Answer Column. In case of Multiple Choice
                Questions, answers can be separated using (comma) separator.
              </h6>
              <h6>
                H) Time column is used in case you want to make the exam time
                bound. Time mentioned against each time is measured in seconds.
              </h6>
              <h6>I) Column Type signify, test type such as:</h6>
              <h6 style="padding-left: 20px">
                a) SCQ —> Single Choice Question
              </h6>
              <h6 style="padding-left: 20px">
                b) MCQ —> Multiple Choice Question
              </h6>
              <h6 style="padding-left: 20px">c) FB —> Fill In the Blanks</h6>
              <h6 style="padding-left: 20px">d) MF —> Match the Following</h6>
              <h6>J) Column TestName, signify name of the assessment.</h6>
              <h6>
                K) Column StartDate, EndDate signify the Life Span of the
                Assessment.
              </h6>
              <b>Sample Excel Entry</b>
              <h6>1. One class, one sec, single choice question</h6>
              <h6><img src="assets/img/Example1.jpeg" alt="..." /></h6>
              <h6>2. One class, multiple sec, multiple choice question</h6>
              <h6><img src="assets/img/Example2.jpeg" alt="..." /></h6>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-primary"
            (click)="downloadTemplate()"
          >
            Download Template
          </button>
        </div>
        <div class="card-body">
          <h5 class="card-title" *ngIf="instructions">
            Step 2: Upload Assessment
          </h5>
          <div
            class="row row-sm"
            style="padding-bottom: 10px"
            *ngIf="instructions"
          >
            <div
              class="col-md-12"
              style="text-align: center; padding-left: 4cm"
            >
              <b
                >After Updating the assessment in the above downloaded
                Excel(class wise), use the below portal to upload the Excel and
                then press the Upload button.</b
              >
            </div>
          </div>

          <div id="fileSelector">
            <div class="image-upload">
              <label
                for="fileUpload"
                class="photoUploadLabel"
                style="padding-top: 20px"
              >
                <i class="fas fa-upload fa-3x text-white"></i>
              </label>
              <input
                type="file"
                name="fileUplaod"
                id="fileUpload"
                multiple
                ng2FileSelect
                [uploader]="uploader"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="row row-sm" *ngFor="let item of uploader.queue">
              <div class="col-md-3">
                {{ item.file.name }}
              </div>
              <div class="col-md-5">
                <div class="progress">
                  <div
                    class="progress-bar bg-success"
                    [ngStyle]="{ width: item.progress + '%' }"
                  ></div>
                </div>
              </div>
              <div class="col-md-4">
                <button
                  type="button"
                  class="btn btn-dark"
                  (click)="item.upload()"
                >
                  Upload
                </button>
                <button
                  style="margin-left: 5px"
                  type="button"
                  class="btn btn-danger"
                  (click)="item.remove()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted">
          <div
            class="col-md-12"
            *ngIf="uploader?.queue?.length > 0"
            style="padding-top: 20px"
          >
            <button
              type="button"
              class="btn btn-primary"
              (click)="uploader.uploadAll()"
            >
              Upload All
            </button>
          </div>
        </div>
      </div>

      <div class="card r" *ngIf="!instructions">
        <!-- old  -->
        <div class="container">
          <!-- <div class="row">
            <p id="step1">Step 1: Upload content and copy url into excel.</p>
            <button
              type="button"
              class="btn template"
              (click)="uploadContent()"
            >
              Upload Content
            </button>
          </div> -->
          <div class="row">
            <p id="step1">Step 1: {{ templateType }} Template Download</p>
            <button
              type="button"
              class="btn template"
              (click)="downloadTemplate()"
            >
              Download Template
            </button>
          </div>
          <div class="row mt-5">
            <p id="stepUpload" class="mb-2">
              Step 2: Upload {{ templateType }}
            </p>
            <small class="mb-2"
              ><i
                >Use the below portal to upload the Excel and then press the\
                Upload button.</i
              ></small
            >
            <!-- <button type="button" class="btn uploadNow">
              <i class="fa-solid fa-arrow-up-from-bracket me-2"></i>Upload Now
            </button> -->
            <div id="fileSelector text-center">
              <div class="image-upload btn">
                <label
                  for="fileUpload"
                  class="photoUploadLabel"
                  style="padding-top: 20px"
                >
                  <i class="fas fa-upload fa-3x text-white"></i>
                </label>
                <input
                  type="file"
                  name="fileUplaod"
                  id="fileUpload"
                  multiple
                  ng2FileSelect
                  [uploader]="uploader"
                />
              </div>
            </div>
          </div>

          <!-- new one -->
          <div class="card-body text-cente">
            <div class="col-md-12">
              <div class="row row-sm" *ngFor="let item of uploader.queue">
                <div class="col-md-3">
                  {{ item.file.name }}
                </div>
                <div class="col-md-5">
                  <div class="progress">
                    <div
                      class="progress-bar bg-success"
                      [ngStyle]="{ width: item.progress + '%' }"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-dark"
                    (click)="item.upload()"
                  >
                    Upload
                  </button>
                  <button
                    style="margin-left: 5px"
                    type="button"
                    class="btn btn-danger"
                    (click)="item.remove()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted text-cente">
          <div
            class="col-md-12"
            *ngIf="uploader?.queue?.length > 0"
            style="padding-top: 20px"
          >
            <button
              type="button"
              class="btn btn-primary"
              (click)="uploader.uploadAll()"
            >
              Upload All
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-md-12"
      *ngFor="let item of attachmentList; let i = index"
      style="
        background-color: rgb(231, 229, 229);
        border-radius: 5px;
        line-height: 40px;
        text-align: center;
      "
    >
      <h5 class="text-center">Step 3: List of Successfully Uploaded Excel</h5>
      <b>This section details the excel that has been successfully uploaded.</b>
      <div class="row row-sm">
        <div class="col-md-3">{{ item.originalname }}</div>
        <div class="col-md-6"></div>
      </div>
    </div>
  </div>
</div>
