import { Component, OnInit } from '@angular/core';
import { AllApisService} from '../all-apis.service';
import {LessonServiceService} from '../lesson-dashboard/lesson-service.service';
import { Router} from '@angular/router';
import { ConfirmDialogService } from '../shared/confirm-dialog/confirm-dialog.service';
import { NotifierService } from 'angular-notifier';


declare var $:any;
@Component({
  selector: 'app-activity-dashboard',
  templateUrl: './activity-dashboard.component.html',
  styleUrls: ['./activity-dashboard.component.css']
})
export class ActivityDashboardComponent implements OnInit {
  activityList=[];
  lessonInfo:any;
  topicId:string;
  moduleId:string;   
  editId:any;
  editRubrics:any;
  editSequence:any;
  deleteIndex:any;
  editActivityNo: any;
  contentNumId : any;
  prevActivityNo: any;
  deleteSpelling = ''
  deleteObj : any;

  constructor(private route:Router, private allApiServ:AllApisService, private serv:LessonServiceService, private confirmBox:ConfirmDialogService, private notifier:NotifierService) { }

  ngOnInit(): void {
    if(this.allApiServ.lessonInfo!==null && typeof this.allApiServ.lessonInfo!=='undefined' && this.allApiServ.lessonInfo!=='' && typeof this.allApiServ.topicId!=='undefined' && this.allApiServ.topicId!==''){
      this.topicId=this.allApiServ.topicId;
      this.lessonInfo=this.allApiServ.lessonInfo;
      this.getActivities();
    }
    else{
      this.moduleId=localStorage.getItem('moduleId');
      this.topicId=localStorage.getItem('topicId');
      if(typeof this.topicId!=='undefined' && this.topicId!=='')this.getLessonList();
      
    }
    
    
  }
  populateTable2(active:boolean) {
    let action_btn =  '<span class="edit"><i style="padding-left:10px;position:pointer;"  class="fas fa-edit fa-2x text-info" title="Edit Details"></i></span> <span style="padding-left:10px;" class="delete text-danger" title="Delete"><i class="far fa-trash-alt" *ngIf="false"> </i></span>'
    let self=this;
    if ($.fn.DataTable.isDataTable(".example1")) {
      $('.example1').DataTable().clear().destroy();
    };
    $('.showhideTable1').css('display', 'block');
    const example = $('.example1')
      .DataTable({
        data: this.activityList,
        columns: [
          
          { data: 'topicId', defaultContent: '<i>Not Set</i>' },
          { data: 'activityNo', defaultContent: '<i>Not Set</i>' },
          { data: 'title', defaultContent: '<i>Not Set</i>' },
          { data: 'rubrics', defaultContent: '<i>Not Set</i>' },
          {
            data: null,
            className: 'center',
            defaultContent: action_btn,
            
          } 
      

        ],
        dom: 'Bfrtip',
      //   buttons: [
      //     {
      //       extend: 'excel',
      //       filename: 'StudentResponse',
      //       title: 'Padho'
      //   },
      //   {
      //       extend: 'csv',
      //       filename: 'StudentResponse',
      //       title: 'Padho'
      //   },
      //   {
      //       extend: 'pdf',
      //       filename: 'StudentResponse',
      //       title: 'Padho'
      //   }
      // ],
      "order": [[ 4, "asc" ]],
        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']]
      });
      $('.example1 tbody').on('click', 'tr span.edit', function () {
        console.log("edit............");
       var p = this.closest('tr');
       var data = $('.example1').DataTable().row(p).index();
       self.editActivityData(self.activityList[data]);
     });
     $('.example1 tbody').on('click', 'tr span.delete', function () {
      console.log("edit............");
     var p = this.closest('tr');
     var data = $('.example1').DataTable().row(p).index();
     self.deleteIndex=data;
     self.deleteLessonData(self.activityList[data], data);
   });
      
  }

  deleteLessonData(data:any, index: any){
    var obj={}
    obj["id"]=data._id;
    obj['moduleId']=this.allApiServ.lessonInfo["_id"];
    obj['topicId']=this.topicId
    let self = this;
    this.deleteObj = obj;
    this.confirmBox.confirmThis('Are you sure you want to delete the content activity', 'Delete Activity ?', function () {
      // prompt.call
      $('#confirmationModel').modal('show');

    }, function () {
    })
  }

  editActivityData(data:any){
    console.log("data", data);

    this.editId = data._id;
    this.prevActivityNo = data.activityNo;
    this.editActivityNo = data.activityNo;
    this.editRubrics = data.rubrics;
    this.editSequence = data.sequence;
    this.contentNumId = data.topicId
    $('#editModel').modal('show');
  }

  getLessonList(){
    this.serv.getLesson({_id:this.moduleId})
    .subscribe(res=>{
      if(res.status ){
        this.allApiServ.lessonInfo=res["result"][0];
        this.lessonInfo = res["result"][0];
        var contentList=res["result"][0]["lessonContents"];
        contentList.forEach(element => {
          if(element._id===this.topicId){
            this.activityList=element.contentActivities.filter( item => item !== null);
            this.contentNumId = element.topicId
          }
        });
        console.log(contentList);
        this.populateTable2(true);
       
      }
    })
  }

  updateEditDetails(){
    var obj={}

    obj["id"]= this.editId;
    obj["rubrics"] = this.editRubrics;
    obj["sequence"] = this.editSequence;
    obj['moduleId'] = this.lessonInfo['moduleId'],
    obj['topicId'] = this.contentNumId,
    obj['activityNo'] = this.prevActivityNo,
    obj['newActivityNo'] = this.editActivityNo.trim(),
    obj['variant'] = this.lessonInfo['variant'],
    obj['version'] = this.lessonInfo['version']

    this.serv.updateEditActivity(obj)
    .subscribe(res=>{
      console.log("content",res);
      if(res.status == true ){
        $('#editModel').modal('hide');
      }
      this.getLessonList();
    })
  }
  
  getActivities(){
    this.lessonInfo.lessonContents.forEach(element => {
      if(element._id===this.topicId){
        this.activityList=element.contentActivities.filter( item => item !== null);
        this.contentNumId = element.topicId
      }
    });
    this.populateTable2(true);
  }

  backClicked(){
    this.route.navigate(['/dashboard/content'])
  }

  confirmSpelling(){
    $('#confirmationModel').modal('hide');
     let self = this;
     if(this.deleteSpelling === 'Delete'){
      self.serv.deleteActivity(self.deleteObj)
      .subscribe(res=>{
        if(res["status"]){
          self.notifier.notify('success','Deleted successfully');
          self.activityList.splice(self.deleteIndex, 1);
          self.populateTable2(true);
        }
      })
     }
     else{
      self.notifier.notify('error','Please type the correct spelling');
     }
     this.deleteSpelling = ''
  }
}
