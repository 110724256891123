import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AllApisService} from '../all-apis.service';

@Injectable({
  providedIn: 'root'
})
export class ActionPerformedService {

  constructor(private http : HttpClient, private allApiServ : AllApisService) { }

  sendActionPerformed(obj) {
    return this.http.post<any>(this.allApiServ.baseurl +'/dump/action', obj, this.allApiServ.httpOptions);
  }
}
