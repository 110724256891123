<div class="page-loader">
  <div class="container">
    <div class="row">
      <div id="loader">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="loading">
          <div class="spinner-message">
            {{loaderMsg}}
          </div>
        </div>
      </div>
  
      
    </div>
  </div>
</div>