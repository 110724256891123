<div class="wrapper" *ngIf="!showLoader1">
   <div id="formContent">
    <div class="backSign" style="text-align: left;">
      <!-- <i class="fas fa-home"></i> -->
      <img src="../../../assets/mainSite2/logo.png" class="img-fluid" (click)='backToHome()'>
    </div>
  <div>
    <img src="assets/img/loginIcon.png" id="icon" alt="User Icon" />
  </div>


    <input class="form form-control-sm" type="text" name="email" (change)="validateInput()" [(ngModel)]="signInModel.emailId" placeholder="Email/Mobile No." required
     (key.enter)="onLogin()">
    <div *ngIf="!validLoginId">
      <label class="validation-message">Invalid Email / Mobile.</label>
    </div>

    <input class="form form-control-sm" type="password" name="password" [(ngModel)]="signInModel.password" placeholder="Password" required minlength="4"
     (key.enter)="onLogin()">
    <div *ngIf="!signInModel.password">
      <label class="validation-message">Minimum 4 characters.</label>
    </div>

    <button class="btn btn-primary" (click)="onLogin()">Sign In</button>

  <!-- Error message -->
<div class="alert" *ngIf="serverErrorMessages">
    {{serverErrorMessages}}
  </div>
 </div>
</div>
<!-- <notifier-container></notifier-container> -->
<page-loader *ngIf="showLoader1" [loaderMsg]="loaderMessage1"></page-loader>
