<div class="br-pagebody" style="background-color: white">
  <div class="row row-sm" style="display: flex" *ngIf="!showLoader">
    <div class="col-md-12">
      <form #f="ngForm">
        <div class="row loopRowDiv" *ngIf="schoolList && schoolList.length > 1">
          <div class="col-md-2"><strong>Select School</strong></div>
          <div class="form-group col-md-6">
            <ng-multiselect-dropdown
              [settings]="dropdownSettings"
              (onSelect)="updateEventSchool($event)"
              [data]="schoolDisplayList"
            >
            </ng-multiselect-dropdown>
            <!-- <select class="form-control form-control-sm" #schoolName="ngModel" name="schoolName" [(ngModel)]="schoolDetails" (ngModelChange)="getSchoolDetails()"> 
                        <option *ngFor="let school of schoolList" [ngValue]="school">{{school.name}}({{school.id}})</option>
                    </select> -->
          </div>
          <div
            class="col-md-2"
            [hidden]="
              !schoolInfo.standardList || !schoolInfo.standardList.length
            "
          >
            <button (click)="showAllQrCode()">All QR</button>
          </div>
        </div>

        <div
          class="col-md-12"
          [hidden]="!schoolInfo.standardList || !schoolInfo.standardList.length"
        >
          <div class="row m-0 p-t-0 p-b-0" style="margin-bottom: 5px">
            <div class="col-md-3"><strong>Class</strong></div>
            <div class="col-md-3"><strong>Map to Class</strong></div>
            <div class="col-md-2"><strong>License Given</strong></div>
            <div class="col-md-2"><strong>License Available</strong></div>
            <!-- <div class="col-md-1"><strong>Cambridge</strong></div> -->
            <div class="col-md-1"><strong>Add License</strong></div>
          </div>

          <div
            class="row loopRowDiv m-0 p-t-0 p-b-0"
            *ngFor="let obj of schoolInfo.standardList; let i = index"
          >
            <div class="form-group col-md-3">
              <input
                class="form-control"
                type="text"
                #count="ngModel"
                [(ngModel)]="obj.standard"
                name="std{{ i }}"
                placeholder="std"
                disabled
              />
            </div>

            <div class="form-group col-md-3">
              <select
                [(ngModel)]="obj.contentStandard"
                name="contentStandard{{ i }}"
                class="form-control form-control-sm"
              >
                <option *ngFor="let std of contentClassList">{{ std }}</option>
              </select>
            </div>

            <div class="form-group col-md-2">
              {{ obj.licenseAdded }}
            </div>

            <div class="form-group col-md-2">
              {{ obj.licenseCount }}
              <button
                *ngIf="
                  obj.licenseCount && obj.licenseCount > 0;
                  else templateName
                "
                (click)="showQrCode(obj.licenseCode)"
              >
                QR
              </button>
              <ng-template #templateName>
                <button>NULL</button>
              </ng-template>
            </div>

            <div class="form-group col-md-2">
              <input
                type="number"
                [(ngModel)]="obj.moreLicense"
                name="moreLicense{{ i }}"
                class="form-control form-control-sm"
              />
            </div>

            <!-- <div class="col-md-12 btnContainer">
               <div
                        class="" data-toggle="modal" data-target="#fineModal" (click)="addNewRow()"><i
                          class="fas fa-plus text-success"></i></div>
                </div>
              </div> -->
            <!-- Submit Buttons -->

            <!-- Submit Buttons -->
          </div>
        </div>
        <div
          class="form-group col-md-12"
          style="text-align: center; padding: 10px"
        >
          <div class="form-group col-md-3">
            <button type="button" class="btn btn-primary" (click)="saveInfo()">
              <i class="fas fa-save"></i> Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <page-loader *ngIf="showLoader" [loaderMsg]="loaderMessage"></page-loader>
</div>

<div
  class="modal fade"
  id="pictureModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="PictureModel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">QR Code</h5>
        <button
          type="button"
          class="close"
          (click)="done()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="singleField">
          <div class="form-group">
            <label>License Code</label>
            <input
              type="text"
              [value]="licenseCode"
              id="myInput"
              readonly="true"
              class="form-control"
            />
          </div>
          <div class="qr-container">
            <ngx-qrcode
              [elementType]="elementType"
              [value]="licenseCode"
              cssClass="coolQRCode"
              errorCorrectionLevel="L"
            >
            </ngx-qrcode>

            <button (click)="downloadQRCode()">Download QRCode</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="pictureAllModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="pictureAllModel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">All QR Code</h5>
        <button
          type="button"
          class="close"
          (click)="done2()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div id="htmlData" class="modal-body">
        <div class="singleField" *ngFor="let qr of allQrUrlData">
          <div class="form-group">
            <label>License Code</label>
            <input
              type="text"
              [value]="qr.licenseCode"
              id="myInput"
              readonly="true"
              class="form-control"
            />
          </div>
          <div class="qr-container">
            <img class="img-responsive" alt="QR Code" [src]="qr.url" />
          </div>
        </div>
        <div class="col-md-4 text-right">
          <button class="btn btn-success btn-block" (click)="openPDF()">
            Download PDF
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div
  class="modal fade"
  id="pictureAllModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="pictureAllModel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">QR Code</h5>
        <button
          type="button"
          class="close"
          (click)="done2()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="singleField">
          <!-- <div class="form-group">
            <label>License Code</label>
            <input
              type="text"
              [value]="licenseCode"
              id="myInput"
              readonly="true"
              class="form-control"
            />
          </div> 
          <div class="qr-container">
             <ngx-qrcode
              [elementType]="elementType"
              [value]="licenseCode"
              cssClass="coolQRCode"
              errorCorrectionLevel="L"
            >
            </ngx-qrcode>
            <img class="coolQRCodeAll" [src]="allQrURL" alt="QR" />

            <button (click)="downloadQRCodeAll()">Download QRCode</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
