<div style="width: 20%;" *ngIf="!teacherShow">
    <app-navigation-panel-admin val='webinarSetting'></app-navigation-panel-admin>
  </div>
  
  <div style="width: 20%;" *ngIf="teacherShow">
    <app-navigation-panel-teacher val='teacherLiveClasses'></app-navigation-panel-teacher>
  </div>
  
  
  <div class="container-fluid br-mainpanel">
    <div class="row row-sm">
  
      <div class="col-md-12">
        <p class="pageTitle">Student Attendance</p>
      </div>
  
      <div class="col-md-12">
  
        <div class="singleRowContainer2">
          <div class="col-md-12">
            <div class="row row-sm">
              <div class='col-md-3'>Class</div>
              <div class='col-md-3'>Section</div>
              <div class='col-md-3'>Month</div>
              <div class='col-md-3'></div>
              <div class='col-md-3'>
                <select #std="ngModel" name="std" [(ngModel)]="searchObj.standard" (change)="updateSectionSubject()" class="form-control form-control-sm selectStyle">
                    <option [value]="">Select class</option>
                    <option *ngFor="let grade of classList" [value]="grade">{{grade}}</option>
                </select>
              </div>
              <div class='col-md-3'>
                <select #sec="ngModel" name="section" [(ngModel)]="searchObj.section"  class="form-control form-control-sm selectStyle">
                    <option [value]="">Select section</option>
                    <option *ngFor="let sec of sectionList" [value]="sec">{{sec}}</option>
                </select>
              </div><div class='col-md-3'>
                <select #sec="ngModel" name="month" [(ngModel)]="month"  class="form-control form-control-sm selectStyle">
                    <option [value]="11">Select month</option>
                    <option [value]="11">Select month</option>
                    <option [value]="10">October</option>
                    <option [value]="9">September</option>
                    <!-- <option [value]="8">August</option>
                    <option [value]="7">July</option> -->
                    
                </select>
              </div>
              <div class='col-md-3'>
                <button type="button" class="btn btn-info" (click) = "getMonthlyAttendence()">Find</button>
              </div>
  
             
              <div class="col-md-12 showhideTable">
                <div class="table-responsive">
                  <table class="display example" cellspacing="0" width="100%">
                    <thead>
                      <tr>
                        
                        <!-- <th *ngFor = 'let col of columnList'>{{col}}</th> -->
                        <!-- <th>Standard</th>
                        <th>Section</th>
                        <th>Present</th>
                        <th>Appearance Count</th> -->
                      </tr>
                    </thead>
                  </table>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <page-loader *ngIf="showLoader"></page-loader>
