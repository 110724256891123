<div>
  <div class="breadcrumb" >
      <div class="col-md-12">
          <div class="row row-sm" style="padding-top: 10px; margin: 0 auto; text-align: center;">
              <div class="col-md-12 gradBack">
                <span style="float: left;padding-left: 12px;" (click)="backClicked()">
                  <i class="fas fa-arrow-left left-arr-icon fa-2x"></i>
                </span>
                    <h4 style="font-size: 20px;">{{schoolName}}</h4><span style="float: right;" (click)="logOut()">Log Out</span>
                </div>
      </div>
      </div>
      
      <!-- <li class="breadcrumb-item active">Webinar List</li> -->
  </div>
  <br>
</div>
  
  <div class="br-pagebody" style="background-color:white;">
    <div class="row row-sm" style="display:flex; height:50vh">
      <div class="col-md-12" style="text-align: center; font-size: large;">
        <b><u>Data Management</u></b>
      </div>
        <div class="col-sm-12 col-md-6" style="text-align: center; flex-basis:50%; margin: auto; font-size: xx-large;">
          
          <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateToAddCourse()"><i class="fas fa-plus"> </i> Add Course</button>
          <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateToAddProduct()"><i class="fas fa-plus"> </i> Add Product</button>
          <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateToLesson()"><i class="fas fa-eye"> </i> View Course Content</button>

          <!-- <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateToSchoolMap()"><i class="fas fa-tasks"> </i> School Mapping</button> -->
          
          <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateToImportLesson()"><i class="fas fa-tasks"> </i> Import Course Content</button>
  
          <!-- <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateToImportContent()"><i class="fas fa-tasks"> </i> Import Contents</button> -->
    
          <!-- <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateToImportActivity()"><i class="far fa-chart-bar"> </i> Import Activities</button> -->

          <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateToImportpdVideos()"><i class="far fa-chart-bar"> </i> Import Personal Development Videos</button>
          <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateTopdVideos()"><i class="fas fa-eye"> </i> View Personal Development Videos</button>
          <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateToChrysalisDashboard()"><i class="fas fa-eye"> </i> Navaras Insights</button>
          <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateToSchoolLicence()"><i class="fas fa-tasks"> </i> School Licence</button>
          <!-- <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateToSchoolAssignment()"><i class="fas fa-tasks"> </i> School Subject Assignment</button> -->
        </div>
      </div>
    </div>
  
  