import { Component, OnInit } from '@angular/core';
import { AllApisService} from '../all-apis.service';
import {LessonServiceService} from '../lesson-dashboard/lesson-service.service';
import { Router} from '@angular/router';
import { ConfirmDialogService } from '../shared/confirm-dialog/confirm-dialog.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {NotifierService} from 'angular-notifier'
import { YtplayerService } from '../shared/ytplayer/ytplayer.service';

declare var $:any;
@Component({
  selector: 'app-pd-videos',
  templateUrl: './pd-videos.component.html',
  styleUrls: ['./pd-videos.component.css']
})
export class PdVideosComponent implements OnInit {

   videoList = []; 
  constructor(private route:Router, private allApiServ:AllApisService, private serv:LessonServiceService, private confirmBox:ConfirmDialogService, private sanitizer: DomSanitizer,private notifier:NotifierService, private ytServ:YtplayerService) { }

  ngOnInit(): void {
      this.getVideosList()
  }

  populateTable2() {
    let action_btn =  '<i style="padding-left:10px,position:pointer;" class="fas fa-eye  text-danger links" title="Display Links"></i><i style="padding-left:10px;position:pointer;"  class="fas fa-edit text-info edit" title="Edit Details"></i> <i style="padding-left:10px;" class="far fa-trash-alt delete text-danger" title="Delete" *ngIf="false"></i> <i style="padding-left:10px,position:pointer;" class="fas fa-arrow-right  text-info move" title="Move to Activites"></i>'
    let action_btn_without_delete =  '<i style="padding-left:10px,position:pointer;" class="fas fa-eye  text-danger links" title="Display Links"></i><i style="padding-left:10px;position:pointer;"  class="fas fa-edit text-info edit" title="Edit Details"></i> <i style="padding-left:10px,position:pointer;" class="fas fa-arrow-right  text-info move" title="Move to Activites"></i>'
    let self=this;
    if ($.fn.DataTable.isDataTable(".example1")) {
      $('.example1').DataTable().clear().destroy();
    };
    $('.showhideTable1').css('display', 'block');
    const example = $('.example1')
      .DataTable({
        data: this.videoList,
        columns: [
          
          { data: 'sequence', defaultContent: '<i>Not Set</i>' },
          { data: 'category', defaultContent: '<i>Not Set</i>' },
          { data: 'title', defaultContent: '<i>Not Set</i>' },
          { data: 'url', defaultContent: '<i>Not Set</i>' },
          { data: 'videoFor', defaultContent: '<i>Not Set</i>' },
         

        ],
        dom: 'Bfrtip',
      
      "order": [[ 0, "asc" ]],
        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']]
      });
     
     
  
      
      
  }

  getVideosList(){
    console.log('came here');
    this.serv.getpdVideos()
    .subscribe(res=>{
      if(res.status ){
        
        this.videoList=res["result"];
        this.populateTable2();
      }
    })
  }
 


  backClicked(){
    this.route.navigate(['/masterAdmin/dashboard']);
  }

  
}
