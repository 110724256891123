import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { WindowRefService } from './window-ref.service';
import { MasterAdminComponent } from './master-admin/master-admin.component';

declare global {
  interface Window { 
              webkit: any;
              Android: any; 
            }
}

@NgModule({
  declarations: [MasterAdminComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    SharedModule
  ],
  providers: [WindowRefService]
})
export class ManageContentModule { }
