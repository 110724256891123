import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {AllApisService} from '../all-apis.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {
  baseurl=this.allApiServ.baseurl+'/plan';
  teacherSubList: any;
  timeInterval : any;
  apiInterval : any;
  constructor(private http: HttpClient, private allApiServ:AllApisService) { }

  downloadPdf(url:string) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(url, { headers: headers, responseType: 'blob' as 'json' });
  }

  getSchoolLessons(obj:any){
    let params=new HttpParams();
    let schoolInfo = this.allApiServ.getSchoolInfo();
    if(schoolInfo && obj.standard) {
      let obj1 = schoolInfo.standardList.filter(std=> std.standard == obj.standard);
      if(obj1 && obj1[0] && obj1[0].variant && obj1[0].version && obj1[0].contentStandard) {
        params=params.append('subject',obj.subject);
        params=params.append('version', obj1[0].version);
        params=params.append('variant', obj1[0].variant);
        params=params.append('standard', obj1[0].contentStandard);
      }      
    } 
    this.allApiServ.httpOptions.params=params;
    return this.http.get<any>(this.allApiServ.baseurl+'/lesson/schoolLesson',this.allApiServ.httpOptions);
  }

  getLessonActivity(obj:any){
    return this.http.post<any>(this.baseurl+'/activity',obj,this.allApiServ.httpOptions);
  }

  unlockContent(obj:any) {
    return this.http.post<any>(this.baseurl+'/unlockContent',obj,this.allApiServ.httpOptions);
  }

  updateActivityFeedback(searchObj: any, feedback:string, studentId:string, teacherId: string, teacherName : any) {
    return this.http.post<any>(this.allApiServ.baseurl+'/response/updateFeedback',{searchObj: searchObj, feedback: feedback, studentId: studentId, teacherId: teacherId, teacherName: teacherName},this.allApiServ.httpOptions);
  }

  getSubjectList(teacherId?:string, schoolId?:string){

    let params = new HttpParams();
    params = params.append('teacherId', teacherId);
    params = params.append('schoolId', schoolId);
    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(this.allApiServ.baseurl+'/teacher/getClassSubject',this.allApiServ.httpOptions);
  }

  getStudentList(searchObj) {
    return this.http.post<any>(this.allApiServ.baseurl+'/teacher/fetchStudents', searchObj, this.allApiServ.httpOptions);
 }

  getActivityResponse(obj: any){
    let params=new HttpParams();
    params=params.append('standard',obj.standard);
    params=params.append('section',obj.section);
    params=params.append('subject',obj.subject);
    params=params.append('week',obj.week);
    params=params.append('lessonId',obj.lessonId);
    params=params.append('activityNo',obj.activityNo);
    
    this.allApiServ.httpOptions.params=params;
    return this.http.get<any>(this.allApiServ.baseurl+'/response/activity',this.allApiServ.httpOptions);
  }

  getPerformance(obj:any){
    
    return this.http.post<any>(this.allApiServ.baseurl+'/response/getProgress',obj,this.allApiServ.httpOptions);
  }

  uploadResponse(fomData:FormData){
    var serverUrl = this.allApiServ.baseurl + '/lesson/uploadRes';
    let teacherName = this.allApiServ.logedInUserName();
    fomData.append('teacherName', teacherName);
    return this.http.post<any>(serverUrl,fomData,this.allApiServ.httpOptions);
  }

  makeUserActive(){ 
    let userDetail = localStorage.getItem('userDetail')
    let user = {}
    if(userDetail && userDetail !== ''){
      user = JSON.parse(userDetail);
    }
    let activeTime = localStorage.getItem('activeTime');
    let actTime = 0;
    if(activeTime)actTime = parseInt(activeTime)
    let obj = {userId : user['sourceId'],role : 'Teacher'}
    obj['activeTime'] =actTime  
    return this.http.post<any>(this.allApiServ.baseurl+'/dump/updateLogin',obj,this.allApiServ.httpOptions);
  }

  updateLastActive(obj : any){
    return this.http.patch<any>(this.allApiServ.baseurl+'/dump/updateLastActive',obj,this.allApiServ.httpOptions);
  }

  runActiveApi(activeTime?:number){
    let userDetail = localStorage.getItem('userDetail')
    let user = {}
    if(userDetail && userDetail !== ''){
      user = JSON.parse(userDetail);
    }
    this.updateLastActive({userId : user['sourceId'], activeTime : activeTime, role : 'Teacher'})
    .subscribe(res => {
      if(res['status'])localStorage.setItem('activeTime', '0');
    })
  }


  updateActiveness(){
   
    this.timeInterval = setInterval(()=>{
      let activeTime = localStorage.getItem('activeTime')
      let actTime = 0
      if(activeTime){
        actTime = parseInt(activeTime)
      }
       localStorage.setItem('activeTime', (actTime + 10).toString())

       console.log('updated', actTime)
    }, 10000) 
  }

  deleteInterval(){
    clearInterval(this.timeInterval)
    // clearInterval(this.apiInterval)
  }

  updateActiveTime(){
    // this.apiInterval = setInterval(()=>{
    //   let activeTime = localStorage.getItem('activeTime')
    //   let actTime = 0
    //   if(activeTime){
    //     actTime = parseInt(activeTime)
    //   }
    //    this.runActiveApi(actTime)

    //    console.log('updatedOnServer', actTime)
    // }, 120000) 
  }

  fetchToken(){
    return this.http.post<any>(this.allApiServ.baseurl+'/updateToken',{}, this.allApiServ.httpOptions)
  }

  updateToken(){
    this.fetchToken()
    .subscribe( res => {
      console.log(res);
      if(res['status']){
        localStorage.setItem('token', res['token']);
      }
    })
  }
}



