import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { SchoolServiceService } from '../school-info/school-service.service';
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-school-licence',
  templateUrl: './school-licence.component.html',
  styleUrls: ['./school-licence.component.css']
})
export class SchoolLicenceComponent implements OnInit {

  constructor(public notifier: NotifierService, private serv: SchoolServiceService) { }
  pipe = new DatePipe('en-IN'); // Use your own locale
  schoolInfoList=[];

  ngOnInit() {
    this.getSchoolDetails();
  }
  populateTable(active:boolean) {
    let self=this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.schoolInfoList,
        columns: [
          { data: 'serialNo', defaultContent: '<i>Not Set</i>' },
          { data: 'maxAttendee', defaultContent: '<i>Not Set</i>' },
          { data: 'purchaseDate', defaultContent: '<i>Not Set</i>' },
          { data: 'expiryDate', defaultContent: '<i>Not Set</i>' },
          
          

        ],

        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']]
      });
     
  }

  getSchoolDetails(){
      this.serv.getIndividualSchoolInfoList()
      .subscribe(res=>{
        console.log("abc",res);
        if(res.result) {
        var ele=res.result[0];
        var licenceList=[]
        if(ele["licenseDetail"] && ele["licenseDetail"].length) {
          var count=0;
          ele["licenseDetail"].forEach(ld => {
            var obj={};
            if(obj["licenseDate"] !== '') {
              count++;
              obj["serialNo"]="L"+count;
              obj["purchaseDate"] = new Date(ld["licenseDate"]).toISOString().substr(0, 10) ;
              obj["maxAttendee"]=ld["maxAttendee"];
              var licDate=new Date(ld["licenseDate"])
              obj["expiryDate"] =new Date((licDate).getTime() + (30 * 86400000)).toISOString().substr(0, 10);
              licenceList.push(obj);
            }

            
        })
       
       
      }
    }
      this.schoolInfoList=licenceList;
      this.populateTable(true);
    })
  }
}