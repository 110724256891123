import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment'
import { NotifierService } from 'angular-notifier';


@Injectable({
  providedIn: 'root'
})
export class AllApisService {
  
  zoom:boolean=false;
  schoolInfo:any;
  slotList=["06:00-07:00", "07:00-08:00", "08:00-09:00", "09:00-10:00", "10:00-11:00", "11:00-12:00","12:00-13:00","13:00-14:00","14:00-15:00","15:00-16:00", "16:00-17:00", "17:00-18:00", "18:00-19:00", "19:00-20:00", "20:00-21:00", "21:00-22:00"];
  lessonInfo:any;
  topicId:string;
  ytLink:string;
  loginUser:any;
  loginDetail: any;
  studentDetail: any;
  constructor(
    private router:Router, public notifier: NotifierService
  ) { }

 baseurl = environment.apiHost;
 /////////////Token//////////////////////////////////
  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.getToken(),
      "Accept": "application/json"
      //localStorage.getItem('token')
      // 'ID': emp.UserID,
    }),
    params: new HttpParams()
  };

  updateHttpOptions() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.getToken(),
        "Accept": "application/json"
        //localStorage.getItem('token')
        // 'ID': emp.UserID,
      }),
      params: new HttpParams()
    };
  }
    /*  to remove the token from the local storage */
    logoutUser() {
      this.loginUser = undefined;
      localStorage.clear();
      this.httpOptions.headers=new HttpHeaders();
      this.httpOptions.params=new HttpParams();
      var resObj = {}
      resObj['status'] = true
      resObj['message'] = "Exit"
      resObj['result'] = {}
      console.log('json objec', JSON.stringify(resObj));
      if (window.webkit && window.webkit.messageHandlers) {
        window.webkit.messageHandlers.exitViewCallBack.postMessage(JSON.stringify(resObj));
      } else if (window.Android) {
        window.Android.logoutUser(JSON.stringify(resObj));
      } else {
        this.router.navigate(['/']);
      }
      return false;
    }
  
    /* to retrive token to send to the server */
    getToken() {
      return localStorage.getItem('token');
    }
  
    /* to check token is present in local storage */
    isLoggedIn() {
      let loggedIn = false;
      if(localStorage.getItem('token') && localStorage.getItem('token') != "") {
        loggedIn = true;
      }
      return loggedIn; /* true or false */
    }

    ensureLogin(notifyType: string, notifyMessage: string) {
      if(!this.isLoggedIn()) {
        this.notifier.notify(notifyType, notifyMessage)
        this.router.navigate(['/user-sign-in']);
      }
    }
  
    logedInUserName(): any {
      // 
      try {
        let loginUser = JSON.parse(localStorage.getItem('userDetail'));
        this.loginUser = JSON.parse(localStorage.getItem('userDetail'));
        if(loginUser.roles.includes("Chrysallis Admin")) {
          return `${loginUser.userName}`
        } else if(loginUser) {
          return `${loginUser.firstName} ${loginUser.lastName}`
        } else {
          return ''
        }
      } catch (error) {
        return ''
      }
    }
  
    setLogedUserName(): any {
      try {
        this.loginUser = JSON.parse(localStorage.getItem('userDetail'));
      } catch (error) {
        this.loginUser = undefined;
      }
    }

    getSchoolInfo() {
      let school = localStorage.getItem('schoolInfo')
      if(school && school != "" && school != 'undefined') {
        return JSON.parse(localStorage.getItem('schoolInfo'));
      } else {
        return {schoolName: "Symbiosis Institute", smSchoolId: "1997", _id: "61fe06d220743d14c9abf74a"};
      }
    }
  
    getStudentInfo() {
      return JSON.parse(localStorage.getItem('studentDetail'));
    }

    studentInfo() {
      if(localStorage.getItem('studentInfo')) {
        return JSON.parse(localStorage.getItem('studentInfo'));
      } else {
        return null;
      }
    }
  
    getBaseUrl(){
      if(typeof localStorage.getItem('schoolInfo')!=='undefined' && localStorage.getItem('schoolInfo')!==""){
        this.schoolInfo=JSON.parse(localStorage.getItem('schoolInfo'));
        // return this.baseurl+'/team';
        if(this.schoolInfo.zoomPaid){
          return this.baseurl+'/zoom';
        }
        else if(this.schoolInfo.impartus) {
          return this.baseurl+'/impartus';
        }
        else if(this.schoolInfo.team) {
          return this.baseurl+'/team';
        }
        else if(this.schoolInfo.gotomeeting){
          return this.baseurl+'/gtm';
        }
        else{
          return this.baseurl+'/liveClass';
        } 
      }
    } 
  
    getRelativePath(){
      return "";
      if(typeof localStorage.getItem('schoolInfo')!=='undefined' && localStorage.getItem('schoolInfo')!==""){
        this.schoolInfo=JSON.parse(localStorage.getItem('schoolInfo'));
        if(this.schoolInfo.zoomPaid){
          return '/zoom';
          
        }
        else{
          return "";
        } 
      }
    }

    getLoginDetail() {
      if(this.isLoggedIn() && !this.loginDetail) {
        this.setLoginDetail()
      }
      return this.loginDetail;
    }

    getStudentDetail() {
      if(this.isLoggedIn() && !this.loginDetail) {
        this.setLoginDetail()
      }
      return this.studentDetail;
    }

    setLoginDetail() {
      if(localStorage.getItem('userDetail') && !["", "undefined"].includes(localStorage.getItem('userDetail'))) {
        this.loginDetail = JSON.parse(localStorage.getItem('userDetail'));
        if (this.loginDetail && this.loginDetail.roles && this.loginDetail.roles[0] == "Parent") {
          this.studentDetail = JSON.parse(localStorage.getItem('studentDetail'));
        }
      }
    }
  
  
  }