<div class="br-pagebody" style="background-color:white;">

  <button type="button" class="btn btn-primary m-2" (click)="moveToSchoolInfo()"><i class="fa fa-list"
      aria-hidden="true"> </i>School Info</button>
  <!-- <button type="button" class="btn btn-primary m-2" (click)="moveToAttendeeList()"><i class="fa fa-child" aria-hidden="true"> Attendance</i></button> -->
  <br>
  <div class="col-md-12 showhideTable table-responsive">
    <table class="display example" cellspacing="0" width="100%">
      <thead>
        <tr>

          <th>Email Id</th>
          <th>Client Id</th>
          <th>Client Secret</th>
          <th>Basic Token</th>
          <th>User List</th>
        </tr>
      </thead>
    </table>
  </div>
</div>