<div style="width: 20%;">
    <app-navigation-panel-admin val='teacherCredentials'></app-navigation-panel-admin>
  </div>

  <div class="container-fluid br-mainpanel">
  
  <div class="col-md-12" *ngIf="!showLoader">
    
<div class="card ">
    <div class="card-body">
        <h5 class="card-title">Teacher Team Credential</h5>
        <div class="col-md-12">
            <form #f="ngForm">

                <div class="row mt-0 mb-0" style="text-align: left;">
                    <div class="col-md-3"><strong>Teacher</strong></div>
                    <div class="col-md-4" ><strong>Username</strong></div>
                    <div class="col-md-4" ><strong>Password</strong></div>

                </div>

                <div class="row loopRowDiv mt-0 mb-0" *ngFor="let obj of teacherSubList;let i=index">

                    <div class="form-group col-md-3 form-control-sm">
                        <ng-multiselect-dropdown [placeholder]="'Select Teacher'"
                            [settings]="dropdownSettings" [data]="teacherList" (onSelect)="updateEventTeacher($event,i)">
                        </ng-multiselect-dropdown>

                    </div>
                    <div class="form-group col-md-4 form-control-sm" >
                        <input type="text" #user="ngModel" name="username{{i}}" [(ngModel)]="obj.teamUsername" 
                        class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-md-4 form-control-sm" >
                        <input type="text" #pass="ngModel" name="Password{{i}}" [(ngModel)]="obj.teamPassword"
                        class="form-control form-control-sm">
                    </div>

                   
                   
                    
                    <!-- remove button -->
                    <div class="form-group col-md-1">
                        <div class="removeBtn"><button class="btn" (click)="removeRow(i)">
                                <i class="fas fa-trash-alt"></i></button></div>
                    </div>

                </div>
                <div class="col-md-12 btnContainer">
                    <div class="" data-toggle="modal" data-target="#fineModal" (click)="addNewRow()"><i
                            class="fas fa-plus text-success"></i></div>

                </div>
                <!-- Submit Buttons -->
                <div class="form-group col-md-12" style="text-align: center;">
                    <button class="btn btn-warning commonBtn  m-2" (click)="onSubmit()">
                        Submit </button>

                        <button class="btn btn-primary" (click)="navigateToImport()">
                            Import Excel </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="card ">
    <div class="card-body">
        <div class="row">
            <h5 class="card-title">Teacher Team Credential List
            <button class="btn btn-warning commonBtn m-2" (click)="getTeacherSubjectList()"><i class="fa fa-refresh" aria-hidden="true"></i>Update List</button>
        </h5>
        </div>
        
        <div class="col-md-12 showhideTable">
            <table class="display example" cellspacing="0" width="100%">
                <thead>
                  <tr>
                    
                    <th>Name</th>
                    <th>Team Username</th>
                    <th>Password</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
              </table>
        </div>
    </div>
</div>

  </div>
  </div>
  
  <page-loader *ngIf="showLoader"></page-loader>