<div class='row'>
  <div class='col-md-2'>Teacher Name</div>
  <div class='col-md-2'>Subject</div>
  <div class='col-md-2'>Standard</div>
  <div class='col-md-2'>Section</div>
  <div class='col-md-2'>Present</div>
  <div class='col-md-2'>Total</div>
</div>
<div class='row'>
  <div class='col-md-2'>{{seminarInfo?.teacherName}}</div>
  <div class='col-md-2'>{{seminarInfo?.subject}}</div>
  <div class='col-md-2'>{{seminarInfo?.class}}</div>
  <div class='col-md-2'>{{seminarInfo?.section}}</div>
  <div class='col-md-2'>{{seminarInfo?.present}}</div>
  <div class='col-md-2'>{{seminarInfo?.expected}}</div>
</div>
<div class="col-md-12 showhideTable">
    <table class="display example" cellspacing="0" width="100%">
        <thead>
          <tr>
            
            <th>Name</th>
            <th>Standard</th>
            <th>Section</th>
            <th>Present</th>
            <th>Appearance Count</th>
          </tr>
        </thead>
      </table>
</div>
<page-loader *ngIf="showLoader"></page-loader>