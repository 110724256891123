import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WindowRefService } from './window-ref.service';
import { PaymentService } from './payment.service';
import { OnlinePaymentComponent } from './online-payment/online-payment.component';
import { SharedModule } from '../shared/shared.module';

declare global {
  interface Window { 
              webkit: any;
              Android: any; 
            }
}

@NgModule({
  declarations: [
    OnlinePaymentComponent
  ],
  imports: [
    CommonModule, SharedModule
  ],
  providers: [WindowRefService, PaymentService],
})
export class PaymentModule { }
