<div>
    <div class="breadcrumb" >
        <div class="col-md-12">
            <div class="row row-sm" style="padding-top: 10px; margin: 0 auto; text-align: center;">
                <div class="col-md-12 gradBack">
                  <span style="float: left;padding-left: 12px;" (click)="backClicked()">
                    <i class="fas fa-arrow-left left-arr-icon fa-2x"></i>
                  </span>
                    <h4 style="font-size: 20px;">Topics</h4>
                  </div>
        </div>
        </div>
        
        <!-- <li class="breadcrumb-item active">Webinar List</li> -->
    </div>
    <br>
  </div>
    
    <div class="br-pagebody" style="background-color:white;">
      <div class="row row-sm" style="margin-left: 0;margin-right:0;">
        <div class="col-md-12 showhideTable1" >
          <table class="display example1" cellspacing="0" width="100%">
            <thead>
              <tr>
    
                <th>Topic Id</th>
                <th>Module Id</th>
                <th>Content Title</th>
                <th>Session</th>
                <th>Links</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>

    
    <div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="errorModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body" style="text-align: center;">
              <div class="showhideTable" >
                <table class="table" cellspacing="0" width="100%">
                  <thead>
                      <tr>
  
                          <th>URL Info</th>
                          <th>Action</th>
                          <th>URL</th>
                          
                      </tr>
                      <tr *ngFor="let link of linkList; let i=index;">
                          <td>{{link.title}}</td>
                          <td> <button class="btn btn-primary btn-sm" (click)="displayModel(i)"><b>Open</b></button></td>
                          <td> {{link.url}} <button class="btn btn-primary btn-sm" (click)="editModel(i)"><b>Edit</b></button></td>
                      </tr>
                  </thead>
              </table>
              </div>
           
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="editModal" tabindex="-1" role="dialog" aria-labelledby="errorModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body" style="text-align: center;">
            <form class="form-inline">
              <div class="form-group mb-2 col-md-12">
                <label>Current Value: {{editLink}}</label>
              </div>
              <div class="form-group col-md-8 mb-2">
                <label for="input2" class="col-md-3">New Value</label>
                <input  id="input22" name="linkName" class='form-control col-md-8' [(ngModel)]='newLink'>
              </div>
              <button class="btn btn-primary mb-2 col-md-2" (click)="updateLink()">Update</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="editModel" tabindex="-1" role="dialog" aria-labelledby="editModel" aria-hidden="true">
      <div class="modal-dialog modal-md " role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit Content</h5>
             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <h6>Content Title</h6>
              <input class="form-control form-control-sm" type="text" placeholder="Enter Content Title"
                name="editContentTitle" [(ngModel)]="editContentTitle">
            </div>
            <div class="form-group">
              <h6>Week</h6>
              <input class="form-control form-control-sm" type="text" placeholder="Enter Week" name="editWeek"
                [(ngModel)]="editWeek">
            </div>
            <div class="form-group">
              <h6>Sesson</h6>
              <input class="form-control form-control-sm" type="text" placeholder="Enter Sesson" name="editSesson"
                [(ngModel)]="editSesson">
            </div>
            <div class="form-group">
              <h6>Sequence</h6>
              <input class="form-control form-control-sm" type="text" placeholder="Enter Sequence" name="editSequence"
                [(ngModel)]="editSequence">
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="updateEditDetails()">Add</button>
            </div>
          </div>
    
        </div>
      </div>
    </div>


    