import { Component, OnInit } from "@angular/core";
import { AllApisService } from "../all-apis.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmDialogService } from "../shared/confirm-dialog/confirm-dialog.service";
import { NotifierService } from "angular-notifier";
import { YtplayerService } from "../shared/ytplayer/ytplayer.service";
import {
  QuestionListService,
  searchKeys,
} from "../master-admin/question-list/question-list.service";
import { BookContentListService } from "../book-content-list/book-content-list.service";
import { StudentService } from "../student/student.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";

declare var $: any;
@Component({
  selector: "app-fetch-questions",
  templateUrl: "./fetch-questions.component.html",
  styleUrls: ["./fetch-questions.component.css"],
})
export class FetchQuestionsComponent implements OnInit {
  questionList = [];
  stdList = [];
  categoryList = [];
  seriesList = [];
  subjectList = [];
  chapterList = [];
  topicList = [];
  dropdownSettings: IDropdownSettings;
  editId: any;
  editTitle: any;
  editSequence: any;
  showLoader: boolean = false;
  loaderMessage: string = "";
  searchObj = new searchKeys();
  popup = false;
  linkList: any;
  deletelesson: boolean = false;
  videoLink: any;
  safeVideo: any;
  checkLink: any;
  editLink: string;
  newLink: string;
  editIndex: any;
  lessonIndex: any;
  deleteIndex: any;
  subjectMaps: any;
  sectionList: any[];
  allCategoryList: any;
  allSubjectList: any;
  bookContentKeys = [];
  bookChapters: any[];
  questionKeys: any[];
  viewOption: any;
  edit: boolean = false;
  bookChapterNos: { chapterNo: any; chapterName: any }[];
  schoolInfo: any;
  userDetail: any;
  subjectListObj: any;
  currStds: any;
  hideLeftNav: boolean = false;
  constructor(
    private serv: QuestionListService,
    private stuServ: StudentService,
    private bookServ: BookContentListService,
    private quesServ: QuestionListService,
    private allApiServ: AllApisService,
    private route: Router,
    public router: ActivatedRoute,
    private confirmBox: ConfirmDialogService,
    private notifier: NotifierService,
    private ytServ: YtplayerService
  ) {}

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: false,
    };
    let localHideLeftNav = localStorage.getItem("hideLeftNav");
    if (localHideLeftNav && localHideLeftNav == "Y") {
      this.hideLeftNav = true;
    }
    this.viewOption = this.router.snapshot.queryParams.editChapters;
    if (this.viewOption && this.viewOption == "editChapters") {
      this.edit = true;
    } else {
      this.edit = false;
    }
    if (!this.serv.searchObj || !this.serv.searchObj.examName) {
      let localSearchObj = localStorage.getItem("searchObj");
      if (localSearchObj && localSearchObj != "undefined") {
        this.searchObj = JSON.parse(localSearchObj);
      }
    } else {
      this.searchObj = this.serv.searchObj;
    }
    if (
      typeof localStorage.getItem("schoolInfo") !== undefined &&
      localStorage.getItem("schoolInfo") !== ""
    ) {
      this.schoolInfo = JSON.parse(localStorage.getItem("schoolInfo"));
    } else {
    }
    if (
      this.schoolInfo &&
      localStorage.getItem("userDetail") &&
      localStorage.getItem("role") &&
      localStorage.getItem("role") == "Teacher"
    ) {
      this.showLoader = true;
      this.loaderMessage = "Getting Classes......";
      $("#alertModal").modal("show");
      this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
      this.stuServ
        .getSubjectList({ role: "Teacher", userId: this.userDetail.sourceId })
        .subscribe((res) => {
          if (res["status"]) {
            this.subjectListObj = res.subjects;
            this.subjectListObj = this.subjectListObj.filter(
              (a) => a.std && a.subject && !a.ck_subject_id
            );
            this.showLoader = false;
            $("#alertModal").modal("hide");
            this.getQuestionKeys();
          }
        });
    } else {
      this.getQuestionKeys();
    }
  }

  getSubjectList() {
    this.serv.getSubject().subscribe((res) => {
      this.allSubjectList = res.subjects;
      this.allCategoryList = res.categoryList;
    });
  }

  updateSeriesList() {
    if (!this.edit) {
      this.searchObj.series = "";
      this.searchObj.chapterNo = "";
      this.searchObj.topic = [];
    }
    this.showLoader = true;
    this.loaderMessage = "Getting Series......";
    $("#alertModal").modal("show");
    this.seriesList = [];
    if (
      this.bookContentKeys &&
      this.bookContentKeys.length &&
      this.searchObj.subject
    ) {
      let filteredKeys = this.bookContentKeys.filter(
        (a) =>
          a.std == this.searchObj.std && a.subject == this.searchObj.subject
      );
      this.seriesList = filteredKeys.map((e) => e.series);
      this.seriesList = [...new Set(this.seriesList)];
      if (
        this.subjectListObj &&
        this.subjectListObj.length &&
        this.currStds &&
        this.currStds.length
      ) {
        let currStd = this.currStds.filter(
          (st) => st.contentStandard == this.searchObj.std
        );
        this.seriesList = currStd[0].subjectList
          .filter(
            (a) =>
              a.subject.toUpperCase() == this.searchObj.subject.toUpperCase()
          )
          .map((e) => e.series);
      } else if (this.schoolInfo && this.currStds && this.currStds.length) {
        this.subjectList = this.currStds
          .filter(
            (st) =>
              st.contentStandard == this.searchObj.std &&
              this.subjectList
                .map((name) => name.toUpperCase())
                .includes(st.subject.toUpperCase())
          )
          .map((a) => a.subject);
      }
      if (
        this.searchObj &&
        this.searchObj.series &&
        this.searchObj.series != ""
      ) {
        this.updateChapterList(true);
      }
    }
    this.showLoader = false;
    $("#alertModal").modal("hide");
  }

  updateSubject() {
    if (!this.edit) {
      this.searchObj.subject = "";
      this.searchObj.series = "";
      this.searchObj.chapterNo = "";
      this.searchObj.topic = [];
    }
    this.showLoader = true;
    this.loaderMessage = "Getting Subjects......";
    $("#alertModal").modal("show");
    this.subjectList = [];
    if (
      this.bookContentKeys &&
      this.bookContentKeys.length &&
      this.searchObj.std
    ) {
      let filteredKeys = this.bookContentKeys.filter(
        (a) => a.std == this.searchObj.std
      );
      this.subjectList = filteredKeys
        .filter((fk) => fk.subject != "")
        .map((e) => e.subject);
      if (
        this.subjectListObj &&
        this.subjectListObj.length &&
        this.currStds &&
        this.currStds.length
      ) {
        let currStd = this.currStds.filter(
          (st) => st.contentStandard == this.searchObj.std
        );
        this.subjectList = this.subjectListObj
          .filter(
            (e) =>
              e.std == currStd[0].standard &&
              this.subjectList
                .map((name) => name.toUpperCase())
                .includes(e.subject.toUpperCase())
          )
          .map((a) => a.subject);
      } else if (this.schoolInfo && this.currStds && this.currStds.length) {
        this.subjectList = this.currStds
          .filter(
            (st) =>
              st.contentStandard == this.searchObj.std &&
              this.subjectList
                .map((name) => name.toUpperCase())
                .includes(st.subject.toUpperCase())
          )
          .map((a) => a.subject);
      }
      this.subjectList = [...new Set(this.subjectList)];
      if (
        this.searchObj &&
        this.searchObj.subject &&
        this.searchObj.subject != ""
      ) {
        this.updateSeriesList();
      }
    }
    this.showLoader = false;
    $("#alertModal").modal("hide");
  }

  updateChapterList(auto = false) {
    this.searchObj.chapterNo = "";
    this.searchObj.topic = [];
    this.showLoader = true;
    this.loaderMessage = "Getting Chapters......";
    $("#alertModal").modal("show");
    this.bookChapters = [];
    if (!auto) {
      this.searchObj.chapterNo = "";
    }
    if (
      this.bookContentKeys &&
      this.bookContentKeys.length &&
      this.searchObj.series
    ) {
      let filteredKeys = this.bookContentKeys.filter(
        (a) =>
          a.subject.toUpperCase() == this.searchObj.subject.toUpperCase() &&
          a.series == this.searchObj.series &&
          a.std == this.searchObj.std
      );
      this.bookChapters = filteredKeys;
      this.bookChapterNos = filteredKeys.map((x) => x.chapterNo);
      this.bookChapterNos = [...new Set(this.bookChapterNos)];
      if (
        this.searchObj &&
        this.searchObj.chapterNo &&
        this.searchObj.chapterNo != ""
      ) {
        this.updateTopicList(true);
      }
    }
    this.showLoader = false;
    $("#alertModal").modal("hide");
  }

  getChapterName(chapterNo) {
    return `${chapterNo}-${
      this.bookChapters.filter((a) => a.chapterNo == chapterNo)[0].chapterName
    }`;
  }

  updateTopicList(auto = false) {
    this.searchObj.topic = [];
    this.showLoader = true;
    this.loaderMessage = "Getting Topics......";
    $("#alertModal").modal("show");
    if (!auto) {
      this.searchObj.topic = [];
    }
    this.topicList = [];
    if (
      this.bookContentKeys &&
      this.bookContentKeys.length &&
      this.searchObj.chapterNo
    ) {
      let filteredKeys = this.bookContentKeys.filter(
        (a) =>
          a.subject.toUpperCase() == this.searchObj.subject.toUpperCase() &&
          a.series == this.searchObj.series &&
          a.std == this.searchObj.std &&
          a.chapterNo == this.searchObj.chapterNo
      );
      this.topicList = filteredKeys.map((e) => e.topic);
      this.topicList = [...new Set(this.topicList)];
      console.log("topics list", this.topicList);
    }
    this.showLoader = false;
    $("#alertModal").modal("hide");
  }

  getQuestionKeys() {
    this.showLoader = true;
    this.loaderMessage = "Getting Classes......";
    $("#alertModal").modal("show");
    this.bookContentKeys = [];
    this.quesServ.getQuestionKeys().subscribe((res) => {
      this.bookContentKeys = res.result;
      if (this.bookContentKeys && this.bookContentKeys.length) {
        // this.stdList = this.bookContentKeys.map((a) => a.std);
        // this.stdList = [...new Set(this.stdList)];
        if (
          this.subjectListObj &&
          this.schoolInfo &&
          this.schoolInfo.standardList &&
          this.schoolInfo.standardList.length
        ) {
          this.stdList = this.subjectListObj.map((a) => a.std);
          this.currStds = this.schoolInfo.standardList.filter(
            (std) =>
              this.stdList.includes(std.standard) &&
              std.contentStandard &&
              std.contentStandard != ""
          );
          this.stdList = this.currStds.map((a) => a.contentStandard);
          this.stdList = [...new Set(this.stdList)];
        } 
        // else if (
        //   this.schoolInfo &&
        //   this.schoolInfo.standardList &&
        //   this.schoolInfo.standardList.length
        // ) {
        //   let currStds = this.schoolInfo.standardList.filter((std) =>
        //     this.stdList.includes(std.contentStandard)
        //   );
        //   this.stdList = currStds.map((a) => a.contentStandard);
        //   this.stdList = [...new Set(this.stdList)];
        // }
        if (this.searchObj && this.searchObj.std && this.searchObj.std != "") {
          this.updateSubject();
        }
      }
    });
    this.showLoader = false;
    $("#alertModal").modal("hide");
  }

  goToSelectQuestons() {
    if (
      this.searchObj.std &&
      this.searchObj.std != "" &&
      this.searchObj.std != " "
    ) {
    } else {
      return this.notifier.notify("error", "Please provide class name.");
    }
    if (
      this.searchObj.subject &&
      this.searchObj.subject != "" &&
      this.searchObj.subject != "Select Subject"
    ) {
    } else {
      return this.notifier.notify("error", "Please provide subject name.");
    }
    if (
      this.searchObj.series &&
      this.searchObj.series != "" &&
      this.searchObj.series != "Select Series"
    ) {
    } else {
      return this.notifier.notify("error", "Please provide series name.");
    }
    if (
      this.searchObj.chapterNo &&
      this.searchObj.chapterNo != "" &&
      this.searchObj.chapterNo != "Select Chapters"
    ) {
    } else {
      return this.notifier.notify("error", "Please provide chapter name.");
    }
    if (
      this.searchObj.std &&
      this.searchObj.std != "" &&
      this.searchObj.subject &&
      this.searchObj.subject != ""
    ) {
      this.serv.searchObj = this.searchObj;
      localStorage.setItem("searchObj", JSON.stringify(this.searchObj));
      if (this.hideLeftNav) {
        this.route.navigate(["/admin/select-questions-2"]);
      } else {
        this.route.navigate(["/admin/select-questions"]);
      }
    } else {
      this.notifier.notify("error", "please provide exam name");
    }
  }
}
