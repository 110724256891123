import { Injectable } from "@angular/core";
import { SignInModel } from "../models/signinModel"; // new SignInModule
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AllApisService } from "../../all-apis.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  errorData: {};
  signInSmUrl = this.allApi.baseurl + "/user/sm_login";
  signInUrl = this.allApi.baseurl + "/user/login";
  otpUrl = this.allApi.baseurl + "/user/getOtp";
  signInWithSMTokenUrl = this.allApi.baseurl + "/user/sm_authenticate";

  signInFromMobileUrl = this.allApi.baseurl + "/user/authenticate_from_mobile";

  constructor(private _http: HttpClient, private allApi: AllApisService) {}

  signInWithSMToken(
    token: string,
    smitraId: string,
    studentId: any = undefined,
    userSourceId = undefined
  ) {
    return this._http
      .post<any>(this.signInWithSMTokenUrl, {
        authToken: token,
        smitraId: smitraId,
        studentId: studentId,
        userSourceId: userSourceId,
      })
      .pipe(catchError(this.handleError));
  }

  signInWithMobileToken(token: string, studentId: string = undefined) {
    localStorage.setItem("token", token);

    //Initilization Token
    let headers = new HttpHeaders({
      Authorization: "Bearer " + this.allApi.getToken(),
    });
    this.allApi.httpOptions.headers = headers;
    console.log(this.allApi.httpOptions);
    return this._http
      .post<any>(
        this.signInFromMobileUrl,
        { authToken: token, studentId: studentId },
        this.allApi.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  logMeInWithSm(signInModel: SignInModel) {
    return this._http
      .post<any>(this.signInSmUrl, signInModel)
      .pipe(catchError(this.handleError));
  }

  logMeIn(signInModel: SignInModel) {
    return this._http
      .post<any>(this.signInUrl, signInModel)
      .pipe(catchError(this.handleError));
  }

  generateOtp(obj: any) {
    return this._http
      .post<any>(this.otpUrl, obj)
      .pipe(catchError(this.handleError));
  }
  // getUserRoles(){
  //   return this._http.post<any>(this.allApi.userRoleListUrl,this.allApi.httpOptions).pipe(catchError(this.handleError));
  // }
  fetchSchoolInfo(schoolId: any) {
    let params = new HttpParams();
    params = params.append("schoolId", schoolId);
    this.allApi.httpOptions.params = params;
    return this._http
      .get<any>(
        this.allApi.baseurl + "/school/fetchSchoolInfo",
        this.allApi.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.

      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: "Oops! Request for document failed",
      errorDesc: "Something bad happened. Please try again later.",
    };
    return throwError(this.errorData);
  }
}
