import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AllApisService } from "../all-apis.service";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  baseurl = this.allApiServ.baseurl;
  constructor(private http: HttpClient, private allApiServ: AllApisService) {}

  getSubjectList() {
    return this.http.get<any>(
      this.baseurl + "/lesson/getSubject",
      this.allApiServ.httpOptions
    );
  }

  getLesson(obj: any) {
    return this.http.post<any>(
      this.baseurl + "/lesson/lessonList",
      obj,
      this.allApiServ.httpOptions
    );
  }

  getGrades() {
    return this.http.get<any>(
      this.baseurl + "/lesson/getClass",
      this.allApiServ.httpOptions
    );
  }

  getS3Url(url: any) {
    let params = new HttpParams();
    params = params.append("url", url);

    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/courses/getS3Url",
      this.allApiServ.httpOptions
    );
  }
}
