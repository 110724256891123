import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { AllApisService } from '../all-apis.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-teacher-dashboard',
  templateUrl: './teacher-dashboard.component.html',
  styleUrls: ['./teacher-dashboard.component.css']
})
export class TeacherDashboardComponent implements OnInit {

  schoolName:any

  constructor(private activeRoute: ActivatedRoute,private router: Router, private allApiService:AllApisService, private _location: Location) { }

  ngOnInit() {
    
    if(typeof localStorage.getItem('schoolInfo')!==undefined && localStorage.getItem('schoolInfo')!==""){
      this.schoolName = this.allApiService.getSchoolInfo().schoolName;
    }

  }

  navigateToAssessmentPerformance(){
    console.log();
    this.router.navigate(['/teacherAssessmentPerformance']);
  }

  navigateToQuestion(){
    // console.log(evt);
    this.router.navigate(['/question']);
  }

  navigateToEditQuestion(){
    // console.log(evt);
    this.router.navigate(['/teacherEditAssessment']);
  }

  finishTask() {
    var resObj = {}
    resObj['status'] = true
    resObj['message'] = "Exit"
    resObj['result'] = {}
    console.log('json objec', JSON.stringify(resObj));
    if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.exitViewCallBack.postMessage(JSON.stringify(resObj));
    }
    if (window.Android) {
      window.Android.finishTask(JSON.stringify(resObj));
    }
  }

  backClicked() {
    this._location.back();
  }

}
