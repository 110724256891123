import { Component, OnInit } from "@angular/core";
import { SchoolServiceService } from "../school-info/school-service.service";
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { BookContentListService } from "../book-content-list/book-content-list.service";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import QRCode from "qrcode";
declare var $: any;
@Component({
  selector: "app-school-subject-map",
  templateUrl: "./school-subject-map.component.html",
  styleUrls: ["./school-subject-map.component.css"],
})
export class SchoolSubjectMapComponent implements OnInit {
  licenseCode = "Techiediaries";
  elementType = "url";
  value = "Techiediaries";
  showLoader = true;
  currentSchool: any;
  allQrUrlData = [];
  loaderMessage = "Loading, please wait..";
  zoomUserList = [];
  gtmUserList = [];
  versionList = ["2019", "2020"];
  variantList = ["TRX", "TRS"];
  schoolInfo: school;
  schoolDetails: object;
  slotList = [
    { name: "fullDay", count: 0 },
    { name: "8-12", count: 0 },
    { name: "12-4", count: 0 },
    { name: "4-7", count: 0 },
  ];
  schoolList = [];
  schoolDisplayList = [];
  dropdownSettings = {
    singleSelection: true,
    idField: "item_id",
    textField: "item_text",
    allowSearchFilter: true,
  };
  teamInfo: any;
  classList: any;
  contentClassList: any;
  contentStandard: any;
  subjectList: any;
  newSubject = new subjectDetail();
  currSubjectList = [];
  currCategory = [];
  currSeries = [];
  currBoard = ["CBSE", "ICSE"];
  bookContentKeys: any;
  currStandardObj: standardLesson;
  stdSubjectList: any;
  currStdSubjectList: any;
  constructor(
    private router: Router,
    private serv: SchoolServiceService,
    private bookServ: BookContentListService,
    public notifier: NotifierService
  ) {}

  ngOnInit() {
    this.schoolInfo = new school();
    this.serv.lessonServ.getClass().subscribe((res) => {
      if (res) {
        this.contentClassList = res["result"];
        this.getSchoolList();
        this.getBookContentKeys();
      }
    }),
      (err) => {
        console.log("unable to fect schools");
      };
  }

  getSchoolList() {
    this.serv.getSchoolList({ schoolType: "EUPHEUS-SM" }).subscribe((res) => {
      if (res) {
        console.log("res", res);
        this.schoolList = res.schoolList;
        this.schoolDisplayList = [];
        this.schoolList.forEach((ele) => {
          this.schoolDisplayList.push({
            item_id: ele.id,
            item_text: ele.name + " (" + ele.id + ")",
          });
        });
        if (this.schoolList && this.schoolList.length == 1) {
          this.schoolDetails = {};
          this.schoolDetails["id"] = this.schoolList[0].id;
          this.schoolDetails["name"] = this.schoolList[0].name;
          this.getSchoolDetails();
        } else {
          this.showLoader = false;
        }
      }
    }),
      (err) => {
        console.log("unable to fect schools");
      };
  }

  showAllQrCode() {
    $("#pictureAllModel").modal("show");
  }

  done2() {
    $("#pictureAllModel").modal("hide");
  }

  openPDF() {
    let DATA: any = document.getElementById("htmlData");
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 150;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL("image/png");
      let PDF = new jsPDF("p", "mm", "a4");
      let position = 0;
      PDF.addImage(FILEURI, "PNG", 0, position, fileWidth, fileHeight);
      PDF.save("AllQRTeacher.pdf");
    });
  }

  async getSubjectDetails() {
    console.log("contentStandard aya", this.contentStandard);
    this.subjectList = [];
    this.currStandardObj = null;
    if (this.contentStandard && this.contentStandard != "") {
      console.log("contentStandard", this.contentStandard);
      let standardObj = this.schoolInfo.standardList.filter(
        (sl) => sl.contentStandard == this.contentStandard
      );
      console.log("classList", this.schoolInfo.standardList);
      console.log("standardObj", standardObj);
      if (standardObj && standardObj.length) {
        this.currStandardObj = standardObj[0];
        this.subjectList = standardObj[0].subjectList;
      }
      if (this.stdSubjectList && standardObj && standardObj.length) {
        let currSubs = this.stdSubjectList[standardObj[0].standard];
        this.currStdSubjectList = currSubs;
      } else {
        this.currStdSubjectList = [];
      }
      if (!this.subjectList || !this.subjectList.length) {
        this.subjectList = [];
      }
      this.newSubject = {
        subject: "",
        category: "",
        series: "",
        board: "",
        session: "",
      };
      console.log("this.currStdSubjectList", this.currStdSubjectList);
      // this.updateSubject();
    }
    console.log("final subject list ", this.subjectList);
    for (let obj of this.subjectList) {
      this.allQrUrlData.push({
        licenseCode: obj.licenseCode,
        series: obj.series,
        board: obj.board,
        category: obj.category,
        subject: obj.subject,
        url: await QRCode.toDataURL(obj.licenseCode),
      });
    }
  }

  addNewSubject() {
    console.log("this.newSubject", this.newSubject);
    if (this.subjectList && this.subjectList.length) {
      if (
        this.newSubject &&
        this.newSubject.subject != "" &&
        this.newSubject.category != "" &&
        this.newSubject.series != "" &&
        this.newSubject.session != ""
      ) {
        this.subjectList.push(this.newSubject);
        this.newSubject = {
          subject: "",
          category: "",
          series: "",
          board: "",
          session: "",
        };
      }
    } else {
      this.subjectList = [];
      if (
        this.newSubject &&
        this.newSubject.subject != "" &&
        this.newSubject.category != "" &&
        this.newSubject.series != "" &&
        this.newSubject.session != ""
      ) {
        this.subjectList.push(this.newSubject);
        this.newSubject = {
          subject: "",
          category: "",
          series: "",
          board: "",
          session: "",
        };
      }
    }
  }

  removeSubject(index) {
    this.subjectList.splice(index, 1);
  }

  getBookContentKeys() {
    this.bookServ.bookContentKeys().subscribe((res) => {
      this.bookContentKeys = res.result;
      // console.log("this.stdList", this.stdList);
      console.log("keys", res);
      this.bookContentKeys = res.result;
    });
  }

  updateSubject() {
    console.log("this.bookContentKeys", this.bookContentKeys);
    this.currSubjectList = [];
    if (
      this.bookContentKeys &&
      this.bookContentKeys.length &&
      this.contentStandard
    ) {
      let filteredKeys = this.bookContentKeys.filter(
        (a) => a.grade == this.contentStandard
      );
      console.log("filteredKeys", filteredKeys);
      this.currStdSubjectList = this.currStdSubjectList.map((a) =>
        a.toUpperCase()
      );
      this.currSubjectList = filteredKeys.filter((e) =>
        this.currStdSubjectList.includes(e.subject.toUpperCase())
      );
      console.log("this.currSubjectList", this.currSubjectList);
      this.currSubjectList = this.currSubjectList.map((e) => e.subject);
      this.currSubjectList = [...new Set(this.currSubjectList)];
    }
    if (this.currSubjectList && this.currSubjectList.length) {
      this.newSubject.subject = this.currSubjectList[0];
      this.updateCategoryList();
    }
  }

  selectSession() {
    console.log("this.bookContentKeys", this.bookContentKeys);
    console.log("this.contentStandard", this.contentStandard);
    this.newSubject.series = "";
    this.newSubject.board = "";
    this.newSubject.category = "";
    this.currSubjectList = [];
    if (
      this.bookContentKeys &&
      this.bookContentKeys.length &&
      this.contentStandard &&
      this.newSubject.session != ""
    ) {
      let filteredKeys = this.bookContentKeys.filter(
        (a) => a.grade == this.contentStandard
      );
      console.log("filteredKeys", filteredKeys);
      console.log("currStdSubjectList", this.currStdSubjectList);
      this.currStdSubjectList = this.currStdSubjectList.map((a) =>
        a.toUpperCase()
      );
      console.log("currStdSubjectList after map", this.currStdSubjectList);
      this.currSubjectList = filteredKeys.filter((e) =>
        this.currStdSubjectList.includes(e.subject.toUpperCase())
      );
      // this.currStdSubjectList.includes(e.subject.toUpperCase())
      console.log("this.currSubjectList", this.currSubjectList);
      this.currSubjectList = filteredKeys
        .filter((e) => e.session && e.session == this.newSubject.session)
        .map((e) => e.subject);
      this.currSubjectList = [...new Set(this.currSubjectList)];
      console.log("this.currSubjectList after set", this.currSubjectList);
    }
    if (this.currSubjectList && this.currSubjectList.length) {
      this.newSubject.subject = this.currSubjectList[0];
      this.updateCategoryList();
    }
  }

  updateCategoryList() {
    let selectedSub = this.newSubject.subject;
    this.currCategory = [];
    if (
      this.bookContentKeys &&
      this.bookContentKeys.length &&
      selectedSub &&
      selectedSub != ""
    ) {
      let filteredKeys = this.bookContentKeys.filter(
        (a) =>
          a.subject.toUpperCase() == selectedSub.toUpperCase() &&
          a.grade == this.contentStandard &&
          a.session &&
          a.session == this.newSubject.session
      );
      this.currCategory = filteredKeys.map((e) => e.category);
      this.currCategory = [...new Set(this.currCategory)];
    }
    if (this.currCategory && this.currCategory.length) {
      this.newSubject.category = this.currCategory[0];
      this.updateSeriesList();
    }
  }

  updateSeriesList() {
    let selectedCat = this.newSubject.category;
    this.currSeries = [];
    if (
      this.bookContentKeys &&
      this.bookContentKeys.length &&
      selectedCat &&
      selectedCat != ""
    ) {
      let filteredKeys = this.bookContentKeys.filter(
        (a) =>
          a.category == selectedCat &&
          a.subject.toUpperCase() == this.newSubject.subject.toUpperCase() &&
          a.grade == this.contentStandard &&
          a.session &&
          a.session == this.newSubject.session
      );
      this.currSeries = filteredKeys.map((e) => e.series);
      this.currSeries = [...new Set(this.currSeries)];
    }
    if (this.currSeries && this.currSeries.length) {
      this.newSubject.series = this.currSeries[0];
    }
  }

  saveInfo() {
    if (this.subjectList && this.subjectList) {
      console.log("this.subjectList 1", this.subjectList);
      // if (
      //   this.newSubject &&
      //   this.newSubject.subject != "" &&
      //   this.newSubject.category != "" &&
      //   this.newSubject.series != "" &&
      //   this.newSubject.session != ""
      // ) {
      //   this.subjectList.push(this.newSubject);
      // }
      console.log("this.subjectList 2", this.subjectList, this.contentStandard);
      if (this.subjectList && this.subjectList) {
        let smSchoolId = this.schoolDetails["id"];
        this.serv
          .updateSchoolDetail({
            smSchoolId: smSchoolId,
            standard: this.contentStandard,
            subjectList: this.subjectList,
          })
          .subscribe(
            (res) => {
              if (this.currStandardObj && res.subjectList) {
                this.currStandardObj.subjectList = res.subjectList;
              }
              console.log(res);
              if (res.status) {
                this.notifier.notify(
                  "success",
                  "Information saved successfully"
                );
              } else {
                this.notifier.notify("error", res.message);
              }
            },
            (err) => {
              this.notifier.notify("error", "Something bad happend");
            }
          );
      } else {
        this.subjectList = [];
        this.subjectList.push({
          subject: "",
          category: "",
          series: "",
          board: "",
          session: "",
        });
        this.notifier.notify("error", "empty values not allowed");
      }
    }
  }
  getSchoolDetails() {
    this.schoolInfo = new school();
    this.schoolInfo.standardList = [];
    this.showLoader = true;
    this.loaderMessage = "fetching school detail.";
    if (
      this.schoolDetails !== null &&
      typeof this.schoolDetails !== "undefined" &&
      this.schoolDetails["id"] !== null &&
      typeof this.schoolDetails["id"] !== "undefined"
    ) {
      this.serv.getSchoolInfo(this.schoolDetails["id"]).subscribe((res) => {
        console.log("res from getSchoolInfo", res);
        if (res["status"]) {
          this.schoolInfo = res["result"];
          console.log("this schoolinfo", this.schoolInfo);
        } else {
          this.schoolInfo["schoolName"] = this.schoolDetails["name"];
        }
        if (res.standardList) {
          this.classList = res.standardList;
        } else {
          this.classList = null;
        }
        if (res.subjectObj) {
          this.stdSubjectList = res.subjectObj;
        }
        if (
          (!this.schoolInfo.standardList ||
            !this.schoolInfo.standardList.length) &&
          this.classList
        ) {
          let standardLessonList = [];
          this.classList.forEach((std) => {
            let stdObj = {};
            stdObj["standard"] = std.standard;
            stdObj["contentStandard"] = "";
            stdObj["licenseCount"] = 0;
            stdObj["licenseAdded"] = 0;
            stdObj["licenseCode"] = "";
            stdObj["moreLicense"] = 0;

            standardLessonList.push(stdObj);
          });
          this.schoolInfo.standardList = standardLessonList;
        } else {
          let newStdList = [];
          this.classList.forEach((std) => {
            let slObj = this.schoolInfo.standardList.filter(
              (sl) => sl.standard == std.standard
            );
            if (slObj && slObj.length) {
              slObj[0]["moreLicense"] = 0;
              newStdList.push(slObj[0]);
            } else {
              let stdObj = {};
              stdObj["standard"] = std.standard;
              stdObj["contentStandard"] = "";
              stdObj["licenseCount"] = 0;
              stdObj["licenseAdded"] = 0;
              stdObj["licenseCode"] = "";
              stdObj["moreLicense"] = 0;
              newStdList.push(stdObj);
            }
          });
          this.schoolInfo.standardList = newStdList;
        }
        this.showLoader = false;
      });
    }
  }
  updateEventSchool(school: any) {
    console.log("seatcher changed", school);
    var arr = this.schoolList;
    this.subjectList = [];
    this.contentStandard = "";
    this.schoolDetails = {};
    arr.forEach((ele) => {
      if (school.item_id == ele.id) {
        this.schoolDetails["id"] = ele.id;
        this.schoolDetails["name"] = ele.name;
        this.getSchoolDetails();
      }
    });
    this.currentSchool = [school];
    console.log("aaacurrentSchool", this.currentSchool);
    console.log("aaaa", this.schoolInfo);
  }

  onSubmit() {
    console.log(this.schoolInfo);
  }

  backClicked() {
    this.router.navigate(["/masterAdmin/dashboard"]);
  }

  openSchoolList() {
    this.router.navigate(["/schoolInfoList"]);
    return false;
  }

  replaceHost() {
    this.router.navigate(["/reschedule"]);
    return false;
  }

  meetingDetails() {
    this.router.navigate(["/meetingDetails"]);
    return false;
  }

  userList() {
    this.router.navigate(["/userList"]);
    return false;
  }

  userInfo() {
    this.router.navigate(["/userInfo"]);
    return false;
  }

  resetRow(index) {
    let currObj = this.schoolInfo.standardList[index]; // Imp. to refreshing
    currObj["contentStandard"] = "";
    currObj["version"] = "";
    currObj["variant"] = "";
  }

  showQrCode(qrValue) {
    console.log("qrValue", qrValue);
    this.licenseCode = qrValue;
    $("#pictureModel").modal("show");
  }

  done() {
    $("#pictureModel").modal("hide");
  }

  downloadQRCode() {
    const fileNameToDownload = "image_qrcode";
    const base64Img =
      document.getElementsByClassName("coolQRCode")[0].children[0]["src"];
    fetch(base64Img)
      .then((res) => res.blob())
      .then((blob) => {
        // IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileNameToDownload);
        } else {
          // Chrome
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = fileNameToDownload;
          link.click();
        }
      });
  }
}

export class school {
  schoolName: string;
  city: string;
  smSchoolId: string;
  standardList: Array<standardLesson>;
  constructor() {}
}

export class standardLesson {
  standard: string;
  contentStandard: string;
  licenseCount: Number;
  licenseAdded: Number;
  licenseCode: string;
  moreLicense: Number;
  subjectList = [];
  constructor() {}
}

export class subjectDetail {
  subject: string;
  category: string;
  series: string;
  board: string;
  session: string;
  constructor() {}
}
