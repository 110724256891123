import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AllApisService } from "../all-apis.service";
import { BehaviorSubject } from "rxjs";
import { Observable } from "rxjs/Rx";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class StudentService {
  baseurl = this.allApiServ.baseurl;
  timeInterval: any;
  apiInterval: any;
  ckSubject: any;
  constructor(
    private http: HttpClient,
    private allApiServ: AllApisService,
    private route: Router
  ) {}
  private messageSource = new BehaviorSubject("none");
  private cambridgeSource = new BehaviorSubject("NN");
  private chrysalisActiom = new BehaviorSubject("NA");
  currentMessage = this.messageSource.asObservable();
  currentCambridge = this.cambridgeSource.asObservable();
  showChrysalisOption = this.chrysalisActiom.asObservable();

  getCK() {
    return this.ckSubject;
  }
  setCK(sub) {
    this.ckSubject = sub;
  }

  getSchoolInfo(schoolId) {
    let params = new HttpParams();
    params = params.append("schoolId", schoolId);
    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/school/fetchSchool",
      this.allApiServ.httpOptions
    );
  }
  getStandards() {
    let params = new HttpParams();
    let smSchoolId = JSON.parse(localStorage.getItem("userDetail"))[
      "smSchoolId"
    ];
    console.log("all the details", smSchoolId);
    params = params.append("role", "Principal");
    // params = params.append("userId", sourceId);
    params = params.append("smSchoolId", smSchoolId);
    return this.http.get<any>(
      this.baseurl + "/school/standards",
      this.allApiServ.httpOptions
    );
  }

  getSubjectList(obj = null) {
    if (obj) {
      let params = new HttpParams();
      params = params.append("userId", obj.userId);
      params = params.append("role", obj.role);
      this.allApiServ.httpOptions.params = params;
    }else{
      let params = new HttpParams();
    this.allApiServ.httpOptions.params = params;
    }
    return this.http.get<any>(
      this.baseurl + "/lesson/getSubject",
      this.allApiServ.httpOptions
    );
  }

  getLesson(obj: any) {
    let params = new HttpParams();
    this.allApiServ.httpOptions.params = params;
    return this.http.post<any>(
      this.baseurl + "/lesson/lessonList",
      obj,
      this.allApiServ.httpOptions
    );
  }

  getCurrentLesson(obj: any) {
    let params = new HttpParams();
    this.allApiServ.httpOptions.params = params;
    return this.http.post<any>(
      this.baseurl + "/plan/currentLesson",
      obj,
      this.allApiServ.httpOptions
    );
  }
  getVideos(std: any, section: any, studentId: any, contentStd: any) {
    let params = new HttpParams();
    params = params.append("standard", std);
    params = params.append("section", section);
    params = params.append("studentId", studentId);
    params = params.append("contentStd", contentStd);
    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/plan/videos",
      this.allApiServ.httpOptions
    );
  }

  getPendingContents(std: any, section: any, studentId: any, contentStd: any) {
    let params = new HttpParams();
    params = params.append("standard", std);
    params = params.append("section", section);
    params = params.append("studentId", studentId);
    params = params.append("contentStd", contentStd);
    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/plan/pendingContent",
      this.allApiServ.httpOptions
    );
  }

  getAllContents(std: any, section: any, studentId: any, contentStd: any) {
    let params = new HttpParams();
    params = params.append("standard", std);
    params = params.append("section", section);
    params = params.append("studentId", studentId);
    params = params.append("contentStd", contentStd);
    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/plan/allContent",
      this.allApiServ.httpOptions
    );
  }

  getAllActivities(
    std: any,
    section: any,
    lessonId: any,
    contentId: any,
    studentId: any
  ) {
    let params = new HttpParams();
    params = params.append("standard", std);
    params = params.append("section", section);
    params = params.append("lessonId", lessonId);
    params = params.append("contentId", contentId);
    params = params.append("studentId", studentId);
    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/plan/pendingActivities",
      this.allApiServ.httpOptions
    );
  }

  getPerformance(
    studentId: string,
    lessonId: string,
    subject: any,
    standard: string,
    section: string
  ) {
    let params = new HttpParams();
    params = params.append("studentId", studentId);
    params = params.append("lessonId", lessonId);
    params = params.append("subject", subject);
    params = params.append("standard", standard);
    params = params.append("section", section);
    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/response/performance",
      this.allApiServ.httpOptions
    );
  }

  uploadResponse(serverUrl, formData) {
    let params = new HttpParams();
    this.allApiServ.httpOptions.params = params;
    console.log("httpRequestOptions", formData);
    return this.http.post<any>(
      serverUrl,
      formData,
      this.allApiServ.httpOptions
    );
  }

  getTeacherMsg(
    studentId: string,
    subject: any,
    standard: string,
    section: string
  ) {
    let params = new HttpParams();
    params = params.append("studentId", studentId);
    params = params.append("subject", subject);
    params = params.append("standard", standard);
    params = params.append("section", section);
    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/response/teacherMsg",
      this.allApiServ.httpOptions
    );
  }

  getLessonUrl(
    standard: string,
    subject: string,
    version: string,
    variant: string,
    studios: string
  ) {
    let params = new HttpParams();
    params = params.append("standard", standard);
    params = params.append("subject", subject);
    params = params.append("version", version);
    params = params.append("variant", variant);
    params = params.append("studios", studios);
    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/lesson/urlList",
      this.allApiServ.httpOptions
    );
  }

  getBookContent(reqObj) {
    let params = new HttpParams();
    this.allApiServ.httpOptions.params = params;
    return this.http.post<any>(
      this.baseurl + "/books/get",
      reqObj,
      this.allApiServ.httpOptions
    );
  }

  getRoboToken(authObj) {
    let params = new HttpParams();
    this.allApiServ.httpOptions.params = params;
    return this.http.post<any>(
      this.baseurl + "/roboGarden/getToken",
      authObj,
      this.allApiServ.httpOptions
    );
  }

  getMathsBuddy(authObj) {
    let params = new HttpParams();
    this.allApiServ.httpOptions.params = params;
    return this.http.post<any>(
      this.baseurl + "/roboGarden/getMathsBuddy",
      authObj,
      this.allApiServ.httpOptions
    );
  }

  getPekatales(obj){
    return this.http.post<any>(
      this.baseurl + "/roboGarden/getPekatales",
      obj,
      this.allApiServ.httpOptions
    ); 
  }

  getCKData() {
    let params = new HttpParams();
    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/books/getCK",
      this.allApiServ.httpOptions
    );
  }

  getCurriculumId(series, source): Observable<any> {
    let params = new HttpParams();
    this.allApiServ.httpOptions.params = params;
    return this.http.post<any>(
      this.baseurl + "/books/getCurriculumId",
      { series: series, source: source },
      this.allApiServ.httpOptions
    );
  }

  getBookProgress(token, selectedExam) {
    let params = new HttpParams();
    params = params.append("token", token);
    params = params.append("examType", selectedExam);
    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/books/getBookProgress",
      this.allApiServ.httpOptions
    );
  }

  getAllExamName(token) {
    let params = new HttpParams();
    params = params.append("token", token);
    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/books/getAllExam",
      this.allApiServ.httpOptions
    );
  }

  getCKSubject(subject, grade) {
    let token = localStorage.getItem("cktoken");
    let mobileCk = localStorage.getItem("mobileCk");
    // if(localStorage.getItem("role") == "Teacher") {
    //   token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyYmIxNDY2MDM5IiwiZXhwIjoxNjg0Nzc1MDEzLCJpYXQiOjE2NzY5OTkwMTMsImFwcCI6IkxpdmVBcHAiLCJwZXJzb25JZCI6IjIwMDUyMzciLCJwZXJzb25Sb2xlVHlwZSI6IlRFQUNIRVIiLCJzY2hvb2xJZCI6IjM0MjAiLCJzY2hvb2xBY2FkZW1pY1llYXJJZCI6Ijg3MzQiLCJzY2hvb2xDb2RlIjoiQ0syMDA2IiwiYXBwbGljYXRpb24iOiJMaXZlIEFwcCJ9.6d2xqELrQQ-Vro-rtUK1l2d6KkkF_b1pD4luZfSc1-6RnZ-8HiyFn9Jp_3uKmswx50KP9lLsArZr6Obwn6obWw"
    // }
    console.log("subject", subject);
    let params = new HttpParams();
    params = params.append("subject", subject);
    params = params.append("grade", grade);
    params = params.append("token", token);
    if (
      localStorage.getItem("role") &&
      localStorage.getItem("role") == "Teacher"
    ) {
      params = params.append("teacher", "true");
    } else if (
      localStorage.getItem("role") &&
      localStorage.getItem("role") == "Principal"
    ) {
      params = params.append("principal", "true");
    }
    if (mobileCk && mobileCk != "") {
      params = params.append("mobile", "true");
    }else{
      params = params.append("mobile", "false");
    }
    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/books/getCKSubject",
      this.allApiServ.httpOptions
    );
  }

  getCKSubjects(token) {
    let params = new HttpParams();
    if (localStorage.getItem("role") == "Teacher") {
      let sourceId = JSON.parse(localStorage.getItem("userDetail"))["sourceId"];
      params = params.append("userId", sourceId);
      params = params.append("role", "Teacher");
      this.allApiServ.httpOptions.params = params;
      return this.http.get<any>(
        this.baseurl + "/lesson/getSubject",
        this.allApiServ.httpOptions
      );
    } else if (localStorage.getItem("role") == "Principal") {
      let smSchoolId = JSON.parse(localStorage.getItem("userDetail"))[
        "smSchoolId"
      ];
      let std = localStorage.getItem("ckstd");
      // let sourceId = JSON.parse(localStorage.getItem("userDetail"))["sourceId"];
      console.log("all the details", smSchoolId, std);
      params = params.append("role", "Principal");
      params = params.append("standard", std);
      // params = params.append("userId", sourceId);
      params = params.append("schoolId", smSchoolId);
      this.allApiServ.httpOptions.params = params;
      return this.http.get<any>(
        this.baseurl + "/lesson/getSubject",
        this.allApiServ.httpOptions
      );
    } else {
      let sourceId = JSON.parse(localStorage.getItem("studentDetail"))[
        "sourceId"
      ];
      params = params.append("userId", sourceId);
      params = params.append("role", "Student");
      this.allApiServ.httpOptions.params = params;
      return this.http.get<any>(
        this.baseurl + "/lesson/getSubject",
        this.allApiServ.httpOptions
      );
    }
  }

  makeUserActive() {
    let studentDetail = localStorage.getItem("studentDetail");
    let student = {};
    if (studentDetail && studentDetail !== "") {
      student = JSON.parse(studentDetail);
    }
    let activeTime = localStorage.getItem("activeTime");
    let actTime = 0;
    if (activeTime) actTime = parseInt(activeTime);
    let obj = { userId: student["sourceId"], role: "Student" };
    obj["activeTime"] = actTime;
    return this.http.post<any>(
      this.baseurl + "/dump/updateLogin",
      obj,
      this.allApiServ.httpOptions
    );
  }

  updateLastActive(obj: any) {
    let params = new HttpParams();
    this.allApiServ.httpOptions.params = params;
    return this.http.patch<any>(
      this.baseurl + "/dump/updateLastActive",
      obj,
      this.allApiServ.httpOptions
    );
  }

  runActiveApi(activeTime?: number) {
    let studentDetail = localStorage.getItem("studentDetail");
    let student = {};
    if (studentDetail && studentDetail !== "") {
      student = JSON.parse(studentDetail);
    }
    this.updateLastActive({
      userId: student["sourceId"],
      activeTime: activeTime,
      role: "Student",
    }).subscribe((res) => {
      if (res["status"]) localStorage.setItem("activeTime", "0");
    });
  }

  updateActiveness() {
    this.timeInterval = setInterval(() => {
      let activeTime = localStorage.getItem("activeTime");
      let actTime = 0;
      if (activeTime) {
        actTime = parseInt(activeTime);
      }
      localStorage.setItem("activeTime", (actTime + 10).toString());

      console.log("updated", actTime);
    }, 10000);
  }

  deleteInterval() {
    clearInterval(this.timeInterval);
    // clearInterval(this.apiInterval)
  }

  updateActiveTime() {
    // this.apiInterval = setInterval(()=>{
    //   let activeTime = localStorage.getItem('activeTime')
    //   let actTime = 0
    //   if(activeTime){
    //     actTime = parseInt(activeTime)
    //   }
    //    this.runActiveApi(actTime)
    //    console.log('updatedOnServer', actTime)
    // }, 120000)
  }

  fetchToken() {
    let studentList = localStorage.getItem("studentDetails");
    let studentIdList = [];
    if (studentList && studentList !== "") {
      studentIdList = JSON.parse(studentList).map((elem) =>
        elem.sourceId.toString()
      );
    }
    let studentDetail = localStorage.getItem("studentDetail");
    let student = {};
    if (studentDetail && studentDetail !== "") {
      student = JSON.parse(studentDetail);
    }
    let userDetail = localStorage.getItem("userDetail");
    let user = {};
    if (userDetail && userDetail !== "") {
      user = JSON.parse(userDetail);
    }
    let obj = {
      userId: student["sourceId"],
      schoolId: student["smSchoolId"],
      role: student["roles"][0],
      parentId: user["sourceId"],
      studentDetail: student,
      studentIds: studentIdList,
    };
    return this.http.post<any>(
      this.allApiServ.baseurl + "/updateToken",
      obj,
      this.allApiServ.httpOptions
    );
  }

  updateToken() {
    this.fetchToken().subscribe((res) => {
      console.log(res);
      if (res["status"]) {
        localStorage.setItem("token", res["token"]);
      }
      this.changeMessage(res["allowStudio"]);
      this.changeCambrideMenu(res["cambridgeAuthToken"]);
      localStorage.setItem("allowStudio", res["allowStudio"]);
      localStorage.setItem("cambridgeAuthToken", res["cambridgeAuthToken"]);
      if (!res["studentActivityList"].length) this.allApiServ.logoutUser();
      else {
        let studentList = localStorage.getItem("studentDetails");
        let studentIdList = [];
        if (studentList && studentList !== "") {
          studentIdList = JSON.parse(studentList);
          studentIdList.forEach((elem) => {
            if (res["studentActivityList"].includes(elem["sourceId"])) {
              elem["active"] = true;
            } else elem["active"] = false;
          });
        }
        localStorage.setItem("studentDetails", JSON.stringify(studentIdList));
        let studentDetail = localStorage.getItem("studentDetail");
        if (studentDetail && studentDetail !== "") {
          let student = JSON.parse(studentDetail);
          if (!res["studentActivityList"].includes(student["sourceId"]))
            this.route.navigate(["/student/select-student"]);
        }
        console.log("came here", studentIdList);
      }
    });
  }

  //CONFIGURE STUDIO-COMPANION
  updateStudioAccess(obj: any) {
    return this.http.post<any>(
      this.allApiServ.baseurl + "/student/updateStudioAccess",
      obj,
      this.allApiServ.httpOptions
    );
  }

  //FETCH STUDENTS FOR STUDIO-COMPANION CONFIGURATION
  fetchStudents(obj: any) {
    return this.http.post<any>(
      this.allApiServ.baseurl + "/student/studentList",
      obj,
      this.allApiServ.httpOptions
    );
  }
  //CHANGE BEHAVIOUR
  changeMessage(message: boolean) {
    if (message) this.messageSource.next("true");
    else this.messageSource.next("false");
  }

  changeCambrideMenu(cambridgeAuthToken: any) {
    console.log("token", cambridgeAuthToken);
    this.cambridgeSource.next(cambridgeAuthToken);
  }

  hideChrysalis(hide: any) {
    this.chrysalisActiom.next(hide);
  }

  //FETCH CAMBRIDGE ENABLED STUDENTS
  fetchCambridgeStudents(obj: any) {
    return this.http.post<any>(
      this.allApiServ.baseurl + "/cambridge/getStudentList",
      obj,
      this.allApiServ.httpOptions
    );
  }

  //FETCH CAMBRIDGE URL
  fetchCambridgeUrl(studentId: any) {
    let params = new HttpParams();
    params = params.append("studentId", studentId);
    this.allApiServ.httpOptions.params = params;
    return this.http.get<any>(
      this.allApiServ.baseurl + "/cambridge/getUrl",
      this.allApiServ.httpOptions
    );
  }
}
