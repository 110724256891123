import { Component, OnInit } from '@angular/core';
import { UserInfoService } from '../user-info/user-info.service';
import { NotifierService } from 'angular-notifier'
import { Router } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  userList=[];
  constructor(public notifier: NotifierService, private serv:UserInfoService,  private router: Router) { }

  ngOnInit() {
    this.getUserList();
  }
  getUserList(){
    console.log('will fetch list')
    this.serv.getUserList()
    .subscribe(res=>{
      console.log(' list fetched')
      console.log(res);
      if(res.status){
        this.userList=res["result"];
       this.populateTable()
      }
    })
  }

  moveToSchoolInfo() {
    this.router.navigate(['/schoolInfo']);
    return false;
  }

  populateTable() {
    let action_btn =  '<i  class="fas fa-edit text-info edit" title="Edit Details"></i><i style="padding-left:10px;" class="far fa-trash-alt text-danger text-info delete" title="Delete"></i>'
    let self=this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.userList,
        columns: [
          
          { data: 'emailId', defaultContent: '<i>Not Set</i>' },
          { data: 'clientId', defaultContent: '<i>Not Set</i>' },
          { data: 'clientSecret', defaultContent: '<i>Not Set</i>' },
          { data: 'basicToken', defaultContent: '<i>Not Set</i>' },
          { data: 'userList', defaultContent: '<i>Not Set</i>' },
  
        ],
        // dom: 'Bfrtip',
        //  buttons: [],

        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']]
      });
      
     
  }

}
