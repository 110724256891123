import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { AllApisService } from '../../all-apis.service';
import {Location} from '@angular/common';

@Component({
  selector: 'master-admin-dashboard',
  templateUrl: './master-admin.component.html',
  styleUrls: ['./master-admin.component.css']
})
export class MasterAdminComponent implements OnInit {

  schoolName:any = '';

  constructor(private activeRoute: ActivatedRoute,private router: Router, private allApiService:AllApisService, private _location: Location) { }

  ngOnInit() {
    
    if(typeof localStorage.getItem('schoolInfo')!==undefined && localStorage.getItem('schoolInfo')!==""){
      //this.schoolName = this.allApiService.getSchoolInfo().schoolName;
    }
 
    this.allApiService.ensureLogin("error", "Please login to access content.");

  }

  navigateToAddCourse() {
    this.router.navigate(['/courses/addCourse']);
  }

  navigateToAddProduct(){
    this.router.navigate(['/courses/addProduct']);
  }

  navigateToLesson() {
    this.router.navigate(['/dashboard/lesson']);
  }

  navigateToImportLesson(){
    
    this.router.navigate(['/importExl/lesson']);
  }

  navigateToImportContent(){
    
    this.router.navigate(['/importExl/lesson-content']);
  }

  navigateToImportActivity(){
    
    this.router.navigate(['/importExl/lesson-activity']);
  }

  navigateToImportpdVideos(){
    this.router.navigate(['/importExl/pdVideos']);
  }

  navigateToSchoolMap() {
    this.router.navigate(['/schoolMap']);
  }

  navigateToSchoolLicence(){
    this.router.navigate(['/schoolInfo']);
  }
  navigateToSchoolAssignment() {
    this.router.navigate(['/schoolAssignedSubject']);
  }

  navigateTopdVideos(){
    this.router.navigate(['/dashboard/pdVideos']);
  }

  navigateToChrysalisDashboard(){

    this.router.navigate(['/reports/scheduledClasses']);
  }
  logOut() {
     
    this.allApiService.logoutUser();
  }
  
  finishTask() {
    var resObj = {}
    resObj['status'] = true
    resObj['message'] = "Exit"
    resObj['result'] = {}
    console.log('json objec', JSON.stringify(resObj));
    if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.exitViewCallBack.postMessage(JSON.stringify(resObj));
    }
    if (window.Android) {
      window.Android.finishTask(JSON.stringify(resObj));
    }
  }
  backClicked() {
    this._location.back();
  }
  
}
