import { Component, OnInit, Input } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { StudentService } from 'src/app/student/student.service';
import {AllApisService} from '../../all-apis.service';

@Component({
  selector: 'app-navigation-panel',
  templateUrl: './navigation-panel.component.html',
  styleUrls: ['./navigation-panel.component.css']
})
export class NavigationPanelComponent implements OnInit {
  @Input() val:string;
  loginuserName: any;
  userDetail : any;
  allowStudio = true;
  allowCambridgeRegistartion = false;
  allowCambridge = false;
  std : any;
  showRegisteration = false;
  hideChrysalis = false;
  constructor(private allApiserv:AllApisService, private serv : StudentService, private notifier : NotifierService) { }

  ngOnInit() {
    console.log('came in navigation panel')
    let user = localStorage.getItem('allowStudio');
    if(user && user!== ''){
      console.log(user, typeof user)
      if(user == 'false' )this.allowStudio = false
      
    }
    let cambridgeAuthToken = localStorage.getItem('cambridgeAuthToken');
    
    if(cambridgeAuthToken && cambridgeAuthToken != 'NA'){
      console.log('cambridgeAuthToken', cambridgeAuthToken)
      this.allowCambridge = true
      
    }                   

    let student = localStorage.getItem('studentDetail');
    if(student && student !== ''){
      let studInfo = JSON.parse(student);
      this.std = studInfo['student.std'];
      
    }
    let school = localStorage.getItem('schoolInfo');
    if(school && school !== ''){
      let schoolInfo = JSON.parse(school);
      schoolInfo['standardList'].forEach( elem => {
        if(elem['standard'] == this.std && elem['enableCambridge']){
          this.allowCambridgeRegistartion = true;
          this.showRegisteration = true;
        }
        if(elem['standard'] == this.std && (typeof elem['contentStandard'] == 'undefined' || elem['contentStandard'] == "")){
          this.hideChrysalis = true;
          
        }
      })
    }
    
    this.serv.currentMessage.subscribe(
      message => {
        // console.log('message', message);
        if(message == 'true')this.allowStudio = true;
        else if(message == 'false')this.allowStudio = false; 
      }
    )

      // console.log(this.allowCambridge, this.allowCambridgeRegistartion)
    this.serv.currentCambridge.subscribe(
      message => {
        
        if(message && message!== '' && message!== 'undefined' &&  message !== 'NA' &&  message !== 'NN'){
          this.allowCambridge = true;
          
        }
        else if( message == 'NA' ) this.allowCambridge = false;
        console.log('allowcambridge', this.allowCambridge)
      }
    )
    //hide chrysalis option
    this.serv.showChrysalisOption.subscribe( res => {
      let student = localStorage.getItem('studentDetail');
      if(student && student !== ''){
        let studInfo = JSON.parse(student);
        this.std = studInfo['student.std'];
        
      }
      let school = localStorage.getItem('schoolInfo');
      if(school && school !== ''){
        let schoolInfo = JSON.parse(school);
        schoolInfo['standardList'].forEach( elem => {
          
          if(elem['standard'] == this.std && (typeof elem['contentStandard'] == 'undefined' || elem['contentStandard'] == "")){
            this.hideChrysalis = true;
            
          }
          if(elem['standard'] == this.std && elem['enableCambridge']){
            this.allowCambridgeRegistartion = true;
            this.showRegisteration = true;
          }
          
        })
      }
      
    })
    this.loginuserName = this.allApiserv.logedInUserName()
    console.log('value',this.val);
   $('.activeIcon1').css('display','none');
   $('.activeIcon2').css('display','none');
   $('.activeIcon3').css('display','none');
   $('.activeIcon4').css('display','none');
   $('.activeIcon5').css('display','none');
   $('.activeIcon6').css('display','none');
   $('.activeIcon7').css('display','none');
   $('.activeIcon8').css('display','none');
    if(this.val=="select-ward"){
      $('.br-sideleft-menu a:nth-child(1)').addClass('active');  
      $('.deactiveIcon1').css('display','none');
      $('.activeIcon1').css('display','block');
    }
    if(this.val=="dashboard"){
      $('.br-sideleft-menu a:nth-child(2)').addClass('active');
      $('.deactiveIcon2').css('display','none');
      $('.activeIcon2').css('display','block');
      
    }
    if(this.val=="liveClasses"){
      $('.br-sideleft-menu a:nth-child(3)').addClass('active');
      $('.deactiveIcon3').css('display','none');
      $('.activeIcon3').css('display','block');

    }
    if(this.val=="studios"){
      $('.br-sideleft-menu a:nth-child(4)').addClass('active');
      $('.deactiveIcon4').css('display','none');
      $('.activeIcon4').css('display','block');
    }
    if(this.val=="companion"){
      $('.br-sideleft-menu a:nth-child(5)').addClass('active');
      $('.deactiveIcon5').css('display','none');
      $('.activeIcon5').css('display','block');
    }
    if(this.val=="forParents"){
      $('.br-sideleft-menu a:nth-child(6)').addClass('active');
      $('.deactiveIcon6').css('display','none');
      $('.activeIcon6').css('display','block');
    }
    if(this.val=="cambridgeRegistration"){
      $('.br-sideleft-menu a:nth-child(7)').addClass('active');
      $('.deactiveIcon7').css('display','none');
      $('.activeIcon7').css('display','block');
    }
    if(this.val=="cambridge"){
      $('.br-sideleft-menu a:nth-child(8)').addClass('active');
      $('.deactiveIcon8').css('display','none');
      $('.activeIcon8').css('display','block');
    }
  
    $('#btnLeftMenuMobile').on('click', function () {
      $('body').addClass('show-left');
      return false;
    });
    $(document).on('click', function (e) {
      e.stopPropagation();
      // closing left sidebar
      if ($('body').hasClass('show-left')) {
        $('body').removeClass('show-left');
       /*  var targ = $(e.target).closest('.br-sideleft').length;
        if (!targ) {
          
        } */
      }
    });
  }


  logOut(){
    console.log('logout');
    this.allApiserv.logoutUser();
  }

  displayMessage(){
     if(!this.allowStudio)this.notifier.notify('error', 'Access Restricted')
  }

  navigateToCambridgeDashboard(){
    let student = localStorage.getItem('studentDetail');
    let studentId  = '';
    if(student && student !== ''){
      let stud = JSON.parse(student);
      studentId = stud['sourceId'];
    }
    this.serv.fetchCambridgeUrl(studentId)
    .subscribe( res => {
      window.location.href = res.url;
    })
  }
}
