<div *ngIf="fromDashboard">
  <section class="content-header">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard">
          <i class="fas fa-home"></i> Dashboard</a>
      </li>
      <li class="breadcrumb-item active">Fee-Payment</li>
    </ol>
  </section>
</div>
<div class="br-pagebody" style="background-color:white;margin-top: 0;" *ngIf="!showImgLoader">
  <div class="row row-sm" style="margin:0;">
    <div class="col-md-12" style="box-shadow: 0 3px #f2f2f2;">
      <div class="row" style="margin-top: 0;">
        <div class="col-md-12 gradBack">
          <span style="float: left;padding-left: 12px;"><i class="fas fa-arrow-left left-arr-icon" (click)="finishPayment()"></i></span><h4 style="font-size: 16px;">Payment</h4>
        </div>
        
      </div>
    </div>
    
    
  </div>
</div>

<div class="modal fade" id="successModal" tabindex="-1" role="dialog" aria-labelledby="successModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
     <div class="signContainer" style="margin:0 auto;margin-top: -50px;">
       <div><i class="fas fa-check correctSign"></i></div>
     </div>
      <div class="modal-body" style="text-align: center;">
       <h3 style="font-size: 20px;">Payment Successful</h3>
       <p>Your Payment has been done successfully.</p>
       <button type="button" class="btn successBtn" data-dismiss="modal" (click)="paymentSuccess()">Ok</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="errorModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
     <div class="signContainer" style="margin:0 auto;margin-top: -50px;">
       <div><i class="fas fa-times errorSign"></i></div>
     </div>
      <div class="modal-body" style="text-align: center;">
       <h3 style="font-size: 20px;">Oh no!</h3>
       <p style="font-size: 16PX;">Something went wrong <br> Please try again.</p>
       <button type="button" class="btn errorBtn" data-dismiss="modal" (click)="paymentFailed()">TRY AGAIN</button>
      </div>
    </div>
  </div>
</div>

<page-loader *ngIf="showImgLoader" imgSrc="assets/img/PaymentPromotscreen.png"></page-loader>
<page-loader *ngIf="showLoader && !showImgLoader" [loaderMsg]="loaderMessage"></page-loader>