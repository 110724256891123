<div>
    <section class="content-header">
      School Info List
      <button type="button" class="btn btn-primary m-2" (click)="moveToSchoolInfo()"><i class="fa fa-list"
        aria-hidden="true"> School Info</i></button>
    </section>
    <br>
  </div>
  <div class="br-pagebody" style="background-color:white;" >
  

    <div class="col-md-12 showhideTable">
      <table class="display example" cellspacing="0" width="100%">
          <thead>
            <tr>
              <th>School Id</th>
              <th>School Name</th>
              <th>License</th>
              <th>License Date</th>
              <th>Zoom Paid</th>
              <!-- <th>Zoom Free</th> -->
              <th>Webinar Paid</th>
              <th>Organizer Key</th>
            </tr>
          </thead>
        </table>
  </div> 
  </div>
  
  
  