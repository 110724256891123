import { Component, OnInit } from '@angular/core';
import { SchoolServiceService } from './school-service.service';
import { NotifierService } from 'angular-notifier'
import { Router } from '@angular/router';

@Component({
  selector: 'app-school-info',
  templateUrl: './school-info.component.html',
  styleUrls: ['./school-info.component.css']
})
export class SchoolInfoComponent implements OnInit {
  zoomUserList=[];
  gtmUserList=[];
  schoolInfo: school
  schoolDetails: object;
  slotList = [{ name: "fullDay", count: 0 }, { name: "8-12", count: 0 }, { name: "12-4", count: 0 }, { name: "4-7", count: 0 }];
  schoolList = [];
  schoolDisplayList=[];
  dropdownSettings  = {
    singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      allowSearchFilter: true
  };
  teamInfo:any;
  constructor(private router: Router, private serv: SchoolServiceService, public notifier: NotifierService) { }

  ngOnInit() {
    this.schoolInfo = new school();
    this.getSchoolList();
    this.schoolInfo.slotList=this.slotList;
    this.teamInfo={};
  }

  getSchoolList() {
    this.serv.getSchoolList()
      .subscribe(res => {
        if(res) {
          this.schoolList = res.schoolList;
          this.schoolDisplayList=[];
          this.schoolList.forEach(ele => {
            this.schoolDisplayList.push({ item_id: ele.id, item_text: ele.name + ' (' + ele.id + ')'});
          })
        
        }
      }),
      err => {
        console.log('unable to fect schools')
      }
  }

  saveInfo() {
    this.onSubmit();
    this.schoolInfo.schoolName = this.schoolDetails["name"];
    this.schoolInfo.smSchoolId = this.schoolDetails["id"];
    this.schoolInfo.city = this.schoolDetails["city"];
    console.log('came here',this.schoolInfo);
    // console.log(this.schoolDetails);
    this.serv.addSchoolDetail(this.schoolInfo)
      .subscribe(res => {
        console.log(res)
        if(res.status) {
          this.notifier.notify("success", "Information saved successfully");
        } else {
          this.notifier.notify("error", res.message);
        }
        //this.updateAccountStatus();
      },
        err => {
          this.notifier.notify("error", "Something bad happend");
        }
      )
  }
  getSchoolDetails(){
    this.schoolInfo=new school();
        this.slotList.forEach(elem=>{
          elem["count"]=0;
        })
        this.schoolInfo.slotList=this.slotList;
        this.zoomUserList = [];
        console.log('this.zoomUserList', this.zoomUserList)
    if(this.schoolDetails!==null && typeof this.schoolDetails!=='undefined' && this.schoolDetails["id"]!==null && typeof this.schoolDetails["id"]!=='undefined'){
      this.serv.getSchoolInfo(this.schoolDetails["id"])
      .subscribe(res=>{
        console.log(res);
        if(res["status"]) {
          this.schoolInfo=res["result"];
          if(typeof this.schoolInfo.impartus ==='undefined' || !this.schoolInfo.impartus){
            this.schoolInfo.impartus=false;
          }
          if(typeof this.schoolInfo.team ==='undefined' || !this.schoolInfo.team){
            this.schoolInfo.team=false;
          }
          if(typeof this.schoolInfo.zoomPaid ==='undefined' || !this.schoolInfo.zoomPaid){
            this.schoolInfo.zoomPaid=false;
          }
          if(typeof this.schoolInfo.gotowebinarPaid ==='undefined' || !this.schoolInfo.gotowebinarPaid){
            this.schoolInfo.gotowebinarPaid=false;
          }
          if(typeof this.schoolInfo.gotomeeting ==='undefined' || !this.schoolInfo.gotomeeting){
            this.schoolInfo.gotomeeting=false;
          }
          
          if(!this.schoolInfo.teamInfo) {
            this.teamInfo={};
            this.teamInfo['tenantId'] = '';
            this.teamInfo['clientId'] = '';
            this.teamInfo['clientSecret'] = '';
            this.schoolInfo.teamInfo = this.teamInfo;
          }
          if(this.schoolInfo ===null || typeof this.schoolInfo.slotList === 'undefined'){
            this.schoolInfo.slotList=this.slotList;
          }
          if(res["result"]["zoomLicenseDetail"] && res["result"]["zoomLicenseDetail"].length) {
            res["result"]["zoomLicenseDetail"].forEach(ele => {
              if(!this.zoomUserList.length || !this.zoomUserList.includes(ele.email)){
                let obj = new zoomInfo(ele.email,ele.licenceType, ele.licenseDate ,ele.maxAttendee)
                if(ele.purchaseDate) obj['purchaseDate'] = new Date(ele.purchaseDate).toISOString().substr(0,10)
                if(ele.licenseDate) obj['licenseDateStr'] = new Date(ele.licenseDate).toISOString().substr(0,10)
                console.log(ele.purchaseDate , typeof ele.purchaseDate)
                this.zoomUserList.push(obj)
              }
            })
          } else if(res["result"]["emailList"] && res["result"]["emailList"].length) {
            res["result"]["emailList"].forEach(ele => {
              if(!this.zoomUserList.length || !this.zoomUserList.includes(ele)){
                this.zoomUserList.push(new zoomInfo(ele))
              }
            })
          } else {
            this.zoomUserList.push(new zoomInfo(res["result"]['emailId']))
          }
          if(!this.zoomUserList.length)this.zoomUserList.push(new zoomInfo())
          console.log('this.zoomUserList', this.zoomUserList)
        }
      })
    }
  }
  updateEventSchool(school:any) {
    console.log('seatcher changed', school);
    var arr = this.schoolList;
    this.schoolDetails={};
    arr.forEach(ele => {
      if(school.item_id == ele.id){
        this.schoolDetails["id"]= ele.id;
        this.schoolDetails["name"] = ele.name;
        this.getSchoolDetails();
      }
    })
    console.log("aaaa", this.schoolInfo);
  }

  updateZoomButton(){
    if(this.schoolInfo.zoomPaid){
      this.schoolInfo.gotowebinarPaid=false;
      this.schoolInfo.gotomeeting=false;
      this.schoolInfo.impartus=false;
      this.schoolInfo.team=false;
    }
  }
  updateGotowebButton(){
    if(this.schoolInfo.gotowebinarPaid){
      this.schoolInfo.zoomPaid=false;
      this.schoolInfo.gotomeeting=false;
      this.schoolInfo.impartus=false;
      this.schoolInfo.team=false;
    }
  }
  updateGtmButton(){
    if(this.schoolInfo.gotomeeting){
      this.schoolInfo.zoomPaid=false;
      this.schoolInfo.gotowebinarPaid=false;
      this.schoolInfo.impartus=false;
      this.schoolInfo.team=false;
    }
  }

  updateImpartusButton() {
    if(this.schoolInfo.impartus){
      this.schoolInfo.zoomPaid=false;
      this.schoolInfo.gotowebinarPaid=false;
      this.schoolInfo.gotomeeting=false;
      this.schoolInfo.team=false;
    }
  }

  updateTeamButton() {
    if(this.schoolInfo.team){
      this.schoolInfo.zoomPaid=false;
      this.schoolInfo.gotowebinarPaid=false;
      this.schoolInfo.gotomeeting=false;
      this.schoolInfo.impartus=false
    }
  }

  addNewRow(){
    if(!this.zoomUserList.length){
      this.zoomUserList.push(new zoomInfo());
      return;
    }
    let lastObj = this.zoomUserList[this.zoomUserList.length-1]
    console.log(lastObj);
    if(lastObj.count && lastObj.emailId){
      this.zoomUserList.push(new zoomInfo());
    }else{
      this.notifier.notify("error","Empty fields not allowed")
    }
    
    //console.log(this.standardSectionArr);
   // console.log(st);
  }

  addNewGtmRow(){
    if(!this.gtmUserList.length){
      this.gtmUserList.push(new gtmInfo());
      return;
    }
    let lastObj = this.gtmUserList[this.gtmUserList.length-1]
    console.log(lastObj);
    if(lastObj.emailId && lastObj.emailId!=='' && lastObj.licenseDate && lastObj.firstName!=='' && lastObj.lastName!==''){
      this.gtmUserList.push(new gtmInfo());
    }else{
      this.notifier.notify("error","Empty fields not allowed")
    }
    
    //console.log(this.standardSectionArr);
   // console.log(st);
  }

  removeRow(index){
    let newArr = this.zoomUserList; // Imp. to refreshing
    newArr.splice(index, 1);
   this.zoomUserList = newArr;
   }
   removeGtmRow(index){
    let newArr = this.gtmUserList; // Imp. to refreshing
    newArr.splice(index, 1);
   this.gtmUserList = newArr;
   }

onSubmit(){
  console.log('this.zoomUserList', this.zoomUserList);
  //this.schoolInfo.licenseDetail=[];
  if(!this.schoolInfo.zoomLicenseDetail) {
    this.schoolInfo.zoomLicenseDetail = [];
  }
 
  var emailIds = this.zoomUserList.map(z=> z.emailId)
  this.schoolInfo.zoomLicenseDetail = this.schoolInfo.zoomLicenseDetail.filter(rz=> emailIds.indexOf(rz.email) != -1)
  this.zoomUserList.forEach(elem=>{
    if(elem["emailId"] !== '') {
      if (!this.schoolInfo.zoomLicenseDetail || this.schoolInfo.zoomLicenseDetail.filter(item=> item.email == elem["emailId"]).length == 0){
        this.schoolInfo.zoomLicenseDetail.push({email: elem["emailId"], licenseDate: elem["licenseDateStr"], maxAttendee: elem["maxAttendee"],purchaseDate:elem["purchaseDate"], licenceType : elem["licenceType"]});
      } else if (!this.schoolInfo.zoomLicenseDetail || this.schoolInfo.zoomLicenseDetail.filter(item=> item.email == elem["emailId"]).length != 0) {
        if(elem["licenseDateStr"]) {
          this.schoolInfo.zoomLicenseDetail.filter(item=> item.email == elem["emailId"])[0].licenseDate = elem["licenseDateStr"];
        }
        if(elem["maxAttendee"]) {
          this.schoolInfo.zoomLicenseDetail.filter(item=> item.email == elem["emailId"])[0].maxAttendee = elem["maxAttendee"];
        }
        if(elem["purchaseDate"]) {
          this.schoolInfo.zoomLicenseDetail.filter(item=> item.email == elem["emailId"])[0].purchaseDate = elem["purchaseDate"];
        }
        if(elem["licenceType"]) {
          this.schoolInfo.zoomLicenseDetail.filter(item=> item.email == elem["emailId"])[0].licenceType = elem["licenceType"];
        }
      }
    }
  })
 this.schoolInfo.gtmList=this.gtmUserList
  console.log(this.schoolInfo);
}

openSchoolList() {
  this.router.navigate(['/schoolInfoList']);
  return false;
}

replaceHost() {
  this.router.navigate(['/reschedule']);
  return false;
}

meetingDetails(){
  this.router.navigate(['/meetingDetails']);
  return false;
}

userList() {
  this.router.navigate(['/userList']);
  return false;
}

userInfo() {
  this.router.navigate(['/userInfo']);
  return false;
}

updateAccountStatus(){
  this.serv.updateUserAccount({emailList:this.schoolInfo.emailList})
    .subscribe(res=>{
      console.log("its never going to happen");
    })
  
}

}

export class school {
  schoolName: string;
  city: string;
  smSchoolId: string;
  slotList: Array<object>;
  zoomPaid = false;
  team = false;
  gotowebinarPaid = false;
  gtwOrganizerKey: string;
  gotomeeting=false;
  impartus=false;
  emailId:string
  emailList:Array<string>
  zoomLicenseDetail:Array<any>
  gtmList:Array<any>;
  teamInfo:any;
  impartusLicenceCount:number;
  leftImpartusLicence:number
  constructor(){
   this.teamInfo={};
   this.teamInfo['tenantId'] = '';
   this.teamInfo['clientId'] = '';
   this.teamInfo['clientSecret'] = '';
   this.impartusLicenceCount=0;
   this.leftImpartusLicence=0;
  }
}

export class zoomInfo{
  count=1;
  emailId:string;
  licenseDate:Date;
  maxAttendee:number;
  licenceType : string;
  constructor(email:string = '', licenceType : string = '', licenseDate: Date = undefined , maxAttendee: number = undefined ) {
    this.emailId = email;
    if(licenseDate) {
      this.licenseDate = licenseDate;
    } else {
      this.licenseDate = new Date();
    }
    if(maxAttendee) {
      this.maxAttendee = maxAttendee;
    } else {
      this.maxAttendee = 100;
    }
    if(licenceType) {
      this.licenceType = licenceType;
    } else {
      this.licenceType = licenceType;
    }
  }
}

export class gtmInfo{
  
  emailId:string;
  licenseDate:Date;
  firstName:string;
  lastName:string
  constructor(email:string = '', licenseDate: Date = undefined, firstName:string = '', lastName:string = '' ) {
    this.emailId = email;
    if(licenseDate) {
      this.licenseDate = licenseDate;
    } else {
      this.licenseDate = new Date();
    }
    
  }
}