import { NgModule } from '@angular/core';
import { SmSignInComponent } from './sm-sign-in/sm-sign-in.component';
import { SharedModule } from '../shared/shared.module';
import { SignInComponent } from './sign-in/sign-in.component';



@NgModule({
  declarations: [
    SmSignInComponent,
    SignInComponent
  ],
  imports: [
    SharedModule
  ]
})
export class AuthenticationModule { }
