import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { ZoomService} from '../zoom/zoom.service';

declare var $: any;
@Component({
  selector: 'app-live-class-attendence',
  templateUrl: './live-class-attendence.component.html',
  styleUrls: ['./live-class-attendence.component.css']
})
export class LiveClassAttendenceComponent implements OnInit {
  seminarId : string;
  attendeeList =  [];
  seminarInfo :any;
  showLoader = false;
  constructor(private router: ActivatedRoute , private serv: ZoomService) { }

  ngOnInit() {
    if(this.router.queryParams){
      this.router.queryParams
      .subscribe(param=>{
        this.seminarId = param.seminarId;
        console.log(this.seminarId)
        this.getAttendence();
      })
    }
  }



  populateTable() {
    let action_btn =  '</i><i style="padding-left:10px;" class="far fa-trash-alt delete text-danger" title="Delete"></i>'
    let self=this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.attendeeList,
        columns: [
          
          { data: 'name', defaultContent: '<i>Not Set</i>' },
          { data: 'std', defaultContent: '<i>Not Set</i>' },
          { data: 'section', defaultContent: '<i>Not Set</i>' },
          { data: 'present', defaultContent: '<i>Not Set</i>' },
          { data: 'appearance', defaultContent: '<i>Not Set</i>' }

        ],
        dom: 'Bfrtip',
        //  buttons: [],

        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']]
      });
      
  }


  getAttendence(){
    this.showLoader = true;
    this.serv.getImpartusAttendence(this.seminarId)
    .subscribe(res=>{
      this.showLoader = false;
      console.log("res",res);
      if(res['status']){
        this.attendeeList=res['result']
        this.seminarInfo=res['seminarInfo']
      }
      this.populateTable();
    })

  }
}
