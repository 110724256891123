import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { AllApisService } from '../all-apis.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

  schoolName:any;

  constructor(private activeRoute: ActivatedRoute,private router: Router, private allApiService:AllApisService, private _location: Location) { }

  ngOnInit() {
    let schoolInfo = this.allApiService.getSchoolInfo();
    console.log('this.allApiService.getSchoolInfo()', schoolInfo);
    if(schoolInfo && schoolInfo !== ""){
      this.schoolName = schoolInfo.schoolName;
    }

  }

  navigateToAssessmentPerformance(){
    
    this.router.navigate(['/assessmentPerformance']);
  }

  navigateToWebinarSetting(){
    
    this.router.navigate(['/dashboard/admin']);
  }

  navigateToWebinarList(){
    
    this.router.navigate(['/webinarList']);
  }

  navigateToQuestion(){
    
    this.router.navigate(['/question']);
  }

  navigateToEditQuestion(){
    
    this.router.navigate(['/editAssessment']);
  }

  
  finishTask() {
    var resObj = {}
    resObj['status'] = true
    resObj['message'] = "Exit"
    resObj['result'] = {}
    console.log('json objec', JSON.stringify(resObj));
    if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.exitViewCallBack.postMessage(JSON.stringify(resObj));
    }
    if (window.Android) {
      window.Android.finishTask(JSON.stringify(resObj));
    }
  }
  backClicked() {
    this._location.back();
  }

  logOut(){
    this.allApiService.logoutUser();
  }

  navigateToPrincipalReports(){
    this.router.navigate(['/dailyContent']);
  }
}
