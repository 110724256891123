<div class="br-pagebody" style="background-color: white">
  <div class="row row-sm" style="margin-left: 0; margin-right: 0">
    <div class="form-group col-md-2">
      <label>Class</label>
      <select
        #stdName_var="ngModel"
        [(ngModel)]="searchObj.std"
        class="form-control form-control-sm"
        (change)="updateSubject()"
      >
        <option [value]="">Select Class</option>
        <option *ngFor="let std of stdList">{{ std }}</option>
      </select>
    </div>

    <div class="form-group col-md-2">
      <label>Subject</label>
      <select
        [(ngModel)]="searchObj.subject"
        class="form-control form-control-sm"
        (change)="updateSeriesList()"
      >
        <option [value]="">Select Subject</option>
        <option *ngFor="let sub of subjectList">{{ sub }}</option>
      </select>
    </div>
    <!-- 
      <div class="form-group col-md-2">
        <label>Category</label>
        <select [(ngModel)]='searchObj.category' class="form-control form-control-sm" (change)='updateSeriesList()'>
            <option [value]="">Select Category</option>
            <option *ngFor="let cat of categoryList">{{cat.category}}</option>
        </select>
      </div> -->

    <div class="form-group col-md-2">
      <label>Series</label>
      <select
        [(ngModel)]="searchObj.series"
        class="form-control form-control-sm"
      >
        <option [value]="">Select Series</option>
        <option *ngFor="let ser of seriesList">{{ ser }}</option>
      </select>
    </div>

    <div class="form-group col-md-2" style="padding-top: 23px">
      <button class="btn btn-warning commonBtn" (click)="getQuestionList()">
        Find
      </button>
    </div>

    <br />
    <div class="col-md-12 showhideTable1">
      <table class="display example1" cellspacing="0" width="100%">
        <thead>
          <tr>
            <th>Series</th>
            <th>Subject</th>
            <th>Class</th>
            <th>Topic</th>
            <th>Question Level</th>
            <th>Chapter</th>
            <th>Type</th>
            <th>Question</th>
            <th>Options</th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="errorModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="errorModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body" style="text-align: center">
        <div class="showhideTable2">
          <table class="table" cellspacing="0" width="100%">
            <thead>
              <tr>
                <th>Content Type</th>
                <th>Action</th>
                <th>URL</th>
              </tr>
              <tr *ngFor="let link of linkList; let i = index">
                <td>{{ link.title }}</td>
                <td>
                  <button
                    class="btn btn-primary btn-sm"
                    (click)="displayModel(i)"
                  >
                    <b>Open</b>
                  </button>
                </td>
                <td>
                  {{ link.url }}
                  <button class="btn btn-info btn-sm" (click)="editModel(i)">
                    <b>Edit</b>
                  </button>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="editModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="errorModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body" style="text-align: center">
        <form class="form-inline">
          <div class="form-group mb-2 col-md-12">
            <label>Current Value: {{ editLink }}</label>
          </div>
          <div class="form-group col-md-8 mb-2">
            <label for="inputPassword2" class="col-md-3">New Value</label>
            <input
              id="inputPassword2"
              name="linkName"
              class="form-control col-md-8"
              [(ngModel)]="newLink"
            />
          </div>
          <button class="btn btn-primary mb-2 col-md-2" (click)="updateLink()">
            Update
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="editModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="editModel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edit Lesson</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <h6>Title</h6>
          <input
            class="form-control form-control-sm"
            type="text"
            placeholder="Enter Title"
            name="editTitle"
            [(ngModel)]="editTitle"
          />
        </div>
        <div class="form-group">
          <h6>Sequence</h6>
          <input
            class="form-control form-control-sm"
            type="text"
            placeholder="Enter Sequence"
            name="editSequence"
            [(ngModel)]="editSequence"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="updateEditDetails()"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="PictureModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="PictureModel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Image</h5>
        <button
          type="button"
          class="close"
          (click)="done()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <iframe
          src="{{ questionCol?.imageUrl }}"
          title="Question Image"
        ></iframe> -->
        <img
          src="{{ questionCol?.imageUrl }}"
          alt="Question Image"
          class="img-fluid"
        />
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="PictureModelOption"
  tabindex="-1"
  role="dialog"
  aria-labelledby="PictureModelOption"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Image</h5>
        <button
          type="button"
          class="close"
          (click)="done1()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <iframe
        src="{{ questionCol?.imageUrl }}"
        title="Question Image"
      ></iframe> -->
        <img
          src="{{ questionCol?.optionText }}"
          alt="Question Image"
          class="img-fluid"
        />
      </div>
    </div>
  </div>
</div>
