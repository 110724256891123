<body class="">

  <div class="br-logo">
    <a href="">
      <img src="../../../assets/Navars-Edutech-logo.png">
    </a>
  </div>
  <div class="search-bar">
    <div class="search-icon">
      <i class="searchIcons ion-search"></i>
    </div>
    <input type="text" placeholder="What are you looking for...">
    <div class="close-search">
      <i class="searchIcons ion-ios-close"></i>
    </div>
  </div>
  <div class="br-sideleft overflow-y-auto">
  
    <div class="br-sideleft-menu ">
      <a href='#/student/select-student' class="br-menu-link">
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon1" src="../../../assets/img/user.png">
          <img class="activeIcon1" src="../../../assets/img/user_active.png">
          <span class="menu-item-label">Select Ward</span>
        </div>
      </a>
      <a href='#/student/dashboard/studentHomePage' class="br-menu-link" [hidden]='hideChrysalis'>
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon2" src="../../../assets/img/apps.png">
          <img class="activeIcon2" src="../../../assets/img/apps_active.png">
          <span class="menu-item-label">Dashboard</span>
        </div>
      </a>
      <a href='#/student/dashboard/studentLiveClasses' class="br-menu-link" [hidden]='hideChrysalis'>
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon3" src="../../../assets/img/video.png">
          <img class="activeIcon3" src="../../../assets/img/video_active.png">
          <span class="menu-item-label">Live classes</span>
        </div>
      </a>
      <a [attr.href]="allowStudio ? '#/student/dashboard/studiosDashboard' : null" class="br-menu-link" (click)="displayMessage()" [hidden]='hideChrysalis'>
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon4" src="../../../assets/img/book.png">
          <img class="activeIcon4" src="../../../assets/img/book_active.png">
          <span class="menu-item-label">E-Books</span>
        </div>
      </a>
      <!-- href='#/student/dashboard/studiosDashboard' -->
      <!-- <a  class="br-menu-link" [hidden]='allowStudio'>
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon7" src="../../../assets/img/book.png">
          <img class="activeIcon7" src="../../../assets/img/book_active.png">
          <span class="menu-item-label">Studios</span>
        </div>
      </a> -->
      <a [attr.href]="allowStudio ? '#/student/dashboard/companionDashboard' : null" class="br-menu-link" (click)="displayMessage()" [hidden]='hideChrysalis'>
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon5" src="../../../assets/img/book.png">
          <img class="activeIcon5" src="../../../assets/img/book_active.png">
          <span class="menu-item-label">DY-Activity</span>
        </div>
      </a>
      <!-- href='#/student/dashboard/companionDashboard' -->
      <!-- <a  class="br-menu-link" [hidden]='allowStudio'>
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon8" src="../../../assets/img/book.png">
          <img class="activeIcon8" src="../../../assets/img/book_active.png">
          <span class="menu-item-label">Companion</span>
        </div>
      </a> -->
      <a href='#/student/dashboard/parentHomepage' class="br-menu-link" [hidden]='hideChrysalis'>
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon6" src="../../../assets/img/Parents.png">
          <img class="activeIcon6" src="../../../assets/img/Parents_active.png">
          <span class="menu-item-label">For Parents</span>
        </div>
      </a>
      <a href='#/cambridgePayment' class="br-menu-link" [hidden]='!allowCambridgeRegistartion || allowCambridge'>
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon7" src="../../../assets/img/Parents.png">
          <img class="activeIcon7" src="../../../assets/img/Parents_active.png">
          <span class="menu-item-label">Cambridge Registration</span>
        </div>
      </a>
      <a  class="br-menu-link" [hidden]='!allowCambridge' (click)="navigateToCambridgeDashboard()">
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon8" src="../../../assets/img/Parents.png">
          <img class="activeIcon8" src="../../../assets/img/Parents_active.png">
          <span class="menu-item-label text-white">Cambridge</span>
        </div>
      </a>
      <button  class="btn helpButton"><a style="text-decoration: none; color:#1AB67A" href="tel:(+91) 9884488580"> Help Desk No <br><i class="fas fa-headset" style="padding-right:10px ;"></i>(+91) 9884488580</a> </button>
    </div>
    <!--div class="sidebar-footer"> <a href="" class="link" data-toggle="tooltip" title="" data-original-title="Settings"><i class="fa fa-cog fa-spin"></i></a> <a href="" class="link" data-toggle="tooltip" title="" data-original-title="Email"><i class="fa fa-envelope"></i></a> <a href="" class="link" data-toggle="tooltip" title="" data-original-title="Logout"><i class="fa fa-power-off"></i></a> </div-->
  </div>
  
  
  <div class="br-header">
    <div class="br-header-left">
      <div class="navicon-left hidden-md-down">
        <!-- <a id="btnLeftMenu" href="">
          <i class="fas fa-user-alt"></i>
        </a> -->
      </div>
      <div class="navicon-left hidden-md-down">
        <a id="btnAppsMenu" href="javascript:void(0);" data-toggle="dropdown" role="button">
          <i class="icon ion-android-apps tx-22"></i>
        </a>
        </div>
        <div class="navicon-left hidden-lg-up">
          <a id="btnLeftMenuMobile" href="">
            <i class="fas fa-bars"></i>
          </a>
        </div>
      </div>
      <div class="br-header-right">
        <div class="navicon m-3">
          <a href="">
            <i class="fa fa-bell fa-lg" aria-hidden="true"></i>
          </a>
        </div>
        <div class="nameLogo m-3">
            <h6 class="nameFirst">{{loginuserName? loginuserName[0] : 'S'}}</h6>
        </div>
        
        <nav class="nav profileNav">
          <div class="dropdown">
            <a href="" class="nav-link nav-link-profile" data-toggle="dropdown">
              <a class="logged-name" style="font-size: 12px;color: #172D4A;">Welcome</a>
              <br>
               <p class="logged-name loginuserName">{{loginuserName}}</p>
              <i class="fas fa-angle-down angleDownICon" style="position: absolute;"></i></a>
            <!--  <i class="fa fa-user-circle-o" aria-hidden="true" style="font-size: 20px;
                 margin-top: 4px;"></i> </a> -->
            <div class="dropdown-menu dropdown-menu-header wd-200 scale-up">
           
              <ul class="list-unstyled user-profile-nav" style="margin-bottom: 0rem;">
              
                <li>
                  <a href="#/sign-in" (click)='logOut()'>
                    <i class="fas fa-sign-out-alt" style="padding-right: 10px;"></i> Sign Out</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
  </div>
      
      
      
      
      
      
      