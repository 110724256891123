import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AllApisService } from "../../all-apis.service";
import { Subject } from "rxjs";
import { YtplayerService } from "./ytplayer.service";
import { PlatformLocation } from "@angular/common";
import { SharedService } from "../shared.service";
declare var $: any;

@Component({
  selector: "app-ytplayer",
  templateUrl: "./ytplayer.component.html",
  styleUrls: ["./ytplayer.component.css"],
})
export class YtplayerComponent implements OnInit {
  name = "Angular 6";
  safeSrc: SafeResourceUrl;
  link: string;
  videoLink: string;
  ios = false;
  iosUrl = null;
  @Input() checkLink: string;
  constructor(
    private location: PlatformLocation,
    private sanitizer: DomSanitizer,
    private allAPiServ: AllApisService,
    private ytServ: YtplayerService,
    private sharedServ: SharedService
  ) {
    location.onPopState(() => {
      $("#displayModel").on("hidden.bs.modal", function (e) {
        $("#displayModel iframe").attr(
          "src",
          $("#displayModel iframe").attr("src")
        );
      });
      $("#displayModel").modal("hide");
      $("#pdfModal").modal("hide");
      $("#imgModal").modal("hide");
    });
  }

  ngOnInit() {
    this.ytServ.getMessage().subscribe((res) => {
      console.log(res);
      this.checkLink = res["link"];
      console.log("res['link']", res["link"]);
      this.displayModel();
    });
    let self = this;
    $("#displayModel").on("hidden.bs.modal", () => {
      console.log("aya yha pr");
      self.changeSource(); //my internal reset function
    });
  }

  displayModel() {
    if (this.checkLink.includes("https://www.wonderslate.com/institution/")) {
      window.open(this.checkLink, "_blank");
    } else if (
      this.checkLink.includes("el-sm-content.s3.ap-south-1.amazonaws.com")
    ) {
      this.sharedServ.getS3Url(this.checkLink).subscribe((res) => {
        if (res.status && res.url) {
          this.checkLink = res.url;
          this.openDisplayModel();
        }
      });
    } else {
      this.openDisplayModel();
    }
  }

  openDisplayModel() {
    if (
      this.checkLink &&
      this.checkLink.includes("http://d37c2epss3gn4j.cloudfront.net/thinkroom")
    ) {
      this.checkLink = this.checkLink.replace("http", "https");
    }
    if (this.checkLink.includes(".pdf")) {
      this.ios =
        /iPad|iPhone|iPod/.test(navigator.userAgent) &&
        !(window as any).MSStream;
      if (this.ios) this.iosUrl = this.checkLink;
      $("#pdfModal").modal("show");

      $("#pdfModal #pdfViewer").prop("src", this.checkLink);
    } else if (
      this.checkLink.includes(".jpg") ||
      this.checkLink.includes(".png")
    ) {
      $("#imgModal").modal("show");
      $("#imgModal #imgViewer").prop("src", this.checkLink);
    } else if (this.checkLink.includes("https://www.youtube.com/watch?v=")) {
      $("#displayModel").modal("show");
      this.videoLink = this.checkLink.replace(
        "https://www.youtube.com/watch?v=",
        "https://www.youtube.com/embed/"
      );
      this.videoLink = this.videoLink + "?modestbranding=1&rel=0&loop=1";
      $("#displayModel #videoViewer").prop("src", this.videoLink);
      console.log("videoLink", this.videoLink);
    } else if (this.checkLink.includes("https://youtu.be/")) {
      $("#displayModel").modal("show");
      this.videoLink = this.checkLink.replace(
        "https://youtu.be/",
        "https://www.youtube.com/embed/"
      );
      this.videoLink = this.videoLink + "?modestbranding=1&rel=0&loop=1";
      $("#displayModel #videoViewer").prop("src", this.videoLink);
      console.log("videoLink", this.videoLink);
    } else if (
      this.checkLink.includes(".mp4") ||
      this.checkLink.includes(".mvk")
    ) {
      $("#displayModel").modal("show");
      this.videoLink = this.checkLink;
      this.videoLink = this.videoLink + "?autoplay=1&showinfo=0&controls=0";
      $("#displayModel #videoViewer").prop("src", this.videoLink);
      console.log("videoLink", this.videoLink);
    }
  }

  closeVideo() {
    console.log("came here");
    $("#displayModel").on("hidden.bs.modal", function (e) {
      $("#displayModel iframe").attr(
        "src",
        $("#displayModel iframe").attr("src")
      );
    });
  }

  changeSource() {
    $("#displayModel iframe").attr("src", "");
    return true;
  }

  iosPdfDownload() {
    window.location.href = this.iosUrl;
  }
}
