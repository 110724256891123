<div style="width: 20%;">
    <app-navigation-panel-admin val='impartusLicence'></app-navigation-panel-admin>
</div>
  
<div class="breadcrumb">
    <div class="col-md-12">
        <div class="row row-sm" style="padding-top: 10px; margin: 0 auto; text-align: center;">
            <div class="col-md-12 gradBack">
                <span style="float: left;padding-left: 12px;"><i class="fas fa-arrow-left left-arr-icon"
                        (click)="backClicked()"></i></span>
                <h4 style="font-size: 20px;">{{schoolName}}</h4>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid br-mainpanel">
    <div class="row row-sm">
        <div class="col-md-12">
            <div class="singleRowContainer">
        <form #f="ngForm">
            <div class="row row-sm">        
                <div class="form-group col-md-4">
                <h6>  CLASS </h6>
                <select #std="ngModel" name="std" [(ngModel)]="standard" (change)="updateSectionList()"
                  class="form-control form-control-sm">
                  <option [value]="">Select class</option>
                  <option *ngFor="let std of stdList" [value]="std">{{std}}</option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <h6>
                  SECTION </h6>
                <ng-multiselect-dropdown name="section" [placeholder]="'Select Section'"  [settings]="dropdownSettingsSection" [data]="sectionList"
                  [(ngModel)]="selectedSection"
                  (onSelect)="updateEventSection($event)" (onDeSelect)="deleteSection($event)" (onSelectAll)="onSelectAllSection($event)">
                </ng-multiselect-dropdown>
              </div>
              <div class="form-group col-md-2" style="padding-top: 20px;">
                <h6></h6>
                <button class="btn btn-primary" (click)="getStudentList()" >View Student</button>
              </div>
            </div>   

        </form>
        </div>
        <div class="col-md-12">
            <h4 class="pageTitle" style="font-size: 20px;">{{schoolName}}</h4>
        </div>
        <div class="col-md-12">
            <div class="singleRowContainer">
        <div class="row row-sm">
            <div class="form-group col-md-4" *ngIf='showButtons'>
                <button class="btn btn-success btn-sm" (click)="addUser()" ><i  class="fas fa-user-plus" title="Delete student" > Add Student</i></button>
            </div>
            <div class="form-group col-md-4" *ngIf='showButtons'>
                <button class="btn btn-dark btn-sm" (click)="deleteUser()" ><i  class="fas fa-user-minus" title="Delete student"> Delete Student</i></button>
            </div>
        </div>
    </div>
</div>
        <form  class="col-md-12" id="frm-example">
            <p><button class="btn btn-primary" style="float: right;" type='submit' *ngIf='showSelection'><i  class="far fa-hand-pointer" title="Delete student" ></i> Select User</button></p>
                        
            <div class="col-md-12 showhideTable ">
                <table class="display example" cellspacing="0" width="100%">
                    <thead>

                    </thead>

                </table>
            </div>
        </form>
    </div>
</div>
