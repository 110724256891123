<div class="homePageContainer">
    <div class="container-fluid">
        <div class="row no-gutters">
            <div class="col-md-12 helpNumber">
                <a href="tel:(+91) 7565801801"> <i class="fas fa-headset" style="text-decoration: none; color:#316AB4;cursor: pointer;padding-right:10px ;"> </i> (+91) 9999999999</a>
            </div>
            <div class="col-md-6">
                <div class="leftCotainer">
                    <div class="logoContainer">
                        <img src="../../assets/Navars-Edutech-logo.png" class="img-fuid">
                    </div>
                    <div class="contentContainer">
                        <p>Chrysalis is committed to<br><span style="color: #316AB4;">Awakening Human Potential</span><br>in every child</p>
                    </div>
                    <button class="btn btn-lg loginBtn" type="button" (click)="signInPage()">
                        <p>LOG IN</p>
                    </button>
                </div>
            </div>
            <div class="col-md-6 homeRightImage">
                <img src="../../assets/img/Illustration Home.png">
            </div>
        </div>
    </div>
</div>
