<div>
  <div class="breadcrumb" >
      <div class="col-md-12">
          <div class="row row-sm" style="padding-top: 10px; margin: 0 auto; text-align: center;">
              <div class="col-md-12 gradBack">
                  <span style="float: left;padding-left: 12px;"><i class="fas fa-arrow-left left-arr-icon" (click)="backClicked()"></i></span><h4 style="font-size: 20px;">{{schoolName}}</h4>
                </div>
      </div>
      </div>
      
      <!-- <li class="breadcrumb-item active">Webinar List</li> -->
  </div>
  <br>
</div>

<div class="br-pagebody" style="background-color:white;">
  <div class="row row-sm" style="display:flex; height:50vh">
    <div class="col-md-12" style="text-align: center; font-size: xx-large;">
      <b><u>Assessment Portal</u></b>
    </div>
    <div class="col-sm-12 col-md-6" style="text-align: center; flex-basis:50%; margin: auto; font-size: x-large;">
      <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateToAssessmentPerformance()"><i
          class="fas fa-chart-bar fa-2x"> Assessment Performance</i></button>

      <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateToQuestion()"><i
          class="fas fa-tasks fa-2x"> Set Question</i></button>

      <button class="btn commonBtn col-sm-12 col-md-12 m-2" (click)="navigateToEditQuestion()"><i
          class="far fa-edit fa-2x"> Edit Question</i></button>
    </div>
  </div>
</div>