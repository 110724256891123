import { Component, OnInit, Input } from '@angular/core';
import { AllApisService } from '../../all-apis.service';

@Component({
  selector: 'app-navigation-panel-teacher',
  templateUrl: './navigation-panel-teacher.component.html',
  styleUrls: ['./navigation-panel-teacher.component.css']
})
export class NavigationPanelTeacherComponent implements OnInit {
  @Input() val:string;
  loginuserName: any;
  role = 'Teacher';
  constructor(private allApiServ:AllApisService) { }

  ngOnInit() {
    this.loginuserName = this.allApiServ.logedInUserName()
    this.role = localStorage.getItem('role')
    console.log('value',this.val);
   $('.activeIcon1').css('display','none');
   $('.activeIcon2').css('display','none');
   $('.activeIcon3').css('display','none');
   $('.activeIcon4').css('display','none');
   $('.activeIcon5').css('display','none');
   $('.activeIcon6').css('display','none');
    if(this.val=="teacherHomepage"){
      $('.br-sideleft-menu a:nth-child(1)').addClass('active');  
      $('.deactiveIcon2').css('display','none');
      $('.activeIcon2').css('display','block');
    }
    if(this.val=="coursePlanner"){
      $('.br-sideleft-menu a:nth-child(2)').addClass('active');
      $('.deactiveIcon1').css('display','none');
      $('.activeIcon1').css('display','block'); 
    }
    if(this.val=="teacherLiveClasses"){
      $('.br-sideleft-menu a:nth-child(3)').addClass('active');
      $('.deactiveIcon3').css('display','none');
      $('.activeIcon3').css('display','block');

    }
    if(this.val=="studentResponse"){
      $('.br-sideleft-menu a:nth-child(4)').addClass('active');
      $('.deactiveIcon4').css('display','none');
      $('.activeIcon4').css('display','block');
    }
    if(this.val=="progressReport"){
      $('.br-sideleft-menu a:nth-child(5)').addClass('active');
      $('.deactiveIcon5').css('display','none');
      $('.activeIcon5').css('display','block');
    }
    if(this.val=="professionalDevelopment"){
      $('.br-sideleft-menu a:nth-child(6)').addClass('active');
      $('.deactiveIcon6').css('display','none');
      $('.activeIcon6').css('display','block');
    }
  
    $('#btnLeftMenuMobile').on('click', function () {
      $('body').addClass('show-left');
      return false;
    });
    $(document).on('click', function (e) {
      e.stopPropagation();
      // closing left sidebar
      if ($('body').hasClass('show-left')) {
        $('body').removeClass('show-left');
        /* var targ = $(e.target).closest('.br-sideleft').length;
        if (!targ) {} */
      }
    });
  }

  logOut(){
    this.allApiServ.logoutUser();
    
  }
}