import { Component, OnInit } from "@angular/core";
import { LessonServiceService } from "./lesson-service.service";
import { AllApisService } from "../all-apis.service";
import { Router } from "@angular/router";
import { ConfirmDialogService } from "../shared/confirm-dialog/confirm-dialog.service";
import { NotifierService } from "angular-notifier";
import { YtplayerService } from "../shared/ytplayer/ytplayer.service";

declare var $: any;
@Component({
  selector: "app-lesson-dashboard",
  templateUrl: "./lesson-dashboard.component.html",
  styleUrls: ["./lesson-dashboard.component.css"],
})
export class LessonDashboardComponent implements OnInit {
  lessonList = [];
  stdList = [];
  versionList = ["2019", "2020"];
  variantList = ["TRX", "TRS"];
  subjectList = [];
  editId: any;
  editTitle: any;
  editSequence: any;
  searchObj = new searchKeys();
  popup = false;
  linkList: any;
  deletelesson: boolean = false;
  videoLink: any;
  safeVideo: any;
  checkLink: any;
  editLink: string;
  newLink: string;
  editIndex: any;
  lessonIndex: any;
  deleteIndex: any;
  subjectMaps: any;
  sectionList: any[];
  constructor(
    private serv: LessonServiceService,
    private allApiServ: AllApisService,
    private route: Router,
    private confirmBox: ConfirmDialogService,
    private notifier: NotifierService,
    private ytServ: YtplayerService
  ) {}

  ngOnInit(): void {
    // this.getLessonList();
    this.getClassList();
    this.getSubjectList();
  }
  populateTable() {
    let action_btn =
      '<span style="padding-left:10px;position:pointer;" class="text-danger links" title="Display Links"><i class="fas fa-eye"> </i> Links </span><span style="padding-left:10px;position:pointer;"  class="text-info edit" title="Edit Details"><i class="fas fa-edit"> </i></span> <span style="padding-left:10px;" class="delete text-danger" title="Delete"><i class="far fa-trash-alt" *ngIf="false"> </i></span> <span style="padding-left:10px;position:pointer;" class="text-info move" title="Move to contents"><i class="fas fa-arrow-right"> </i></span>';
    let action_btn_without_delete =
      '<span style="padding-left:10px;position:pointer;" class="text-danger links" title="Display Links"><i class="fas fa-eye"> </i> Links </span><span style="padding-left:10px;position:pointer;"  class="text-info edit" title="Edit Details"><i class="fas fa-edit"> </i></span> <span style="padding-left:10px;" class="delete text-danger" title="Delete"><i class="far fa-trash-alt" *ngIf="false"> </i></span> <span style="padding-left:10px;position:pointer;" class="text-info move" title="Move to contents"><i class="fas fa-arrow-right"> </i></span>';
    let self = this;
    if ($.fn.DataTable.isDataTable(".example1")) {
      $(".example1").DataTable().clear().destroy();
    }
    $(".showhideTable1").css("display", "block");
    const example = $(".example1").DataTable({
      data: this.lessonList,
      columns: [
        { data: "moduleId", defaultContent: "<i>Not Set</i>" },
        { data: "program", defaultContent: "<i>Not Set</i>" },
        { data: "semester", defaultContent: "<i>Not Set</i>" },
        { data: "subject", defaultContent: "<i>Not Set</i>" },
        { data: "title", defaultContent: "<i>Not Set</i>" },
        { data: "sequence", defaultContent: "<i>Not Set</i>" },
        {
          data: null,
          className: "center",
          render: function (data) {
            if (data.lessonContents && data.lessonContents.length > 0) {
              return action_btn_without_delete;
            } else {
              return action_btn;
            }
          },
          defaultContent: action_btn_without_delete,
        },
      ],
      dom: "Bfrtip",
      //   buttons: [
      //     {
      //       extend: 'excel',
      //       filename: 'StudentResponse',
      //       title: 'Padho'
      //   },
      //   {
      //       extend: 'csv',
      //       filename: 'StudentResponse',
      //       title: 'Padho'
      //   },
      //   {
      //       extend: 'pdf',
      //       filename: 'StudentResponse',
      //       title: 'Padho'
      //   }
      // ],
      order: [[0, "asc"]],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
    });
    $(".example1 tbody ").on("click", "tr span.move", function () {
      // console.log("move...")
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).index();
      self.navigateToContents(data);
    });
    $(".example1 tbody").on("click", "tr span.edit", function () {
      console.log("edit............");
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).index();
      self.editLessonData(self.lessonList[data]);
    });
    $(".example1 tbody").on("click", "tr span.delete", function () {
      console.log("edit............");
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).index();
      self.deleteIndex = data;
      self.deleteLessonData(self.lessonList[data]);
    });
    $(".example1 tbody ").on("click", "tr span.links", function () {
      // console.log("move...")
      var p = this.closest("tr");
      var data = $(".example1").DataTable().row(p).index();
      self.displayLinks(data);
    });
  }

  deleteLessonData(data: any) {
    var obj = {};
    obj["id"] = data._id;
    let self = this;

    this.confirmBox.confirmThis(
      "Are you sure you want to delete the lesson",
      "Delete Lesson ?",
      function () {
        self.serv.deleteLesson(obj).subscribe((res) => {
          self.notifier.notify("success", "Lesson deleted succesfully");
          self.lessonList.splice(self.deleteIndex, 1);
          self.populateTable();
        });
      },
      function () {}
    );
  }

  editLessonData(data: any) {
    console.log("data", data);
    this.editId = data._id;
    this.editTitle = data.title;
    this.editSequence = data.sequence;
    $("#editModel").modal("show");
  }

  updateEditDetails() {
    var obj = {};

    obj["id"] = this.editId;
    obj["editTitle"] = this.editTitle;
    obj["editSequence"] = this.editSequence;

    this.serv.updateEditLesson(obj).subscribe((res) => {
      console.log("lession", res);
      if (res.status == true) {
        $("#editModel").modal("hide");
      }

      this.getLessonList();
    });
  }

  getClassList() {
    this.serv.getClass().subscribe((res) => {
      this.stdList = ["B.B.A", "M.B.A"];
    });
  }

  getSubjectList() {
    this.serv.getSubject().subscribe((res) => {
      console.log("Subjects", res);
      var check = {};
      this.subjectList = res.subjects;
    });
  }

  updateSubject() {
    if (!this.subjectList && !this.subjectList.length) {
      this.subjectList = [];
    }
    this.lessonList = [];
    let currStd = this.stdList[0];
    if (this.searchObj.program) {
      currStd = this.searchObj.program;
    }
    let sections = this.stdList
      .filter((sl) => sl.class == currStd)
      .map((e) => e.section);
    this.sectionList = [...new Set([].concat(...sections))];
    let currSec = this.sectionList[0];
    if (this.searchObj.semester) {
      currSec = this.searchObj.semester;
    }
    console.log("sectons", this.sectionList);
    console.log("this.subjectList", this.subjectList);
  }

  getLessonList() {
    this.serv.getLesson(this.searchObj).subscribe((res) => {
      console.log("lession", res);
      if (res.status) {
        this.lessonList = res["result"];
        this.populateTable();
      }
    });
  }
  navigateToContents(evt: any) {
    this.allApiServ.lessonInfo = this.lessonList[evt];
    localStorage.setItem("moduleId", this.lessonList[evt]["_id"]);
    this.route.navigate(["/dashboard/content"]);
  }

  displayLinks(evt: any) {
    this.linkList = this.lessonList[evt]["links"];
    this.lessonIndex = evt;
    // this.popup=true;
    //this.populateTable2();
    $("#errorModal").modal("show");
  }

  displayModel(evt: any) {
    // window.location.href=this.linkList[evt]['url'];
    console.log("evt", evt);
    $("#errorModal").modal("hide");
    this.checkLink = this.linkList[evt]["url"];
    this.ytServ.confirmThis(this.checkLink);
  }

  backClicked() {
    this.route.navigate(["/masterAdmin/dashboard"]);
  }

  populateTable2() {
    let action_btn =
      '<i style="padding-right:30px,position:pointer;" class="fas fa-eye  text-danger links" title="Display Links"></i>  <i style="padding-left:30px,position:pointer;" class="fas fa-arrow-right  text-info move" title="Move to contents"></i>';
    let self = this;
    if ($.fn.DataTable.isDataTable(".example2")) {
      $(".example2").DataTable().clear().destroy();
    }
    $(".showhideTable2").css("display", "block");
    const example = $(".example2").DataTable({
      data: this.linkList,
      columns: [
        { data: "title", defaultContent: "<i>Not Set</i>" },
        { data: "url", defaultContent: "<i>Not Set</i>" },
        { data: "contentType", defaultContent: "<i>Not Set</i>" },
      ],
      // dom: 'Bfrtip',

      order: [],
      lengthMenu: [
        [5, 10, 25, 50, -1],
        [5, 10, 25, 50, "All"],
      ],
    });
  }

  updateLink() {
    console.log("the link has been updated to", this.newLink);
    this.linkList[this.editIndex]["url"] = this.newLink;
    console.log(this.linkList[this.editIndex]["url"]);
    var obj = {
      standard: this.lessonList[this.lessonIndex]["standard"],
      moduleId: this.lessonList[this.lessonIndex]["moduleId"],
      version: this.lessonList[this.lessonIndex]["version"],
      variant: this.lessonList[this.lessonIndex]["variant"],
      subject: this.lessonList[this.lessonIndex]["subject"],
      title: this.linkList[this.editIndex]["title"],
      url: this.newLink,
    };
    $("#editModal").modal("hide");
    this.serv.updateLink(obj).subscribe((res) => {
      if (res["status"]) {
        this.notifier.notify("success", "Link has been updated successfully");
      } else {
        this.notifier.notify("error", "Link update failed");
      }
    });
  }

  editModel(evt: any) {
    $("#errorModal").modal("hide");
    this.editLink = this.linkList[evt]["url"];
    this.editIndex = evt;
    $("#editModal").modal("show");
  }
}

export class searchKeys {
  program: string;
  version: string;
  semester: string;
  subject: string;
}
