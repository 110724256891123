import { Component, OnInit } from '@angular/core';
import { SchoolServiceService } from '../school-info/school-service.service';
import { NotifierService } from 'angular-notifier'
import { Router } from '@angular/router';
import { TeacherMapService } from '../zoom/teacher-subject/teacher-map.service';

declare var $:any;
@Component({
  selector: 'school-subject-assignment',
  templateUrl: './school-subject-assignment.html',
  styleUrls: ['./school-subject-assignment.css']
})
export class SchoolSubjectAssignment implements OnInit {
  zoomUserList=[];
  gtmUserList=[];
  versionList=['2019','2020'];
  variantList=['TRX','TRS'];
  schoolInfo: school
  schoolDetails: object;
  slotList = [{ name: "fullDay", count: 0 }, { name: "8-12", count: 0 }, { name: "12-4", count: 0 }, { name: "4-7", count: 0 }];
  schoolList = [];
  schoolDisplayList=[];
  dropdownSettings  = {
    singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      allowSearchFilter: true
  };
  teamInfo:any;
  classList: any;
  contentClassList: any;
  displayList: any[];
  constructor(private router: Router, private teacherMapServ: TeacherMapService, private serv: SchoolServiceService, public notifier: NotifierService) { }

  ngOnInit() {
    this.schoolInfo = new school();
    this.serv.lessonServ.getClass().subscribe(res => {
      if(res) {
        this.contentClassList=res['result'];
        this.getSchoolList();
      }
    }),
    err => {
      console.log('unable to fect schools')
    }
  }

  getSchoolList() {
    this.serv.getSchoolList()
      .subscribe(res => {
        if(res) {
          this.schoolList = res.schoolList;
          this.schoolDisplayList=[];
          this.schoolList.forEach(ele => {
            this.schoolDisplayList.push({ item_id: ele.id, item_text: ele.name + ' (' + ele.id + ')'});
          })
        
        }
      }),
      err => {
        console.log('unable to fect schools')
      }
  }

  saveInfo() {
    //this.onSubmit();
    this.schoolInfo.schoolName = this.schoolDetails["name"];
    this.schoolInfo.smSchoolId = this.schoolDetails["id"];
    this.schoolInfo.city = this.schoolDetails["city"];
    console.log(this.schoolInfo);
    console.log(this.schoolDetails);
    this.serv.addSchoolDetail(this.schoolInfo)
      .subscribe(res => {
        console.log(res)
        if(res.status) {
          this.notifier.notify("success", "Information saved successfully");
        } else {
          this.notifier.notify("error", res.message);
        }
      },
        err => {
          this.notifier.notify("error", "Something bad happend");
        }
      )
  }

  getTeacherSubjectList(){
    this.displayList=[];
    if(this.schoolDetails['id']) {
    this.teacherMapServ.fetchList(this.schoolDetails['id'])
    .subscribe(res=>{
      console.log('teacher list', res);
      if(res["status"] && res["result"].length){
        res["result"].forEach(elem=>{
          elem["subjectList"].forEach(elem1=>{
            let obj={
              name:elem["teacherName"],
              class:elem1["class"],
              section:elem1["section"].join(", "),
              subject:elem1["subject"],
              id:elem["teacherId"]
            }
            this.displayList.push(obj);
          })
        })
        this.populateTable(true);
      } else {
        this.populateTable(true);
      }
     
    })
  } else {
    this.populateTable(true);
  }
  }

  populateTable(active:boolean) {
    let action_btn =  '<i style="padding-left:10px,position:pointer;" class="far fa-trash-alt text-danger text-info delete" title="Delete"></i>'
    let self=this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.displayList,
        columns: [
          
          { data: 'name', defaultContent: '<i>Not Set</i>' },
          { data: 'class', defaultContent: '<i>Not Set</i>' },
          { data: 'section', defaultContent: '<i>Not Set</i>' },
          { data: 'subject', defaultContent: '<i>Not Set</i>' },
          // {
          //   data: null,
          //   className: 'center',
          //   defaultContent: action_btn,
            
          // } 

        ],
        // dom: 'Bfrtip',
        //  buttons: [],

        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']]
      });
      $('.example tbody ').on('click', 'tr i.delete', function () {
        console.log("delete...")
        var p = this.closest('tr');
        var data = $('.example').DataTable().row(p).index();
        // self.confirmDetails(data);
     });
      
  }


  updateEventSchool(school:any) {
    console.log('seatcher changed', school);
    // var arr = this.schoolList;
    this.schoolDetails={};
    let currSchool = this.schoolList.filter(s=> s.id == school.item_id)[0]
    
    this.schoolDetails["id"]= currSchool.id;
    this.schoolDetails["name"] = currSchool.name;
    this.getTeacherSubjectList();
    
    console.log("aaaa", this.schoolInfo);
  }

onSubmit(){
  console.log(this.schoolInfo);
}

backClicked(){
  this.router.navigate(['/masterAdmin/dashboard']);
}

openSchoolList() {
  this.router.navigate(['/schoolInfoList']);
  return false;
}

replaceHost() {
  this.router.navigate(['/reschedule']);
  return false;
}

meetingDetails(){
  this.router.navigate(['/meetingDetails']);
  return false;
}

userList() {
  this.router.navigate(['/userList']);
  return false;
}

userInfo() {
  this.router.navigate(['/userInfo']);
  return false;
}

}

export class school {
  schoolName: string;
  city: string;
  smSchoolId: string;
  standardList: Array<standardLesson>;
  constructor() {
  }
}

export class standardLesson {
  standard:string;
  contentStandard:string;
  variant:String;
  version:String;
  constructor(){
  }
}