<body class="">

    <div class="br-logo"><a routerLink=""><img src="https://lms-books.s3.ap-south-1.amazonaws.com/lms-ch/sm_logo.png" class="img-fluid"></a></div>
    <div class="search-bar">
        <div class="search-icon">
            <i class="searchIcons ion-search"></i>
        </div>
        <input type="text" placeholder="What are you looking for...">
        <div class="close-search">
            <i class="searchIcons ion-ios-close"></i>
        </div>
    </div>
    <div class="br-sideleft overflow-y-auto">

        <div class="br-sideleft-menu ">


            <a href='#/admin/dashboard/admin' class="br-menu-link">
                <div class="br-menu-item" aria-disabled="true">
                    <img class="deactiveIcon2" src="../../../assets/img/video.png">
                    <img class="activeIcon2" src="../../../assets/img/video_active.png">
                    <span class="menu-item-label">Scheduled Live Classes</span>
                </div>
            </a>

            <a href='#/admin/assignSubject' class="br-menu-link">
                <div class="br-menu-item" aria-disabled="true">
                    <img class="deactiveIcon1" src="../../../assets/img/Subject.png">
                    <img class="activeIcon1" src="../../../assets/img/SubjectActive.png">
                    <span class="menu-item-label">Allocate Subjects</span>
                </div>
            </a>
            <a href='#/admin/studioConfigure' class="br-menu-link" >
                <div class="br-menu-item" aria-disabled="true">
                    <img class="deactiveIcon3" src="../../../assets/img/Subject.png">
                    <img class="activeIcon3" src="../../../assets/img/SubjectActive.png">
                    <span class="menu-item-label">Studio Configuration</span>
                </div>
            </a>
            <a href='#/admin/cambridgeStudentList' class="br-menu-link" >
                <div class="br-menu-item" aria-disabled="true">
                    <img class="deactiveIcon4" src="../../../assets/img/Subject.png">
                    <img class="activeIcon4" src="../../../assets/img/SubjectActive.png">
                    <span class="menu-item-label">Cambridge Student List</span>
                </div>
            </a>
           
            <a href='#/credentials' class="br-menu-link" *ngIf="team">
                <div class="br-menu-item" aria-disabled="true">
                    <img class="deactiveIcon5" src="../../../assets/img/Subject.png">
                    <img class="activeIcon5" src="../../../assets/img/SubjectActive.png">
                    <span class="menu-item-label">Teacher Credentials</span>
                </div>
            </a>
            <a href='#/studentCredentials' class="br-menu-link" *ngIf="team">
                <div class="br-menu-item" aria-disabled="true">
                    <img class="deactiveIcon6" src="../../../assets/img/Subject.png">
                    <img class="activeIcon6" src="../../../assets/img/SubjectActive.png">
                    <span class="menu-item-label">Student Credentials</span>
                </div>
            </a>
           
            <a href='#/impartusMapping' class="br-menu-link" *ngIf="impartus">
                <div class="br-menu-item" aria-disabled="true">
                    <img class="deactiveIcon7" src="../../../assets/img/Subject.png">
                    <img class="activeIcon7" src="../../../assets/img/SubjectActive.png">
                    <span class="menu-item-label">Impartus Licence</span>
                </div>
            </a>

        </div>
        <!--div class="sidebar-footer"> <a href="" class="link" data-toggle="tooltip" title="" data-original-title="Settings"><i class="fa fa-cog fa-spin"></i></a> <a href="" class="link" data-toggle="tooltip" title="" data-original-title="Email"><i class="fa fa-envelope"></i></a> <a href="" class="link" data-toggle="tooltip" title="" data-original-title="Logout"><i class="fa fa-power-off"></i></a> </div-->
    </div>


    <div class="br-header">
        <div class="br-header-left">
            <div class="navicon hidden-md-down">
                <!-- <a id="btnLeftMenu" href="">
              <i class="fas fa-user-alt"></i>
            </a> -->
            </div>
            <div class="navicon hidden-md-down">
                <a id="btnAppsMenu" href="javascript:void(0);" data-toggle="dropdown" role="button">
                    <i class="icon ion-android-apps tx-22"></i>
                </a>
            </div>
            <div class="navicon hidden-lg-up">
                <a id="btnLeftMenuMobile" href="">
                    <i class="fas fa-bars"></i>
                </a>
            </div>
        </div>

        <div class="br-header-right">
            <div class="navicon m-3">
                <a href="">
                    <i class="fa fa-bell fa-lg" aria-hidden="true"></i>
                </a>
            </div>
            <div class="nameLogo m-3">
                <h6 class="nameFirst">{{loginuserName? loginuserName[0] : 'T'}}</h6>
            </div>
            <nav class="nav profileNav">
                <div class="dropdown">
                    <a href="" class="nav-link nav-link-profile" data-toggle="dropdown">
                        <a class="logged-name hidden-md-down" style="font-size: 12px;color: #172D4A;">Welcome</a>
                        <br>
                        <p class="logged-name hidden-md-down loginuserName">{{loginuserName}}</p>
                        <i class="fas fa-angle-down angleDownICon" style="position: absolute;"></i>
                    </a>
                    <!--  <i class="fa fa-user-circle-o" aria-hidden="true" style="font-size: 20px;
                 margin-top: 4px;"></i> </a> -->
                    <div class="dropdown-menu dropdown-menu-header wd-200 scale-up">

                        <ul class="list-unstyled user-profile-nav" style="margin-bottom: 0rem;">

                            <li>
                                <a href="#/sign-in" (click)='logOut()'>
                                    <i class="fas fa-sign-out-alt" style="padding-right: 10px;"></i> Sign Out</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>