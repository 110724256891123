<div class="card ">
    <div class="card-body">
      <h5 class="card-title">School Licence Page<button type="button" class="btn btn-primary m-2" (click)="openSchoolList()"><i class="fa fa-list"> </i> Existing School List</button>
        <button type="button" class="btn btn-primary m-2" (click)="replaceHost()"><i class="fa fa-list"> </i> Replace Host</button>
        <button type="button" class="btn btn-primary m-2" (click)="meetingDetails()"><i class="fa fa-list"> </i> Check Meetings</button>
        <button type="button" class="btn btn-primary m-2" (click)="userList()"><i class="fa fa-list"> </i> Licence List</button>
        <button type="button" class="btn btn-primary m-2" (click)="userInfo()"><i class="fa fa-list"> </i> Add Licence</button>
      </h5>
      <div class="col-md-12">
        <form #f="ngForm">
            <div class="row">
                <div class="col-md-6"><strong>School Name</strong></div>
                
            </div>
      
            <div class="row loopRowDiv">  
      
                <div class="form-group col-md-6">
                    <ng-multiselect-dropdown
                        [data]="schoolDisplayList"
                        [settings]="dropdownSettings"
                        (onSelect)="updateEventSchool($event)"> 
                    </ng-multiselect-dropdown>
                    <!-- <select class="form-control form-control-sm" #schoolName="ngModel" name="schoolName" [(ngModel)]="schoolDetails" (ngModelChange)="getSchoolDetails()"> 
                        <option *ngFor="let school of schoolList" [ngValue]="school">{{school.name}}({{school.id}})</option>
                    </select> -->
                </div>
              </div>
              <div class="row">
                <div class="col-md-2"><strong>Team</strong></div>
                <div class="col-md-2"><strong>Zoom Paid</strong></div>
                <div class="col-md-2"><strong>Gotowebinar Paid</strong></div>
                <div class="col-md-2"><strong>Gotomeeting</strong></div>
                <div class="col-md-2"><strong>impartus</strong></div>
                
            </div>
              <div class="row loopRowDiv">
                <!-- <div class="form-group col-md-2">
                    <input type="checkbox"  class="form-check-input" #zoomFree="ngModel" name="zoomFree" [(ngModel)]="schoolInfo.zoomFree" class="form-group col-md-3">
                </div> -->
                <div class="form-group col-md-2">
                  <ui-switch defaultBgColor="red" #teamVar="ngModel" name="team" [(ngModel)]="schoolInfo.team"  [checked]=true></ui-switch>
                  <!-- <input type="checkbox" #zoomPaid="ngModel" name="zoomPaid" [(ngModel)]="schoolInfo.zoomPaid" class="form-group col-md-3"> -->
              </div>
                <div class="form-group col-md-2">
                    <ui-switch defaultBgColor="red" #zoomPaid="ngModel" name="zoomPaid" [(ngModel)]="schoolInfo.zoomPaid"  [checked]=true></ui-switch>
                    <!-- <input type="checkbox" #zoomPaid="ngModel" name="zoomPaid" [(ngModel)]="schoolInfo.zoomPaid" class="form-group col-md-3"> -->
                </div>
                <div class="form-group col-md-2">
                    <ui-switch defaultBgColor="red" #gotowebinar="ngModel" name="gotowebinar" [(ngModel)]="schoolInfo.gotowebinarPaid"  [checked]=true></ui-switch>
                    <!-- <input type="checkbox" #gotowebinar="ngModel" name="gotowebinar" [(ngModel)]="schoolInfo.gotowebinarPaid" class="form-group col-md-12"> -->
                </div>
                <div class="form-group col-md-2">
                  <ui-switch defaultBgColor="red" #gotomeeting="ngModel" name="gtm" [(ngModel)]="schoolInfo.gotomeeting"  [checked]=true></ui-switch>
                  <!-- <input type="checkbox" #gotowebinar="ngModel" name="gotowebinar" [(ngModel)]="schoolInfo.gotowebinarPaid" class="form-group col-md-12"> -->
              </div>
              <div class="form-group col-md-2">
                <ui-switch defaultBgColor="red" #impartus="ngModel" name="impartus" [(ngModel)]="schoolInfo.impartus"  [checked]=true></ui-switch> 
                <!-- <input type="checkbox" #gotowebinar="ngModel" name="gotowebinar" [(ngModel)]="schoolInfo.gotowebinarPaid" class="form-group col-md-12"> --> 
            </div>
            
                
            </div>
            <div class="row">
                <div class="col-md-2"><strong>Full Day</strong></div>
                <div class="col-md-2"><strong>8:00 AM-12:00 PM</strong></div>
                <div class="col-md-2"><strong>12:00 PM-4:00PM</strong></div>
                <div class="col-md-2"><strong>4:00 PM-7:00 PM</strong></div>
            </div>
            <div class="row loopRowDiv">  
      
                <input class="form-group col-md-2" type="number" #fullday="ngModel" name="fullDay" [(ngModel)]="schoolInfo['slotList'][0]['count']">
                <input class="form-group col-md-2" type="number" #first="ngModel" name="first" [(ngModel)]="schoolInfo['slotList'][1]['count']">
                <input class="form-group col-md-2" type="number" #second="ngModel" name="second" [(ngModel)]="schoolInfo['slotList'][2]['count']">
                <input class="form-group col-md-2" type="number" #third="ngModel" name="third" [(ngModel)]="schoolInfo['slotList'][3]['count']">
            </div>

              
            
            <div class="form-group col-md-6" [hidden]="!schoolInfo.gotowebinarPaid">
              <div class="col-md-12"><strong>Organizer Key</strong></div>
              <input type="text" #gotowebinar="ngModel" name="gtwOrganizerKey" [(ngModel)]="schoolInfo.gtwOrganizerKey" class="form-group form-control col-md-12">
           </div>

            <div class="col-md-12" [hidden]="!schoolInfo.zoomPaid">
            <div class="row" style="margin-bottom: 10px;text-align: left;">
              <div class="col-md-1"><strong>Full Day</strong></div>
              <div class="col-md-3"><strong>Email Id</strong></div>
              <div class="col-md-2"><strong>Type</strong></div>
              <div class="col-md-2"><strong>Max Attendees</strong></div>
              <div class="col-md-2"><strong>Start Date</strong></div>
              <div class="col-md-2"><strong> Purchase Date</strong></div>
              
             </div>
       
            <div class="row loopRowDiv" *ngFor="let obj of zoomUserList;let i=index">
            
              <div class="form-group col-md-1">
              <input class="form-control" type="text" #count="ngModel"
              [(ngModel)]="obj.count" name="count{{i}}" placeholder="Count" disabled>
              </div>
          
             <div class="form-group col-md-3">
             <input class="form-control" type="text" #emailId="ngModel"
             [(ngModel)]="obj.emailId" name="email{{i}}" placeholder="Email Id">
             </div>
             <div class="form-group col-md-1">
              <select name="zoomType{{i}}" class='form-control' [(ngModel)]="obj.licenceType">
                <option value="Paid">Paid</option>
                <option value="Free">Free</option>
              </select>
              </div>

             <div class="form-group col-md-2">
              <input class="form-control" type="number" #maxAttendee="ngModel"
              [(ngModel)]="obj.maxAttendee" name="maxAttendee{{i}}" placeholder="Max Attendee">
              </div>

              <div class="form-group col-md-2">
                <input type="date" #licenseDate="ngModel" name="licenseDate{{i}}" [(ngModel)]="obj.licenseDateStr" 
        class="form-control form-control-sm"> 
                </div>
                <div class="form-group col-md-2">
                  <input type="date" #purchase="ngModel" name="purchaseDate{{i}}" [(ngModel)]="obj.purchaseDate" 
          class="form-control form-control-sm"> 
                  </div>
              <!-- remove button -->
             <div class="form-group col-md-1">
             <div class="removeBtn"><button class="btn" (click)="removeRow(i)">
               <i class="fas fa-trash-alt"> </i> </button></div>
             </div>
       
             </div> 
             <div class="col-md-12 btnContainer">
               <div
                        class="" data-toggle="modal" data-target="#fineModal" (click)="addNewRow()"><i
                          class="fas fa-plus text-success"> </i> New Row</div>
                </div>
              </div>
              <!-- Submit Buttons -->
             <div class="col-md-12" [hidden]="!schoolInfo.gotomeeting">
              <div class="row" style="margin-bottom: 10px;text-align: left;">
                <div class="col-md-2"><strong>First Name</strong></div>
                <div class="col-md-2"><strong>Last Name</strong></div>
                <div class="col-md-4"><strong>Email Id</strong></div>            
                <div class="col-md-3"><strong>Start Date</strong></div>
               </div>
         
              <div class="row loopRowDiv" *ngFor="let obj of gtmUserList;let i=index">
              
               
                <div class="form-group col-md-2">
                  <input class="form-control" type="text" #emailId="ngModel"
                  [(ngModel)]="obj.first" name="first{{i}}" placeholder="First Name">
                  </div>
                  <div class="form-group col-md-2">
                    <input class="form-control" type="text" #emailId="ngModel"
                    [(ngModel)]="obj.last" name="last{{i}}" placeholder="Last Name">
                    </div>
               <div class="form-group col-md-4">
               <input class="form-control" type="text" #emailId="ngModel"
               [(ngModel)]="obj.emailId" name="email{{i}}" placeholder="Email Id">
               </div>
  
              
  
                <div class="form-group col-md-3">
                  <input type="date" #licenseDate="ngModel" name="licenseDate{{i}}" [(ngModel)]="obj.licenseDate" 
          class="form-control form-control-sm"> 
                  </div>
                <!-- remove button -->
               <div class="form-group col-md-1">
               <div class="removeBtn"><button class="btn" (click)="removeGtmRow(i)">
                 <i class="fas fa-trash-alt"></i></button></div>
               </div>
         
               </div> 
               <div class="col-md-12 btnContainer">
                 <div
                          class="" data-toggle="modal" data-target="#fineModal" (click)="addNewGtmRow()"><i
                            class="fas fa-plus text-success"></i></div>
                  </div>
                </div>
                <div class="col-md-12" [hidden]="!schoolInfo.team">
                  <div class="row" style="margin-bottom: 10px;text-align: left;">
                    <div class="col-md-4"><strong>Tenant Id</strong></div>
                    <div class="col-md-4"><strong>Client Id</strong></div>
                    <div class="col-md-4"><strong>Client Secret</strong></div>            
                    
                   </div>
             
                  <div class="row" >
                  
                    <div class="form-group col-md-4">
                      <input type="text" #tenant="ngModel" name="tenantId" [(ngModel)]="schoolInfo['teamInfo']['tenantId']"
                        class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-md-4">
                      <input type="text" #clientId="ngModel" name="clientId" [(ngModel)]="schoolInfo['teamInfo']['clientId']"
                        class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-md-4">
                      <input type="text" #clientSecret="ngModel" name="ClientSecret" [(ngModel)]="schoolInfo['teamInfo']['clientSecret']"
                        class="form-control form-control-sm">
                    </div>
             
                   </div> 
                
                    </div>
                    <div class="col-md-12" [hidden]="!schoolInfo.impartus">
                      <div class="row" style="margin-bottom: 10px;text-align: left;">
                        <div class="col-md-4"><strong>Licence Count</strong></div>
                        <div class="col-md-4"><strong>Left Licence Count</strong></div>
                                    
                        
                       </div>
                 
                      <div class="row" >
                      
                        <input class="form-control col-md-4" type="number" #emailId="ngModel"
                        [(ngModel)]="schoolInfo.impartusLicenceCount" name="impartusLicenceCount" placeholder="Licence Count">
                        <input class="form-control col-md-4" type="number" #emailId="ngModel"
                        [(ngModel)]="schoolInfo.leftImpartusLicence" name="leftImpartusLicence" placeholder="Available Licence Count">
                 
                       </div> 
                    
                        </div>
                <!-- Submit Buttons -->
               <div class="form-group col-md-12" style="text-align: center; padding: 10px">
                  <div class="form-group col-md-3">
                    <button type="button" class="btn btn-primary" (click)="saveInfo()"><i class="fas fa-save"> </i> Save</button>
                  </div>
               </div>
        </form>

      </div>


    
    </div>
  </div>