<div class="container">
  <!-- Breadcrumb step -->
  <div class="row">
    <h3 class="title my-3">Let's Create Question Set</h3>
    <div class="col-12 col-md-12 parent my-3 d-flex">
      <div class="child1 col-2 col-md-2">
        <span class="rounded-circle rcircle p-3 pb-2 pt-2" id="activecircle"
          >1</span
        >
        <label>Create Exam</label>
      </div>
      <div class="child1 col-1 col-md-1">
        <hr class="between" />
      </div>
      <div class="child1 col-2 col-md-2">
        <span class="rounded-circle rcircle p-3 pb-2 pt-2">2</span>
        <label>Choose Subject</label>
      </div>
      <div class="child1 col-1 col-md-1">
        <hr class="between" />
      </div>
      <div class="child1 col-2 col-md-2">
        <span class="rounded-circle rcircle p-3 pb-2 pt-2">2</span>
        <label>Select Questions</label>
      </div>
      <div class="child1 col-1 col-md-1">
        <hr class="between" />
      </div>
      <div class="child1 col-3 col-md-3">
        <span class="rounded-circle rcircle p-3 pb-2 pt-2">3</span>
        <label>Create Question Set</label>
      </div>
    </div>
  </div>

  <!-- <div class="row my-3"> -->
  <form class="row my-3">
    <div class="form-group col-md-3">
      <label for="inputState" class="form-label">Exam Name</label>
      <input
        #examname_var="ngModel"
        name="examName"
        type="text"
        placeholder="eg. Unit Test"
        [(ngModel)]="searchObj.examName"
        class="form-control"
      />
    </div>

    <div class="form-group col-md-3">
      <label for="inputState" class="form-label">Maximum Marks</label>
      <input
        #examname_var="ngModel"
        name="examName"
        type="number"
        placeholder="eg. 100"
        [(ngModel)]="searchObj.maxMarks"
        class="form-control"
      />
    </div>

    <div class="form-group col-md-3">
      <label for="inputState" class="form-label">Duration (in hour)</label>
      <input
        #examname_var="ngModel"
        name="examName"
        type="number"
        placeholder="eg. 2.5"
        [(ngModel)]="searchObj.maxHours"
        class="form-control"
      />
    </div>

    <button
      type="button"
      class="btn col-md-1 btn-sm"
      id="sbbtn"
      (click)="goToChooseSubject()"
    >
      &#8594;
    </button>
  </form>
  <div id="foo">
    <span style="color: red">Note: </span
    ><span
      >Instructions for the students to be added to the question paper can be
      done at the final step.</span
    >
  </div>
</div>
