import { Component, OnInit, Input } from '@angular/core';
import { AllApisService } from 'src/app/all-apis.service';




@Component({
  selector: 'app-navigation-panel-admin',
  templateUrl: './navigation-panel-admin.component.html',
  styleUrls: ['./navigation-panel-admin.component.css']
})
export class NavigationPanelAdminComponent implements OnInit {

  @Input() val:string;
  loginuserName: any;
  impartus: any;
  team: any;
  constructor(private allApiServ:AllApisService) { }

  ngOnInit() {
    this.loginuserName = this.allApiServ.logedInUserName()
    let schoolInfo = this.allApiServ.getSchoolInfo()
    if(schoolInfo) {
      this.impartus = schoolInfo.impartus;
      this.team = schoolInfo.team;
    } else {
      this.impartus = false;
      this.team = false;
    }
   console.log(this.val)
   $('.activeIcon1').css('display','none');
   $('.activeIcon2').css('display','none');
   $('.activeIcon3').css('display','none');
   $('.activeIcon4').css('display','none');
   $('.activeIcon5').css('display','none');
   $('.activeIcon6').css('display','none');
   $('.activeIcon7').css('display','none');
    
    if(this.val=="assignSubject"){
      $('.br-sideleft-menu a:nth-child(2)').addClass('active');
      $('.deactiveIcon1').css('display','none');
      $('.activeIcon1').css('display','block'); 
    }
    if(this.val=="webinarSetting"){
      $('.br-sideleft-menu a:nth-child(1)').addClass('active');  
      $('.deactiveIcon2').css('display','none');
      $('.activeIcon2').css('display','block');
    }
    
    if(this.val=="studioConfigure"){
      console.log('stuuuuu')
      $('.br-sideleft-menu a:nth-child(3)').addClass('active');
      $('.deactiveIcon3').css('display','none');
      $('.activeIcon3').css('display','block'); 
    }
    if(this.val=="cambridgeStudent"){
      
      $('.br-sideleft-menu a:nth-child(4)').addClass('active');
      $('.deactiveIcon4').css('display','none');
      $('.activeIcon4').css('display','block'); 
    }
    if(this.val=="teacherCredentials"){
      $('.br-sideleft-menu a:nth-child(5)').addClass('active');
      $('.deactiveIcon5').css('display','none');
      $('.activeIcon5').css('display','block'); 
    }
    if(this.val=="studentCredentials"){
      // console.log('stuuuuu')
      $('.br-sideleft-menu a:nth-child(6)').addClass('active');
      $('.deactiveIcon6').css('display','none');
      $('.activeIcon6').css('display','block'); 
    }
    if(this.val=="impartusLicence"){
      $('.br-sideleft-menu a:nth-child(7)').addClass('active');
      $('.deactiveIcon7').css('display','none');
      $('.activeIcon7').css('display','block'); 
    }
    $('#btnLeftMenuMobile').on('click', function () {
      $('body').addClass('show-left');
      return false;
    });
    $(document).on('click', function (e) {
      e.stopPropagation();
      // closing left sidebar
      if ($('body').hasClass('show-left')) {
        var targ = $(e.target).closest('.br-sideleft').length;
        if (!targ) {
          $('body').removeClass('show-left');
        }
      }
    });
  }

  logOut(){
    this.allApiServ.logoutUser();
    
  }
}