import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { TeacherMapService } from '../zoom/teacher-subject/teacher-map.service';
import { ZoomService } from '../zoom/zoom.service';
import { last } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AllApisService } from '../all-apis.service';
import {ConfirmDialogService} from '../shared/confirm-dialog/confirm-dialog.service'
declare var $:any;

@Component({
  selector: 'app-teacher-credentials',
  templateUrl: './teacher-credentials.component.html',
  styleUrls: ['./teacher-credentials.component.css']
})
export class TeacherCredentialsComponent implements OnInit {

  teacherSubList :teacherSub[]=[];
  teacherList = [];
  teacherListEmail = [];
  
  dropdownSettings = {
    singleSelection: true,
    idField: 'item_id',
    textField: 'item_text',
    allowSearchFilter: true
  };
  
  displayList=[];
  showLoader: boolean = false;
  schoolInfo:any;
  team=false;
  constructor(private serv: TeacherMapService, private zoomServ: ZoomService, public notifier: NotifierService, private router: Router, private allApiService:AllApisService, private confirmBox:ConfirmDialogService) { }

  ngOnInit() {
    this.getTeacherList();
    
    
    this.getTeacherSubjectList();
    if(typeof localStorage.getItem('schoolInfo')!==undefined && localStorage.getItem('schoolInfo')!==""){
      this.schoolInfo=JSON.parse(localStorage.getItem('schoolInfo'));
      if(this.schoolInfo.team){
        this.team=true;
        
      } 
    }
  }


  getTeacherList() {
    this.zoomServ.getTeachers()
      .subscribe(res => {
        this.teacherList = [];
        this.teacherListEmail = res.teacherList;
        var arr = res.teacherList;
        arr.forEach(ele => {
          this.teacherList.push({ item_id: ele.id, item_text: ele.name });
        })
        console.log("AAAA", arr);
      })
  }



  

  updateEventTeacher(teacher: any, index: any) {
    console.log('seatcher changed', teacher);
    var arr = this.teacherListEmail;
    arr.forEach(ele => {
      if (teacher.item_id == ele.id) {
        this.teacherSubList[index]["teacherId"] = ele.id;
        this.teacherSubList[index]["teacherName"] = ele.name;

      }
    })
    console.log("aaaa", this.teacherSubList[index]);
  }



  


  addNewRow() {
    if (!this.teacherSubList.length) {
      this.teacherSubList.push(new teacherSub());
      return;
    }
    let lastObj = this.teacherSubList[this.teacherSubList.length - 1]
    console.log(lastObj);
    if (lastObj.teacherId && lastObj.teamUsername && lastObj.teamPassword && lastObj.teamUsername!=='' && lastObj.teamPassword!=='') {
      this.teacherSubList.push(new teacherSub());
    } else {
      this.notifier.notify("error", "Empty fields not allowed")
    }

    //console.log(this.standardSectionArr);
    // console.log(st);
  }

  removeRow(index) {
    let newArr = this.teacherSubList; // Imp. to refreshing
    newArr.splice(index, 1);
    this.teacherSubList = newArr;
  }

  onSubmit() {
    var self=this;
    console.log(this.teacherSubList);
    var teacherMap=[];
    this.teacherSubList.forEach(elem=>{
      var flag=true;
      if(!elem.teacherId || elem.teacherId === '' || !elem.teamUsername || elem.teamUsername === '' || !elem.teamPassword || elem.teamPassword === '') {  
        flag=false;
      } else {
        flag=true;
      }
      if(flag){
        let obj={
          teacherId:elem["teacherId"],
          teacherName:elem["teacherName"],
          teamUsername:elem["teamUsername"],
          teamPassword:elem["teamPassword"]
          
        }
        teacherMap.push(obj);
      }
    })
    console.log(teacherMap);
    this.serv.addTeacherMsDetail(teacherMap)
      .subscribe(res => {
        if (res.status) {
          console.log(res);
          this.notifier.notify("success", "Teacher information added successfully");
          this.teacherSubList = [];
        }
        else {
          this.notifier.notify("error", "Something bad happend");
        }
      }, err => {
        this.notifier.notify("error", "Something bad happend");
      })

  }


  populateTable(active:boolean) {
    let action_btn =  '<i style="padding-left:10px,position:pointer;" class="far fa-trash-alt text-danger text-info delete" title="Delete"></i>'
    let self=this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.displayList,
        columns: [
          
          { data: 'name', defaultContent: '<i>Not Set</i>' },
          { data: 'username', defaultContent: '<i>Not Set</i>' },
          { data: 'password', defaultContent: '<i>Not Set</i>' },
          // {
          //   data: null,
          //   className: 'center',
          //   defaultContent: action_btn,
            
          // } 

        ],
        // dom: 'Bfrtip',
        //  buttons: [],

        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']]
      });
      $('.example tbody ').on('click', 'tr i.delete', function () {
        console.log("delete...")
        var p = this.closest('tr');
        var data = $('.example').DataTable().row(p).index();
        self.confirmDetails(data);
     });
      
  }

  getTeacherSubjectList(){
    this.displayList=[];
    this.serv.fetchList()
    .subscribe(res=>{
      console.log('teacher list', res);
      if(res["status"] && res["result"].length){
        res["result"].forEach(elem=>{
          let obj={
            name:elem["teacherName"],
            username:elem["teamUsername"],
            password:elem["teamPassword"]
          }
          this.displayList.push(obj);
        })
        this.populateTable(true);
      }
     
    })
  }


  deleteAllotment(evt:any){
    this.serv.removeAllotment(this.displayList[evt])
    .subscribe(res=>{
      if(res["status"]){
        this.notifier.notify("success","Allotment has been deleted");
        this.displayList.splice(evt,1);
        this.populateTable(true);
      }
      else{
        this.notifier.notify("error","Something bad happened");
      }
    },err=>{
      this.notifier.notify("error","Something bad happened");
    })
  }

  confirmDetails(evt:any) {
  
    let self=this;
          this.confirmBox.confirmThis('This will delete the allotment.',  'Delete Allotment ?', function () {  
            self.deleteAllotment(evt);
          }, function () {  
          }) 
  }



  scheduledList() {
    this.router.navigate(['/webinarList']);
    return false;
  }

  moveToSessionList(){
    this.router.navigate(['/webinarList']);
  }
  // moveToAttendeeList(){
  //   this.router.navigate([this.allApiService.getRelativePath()+'/attendence']);
  // }
  moveToWebinarSetting(){
    this.router.navigate(['/admin/dashboard/admin']);
  }

  navigateToImport(){
    this.router.navigate(['/importExl/teacher']);
  }
}


export class teacherSub {
  teacherId: string;
  teacherName: string;
  class: string;
  section: Array<string>;
  subject: string;
  teamUsername:string;
  teamPassword:string

  constructor() {
    this.section = [];
    
  }

}
