<meta name="viewport" content="width=device-width, initial-scale=1" />
<div class="sbslogoContainer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <img
          src="https://storage.googleapis.com/bkt-sm-prod-lms-books/lms-ch/sm_logo.png"
          class="img-fluid sbs-center"
        />
      </div>
    </div>
  </div>
</div>

<div class="container mt-5">
  <div class="row">
    <div class="col-md-6 mt-4">
      <form
        class="mt-5"
        #signInForm="ngForm"
        (ngSubmit)="onLogin()"
        novalidate
        method="POST"
        style="padding-left: 0"
      >
        <div class="form-group">
          <label for="userName">USERNAME <sup>*</sup></label>
          <input
            id="userName"
            class="form-input form-control form-control-lg"
            type="text"
            name="email"
            #email="ngModel"
            (change)="validateInput()"
            [(ngModel)]="this.signInModel.emailId"
            placeholder="Enter Username"
            required
            [ngClass]="{
              'invalid-textbox': signInForm.submitted && !email.valid
            }"
            (key.enter)="onLogin()"
          />
        </div>
        <div *ngIf="signInForm.submitted && !validLoginId">
          <label class="validation-message">Invalid Username.</label>
        </div>
        <div class="form-group mt-4">
          <label for="password">PASSWORD<sup>*</sup></label>
          <input
            id="password"
            class="form-input form-control form-control-lg"
            type="password"
            name="password"
            #password="ngModel"
            [(ngModel)]="this.signInModel.password"
            placeholder="Enter Password"
            required
            minlength="4"
            [ngClass]="{
              'invalid-textbox': signInForm.submitted && !password.valid
            }"
            (key.enter)="onLogin()"
          />
        </div>
        <div *ngIf="signInForm.submitted && password.errors?.minlength">
          <label class="validation-message">Minimum 4 characters.</label>
        </div>
        <div class="form-group mt-3">
          <button type="submit" class="btn btn-lg loginBtn">
            <p>Login</p>
          </button>
        </div>
      </form>
    </div>
    <div class="col-md-6 mt-4">
      <img
        src="https://storage.googleapis.com/bkt-sm-prod-symbiosis/site/sign_in_page.svg"
        class="opacity-50 img-fluid"
      />
    </div>
  </div>
</div>
<page-loader *ngIf="showLoader" [loaderMsg]="loaderMessage"></page-loader>
