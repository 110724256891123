import { Component, OnInit } from '@angular/core';
import { ZoomService} from '../zoom/zoom.service';
import {NotifierService} from 'angular-notifier';
import { TeacherService } from '../teacher/teacher.service';

declare var $: any;
@Component({
  selector: 'app-monthly-attendence',
  templateUrl: './monthly-attendence.component.html',
  styleUrls: ['./monthly-attendence.component.css']
})
export class MonthlyAttendenceComponent implements OnInit {

  showLoader: boolean = false;
  seminarId : string;
  attendeeList =  []
  seminarInfo: any;
  userInfo:any;
  teacherShow:boolean = false;
  standard : any;
  section : any;
  stdList = [];
  sectionList = []
  sectionObj: any;
  dataTableColumnList = [];
  columnList = []
  headerString = '';
  month = 11;
  searchObj = new search();
  gradeList = [];
  classList = [];
  constructor( private serv: ZoomService, private notifier : NotifierService, private teacherServ: TeacherService) { }

  ngOnInit() {

    if (typeof localStorage.getItem('userDetail') !== undefined && localStorage.getItem('userDetail') !== "") {
      this.userInfo = JSON.parse(localStorage.getItem('userDetail'));
      if (this.userInfo.roles[0] === 'Teacher') {
       this.teacherShow = true;
      }
    }
    this.fetchGrades();
  }



  populateTable() {
    let action_btn =  '</i><i style="padding-left:10px;" class="far fa-trash-alt delete text-danger" title="Delete"></i>'
    let self=this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.attendeeList,
        columns: this.dataTableColumnList,
        dom: 'Bfrtip',
        buttons: [
          {
            extend: 'excelHtml5',

            title: 'Monthly Attendence',
            message: self.headerString


        }
        
        ],
         

        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']]
      });
      
  }


  getMonthlyAttendence(){
    this.showLoader = true;
    this.serv.getMonthlyAttendence({std : this.standard, section : this.section, month : this.month})
    .subscribe(res=>{
      console.log("res",res);
      if(res['status']){
        this.attendeeList=res['result']
        this.dataTableColumnList = res['dataColumn'],
        this.headerString = res['header']
        this.populateTable();
      }
      else this.notifier.notify('error', 'Something bad happened');
      this.showLoader = false;
      
    })

  }


  fetchGrades() {
    let role = localStorage.getItem('role')
    if (!this.teacherServ.teacherSubList || role != 'Teacher') {
      this.showLoader = true;
      
      this.teacherServ.getSubjectList()
        .subscribe(res => {
          this.showLoader = false;
          console.log('grade', res)
          if (res['status']) {
            this.teacherServ.teacherSubList = res['result'];
            this.gradeList = this.teacherServ.teacherSubList;
            this.classList = this.teacherServ.teacherSubList.map(a => a.class);
            this.updateSectionSubject();
          }
        })
    } else {
      this.gradeList = this.teacherServ.teacherSubList;
      this.classList = this.teacherServ.teacherSubList.map(a => a.class);
      this.updateSectionSubject();
    }

  }

  updateSectionSubject() {
    
    let currStd = this.classList[0]
    if (this.searchObj.standard) {
      currStd = this.searchObj.standard;
    }
    this.classList = [...new Set(this.classList)];
    console.log('this.classlist', this.classList)
    let sections = this.gradeList.filter(sl => sl.class == currStd).map(e => e.section);
    this.sectionList = [...new Set([].concat(...sections))]
    let currSec = this.sectionList[0]
    if (this.searchObj.section) {
      currSec = this.searchObj.section
    }
    
  }
}

export class search {
  standard: string;
  section: string;
  subject: string;
  lessonId: string;
  week: number;
}