import { Component, OnInit } from '@angular/core';
import { WindowRefService } from '../../payment/window-ref.service';
import { PaymentService } from '../../payment/payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/authentication/services/user.service';
import { AllApisService } from 'src/app/all-apis.service';
import { NotifierService } from 'angular-notifier';
import { HttpHeaders } from '@angular/common/http';

declare var $: any;
@Component({
  selector: 'app-online-payment',
  templateUrl: './online-payment.component.html',
  styleUrls: ['./online-payment.component.scss']
})
export class OnlinePaymentComponent implements OnInit {
  validLoginId: boolean;
  showLoader: boolean = true;
  schoolInfo: any;
  loaderMessage: string = '';
  batchInfo: any;
  onSuccess: boolean = false;

  fromDashboard = false;
  htmlBlock = '.br-pagebody';
  paymentId: any;
  merchantName: any;
  merchantId: any;
  amount: any;
  rewardCount: any;
  cashback: any;
  scratchCard: any;
  showImgLoader: boolean = true;

  constructor(private winRef: WindowRefService, 
    private paymentService: PaymentService, 
    private router: Router, private activeRoute: ActivatedRoute, 
    private userService: UserService,
    private allApies: AllApisService,
    private notifier: NotifierService) { }   //, private global:OneStorage


  serverErrorMessages: string;
  //server Data
  sData: Object;
  role: string;
  path:string;
  ngOnInit() {
    this.showImgLoader = true;
    console.log("this.activeRoute.queryParams", this.activeRoute.queryParams)
    if (this.activeRoute.queryParams) {
      this.activeRoute.queryParams.subscribe(params => {
        this.loaderMessage = "Verifying payment details..";
        if (params.batchId && params.studentId && params.paymentId) {
          let obj = {}
          obj['batchId'] = params.batchId;
          obj['studentId'] = params.studentId;
          obj['paymentId'] = params.paymentId;
          this.paymentService.confirmPayment(obj).subscribe(res => {
            if(res.status) {
              this.showImgLoader = false;
              if(params.success) {
                this.showLoader = false;
                $("#successModal").modal('show');
                this.merchantName = params.merchantName;
                this.merchantId = params.merchantId;
                this.amount = params.amount;
                this.paymentId = params.paymentId;
                this.rewardCount = params.rewardCount;
                this.cashback = params.cashback;
                this.scratchCard = params.scratchCard;
                //window.Android.finishTask(true, "Unable to make payment now.");
              } 
            } else {
              $("#errorModal").modal('show');
            }
          })
        } else {
          this.showLoader = false;
          let batchId = localStorage.getItem("batchId");
          console.log("batch id from local", batchId)
          this.batchPayment({batchId: batchId});
        }
      });
    } else {
      this.showLoader = false;
      let batchId = localStorage.getItem("batchId");
      console.log("batch id from local", batchId)
      this.batchPayment({batchId: batchId});
    }
  }

  callAfterSignIn(params) {
    this.loaderMessage = "Fetching Payment Details..";
    if(params.batchId) {
      console.log('payment started')
      this.batchPayment({batchId: params.batchId});
    } else {
      this.showImgLoader = false;
      if(params.success) {
        $("#successModal").modal('show');
        this.merchantName = params.merchantName;
        this.merchantId = params.merchantId;
        this.amount = params.amount;
        this.paymentId = params.paymentId;
        this.rewardCount = params.rewardCount;
        this.cashback = params.cashback;
        this.scratchCard = params.scratchCard;
        //window.Android.finishTask(true, "Unable to make payment now.");
      } else if(params.error) {
        $("#errorModal").modal('show');
        //window.Android.finishTask(true, "Unable to make payment now.");
      }
    }
  }

  batchPayment(obj: any) {
    console.log('obj aya', obj);
    this.paymentService.batchPayment(obj).subscribe((response) => {
      this.showImgLoader = false;
      console.log("response.status", response)
      if(response.status && response.options) {
        console.log('res.options', response.options);
        this.showLoader = false;
        this.payWithRazor(response.options);
      } else if(response.status && response.paymentDone) {
        this.showLoader = false;
        $("#successModal").modal('show');
      } else {
        this.showLoader = false;
        var obj = {}
        obj['status'] = false
        obj['message'] = "Failed"
        console.log('json objec', JSON.stringify(obj));
        if(window.webkit && window.webkit.messageHandlers) {
          window.webkit.messageHandlers.errorViewCallBack.postMessage(JSON.stringify(obj));
        }
        if(window.Android) {
          window.Android.finishTask(JSON.stringify(obj));
        }
      }
      },
      error => {
        console.log('Error', error);
        this.notifier.notify("error", 'Unable to fetch Payent Details.');
        this.showLoader = false;
        this.finishPayment();
      });
  }

  createPayment(payObj: any) {
    console.log('pay obj ', payObj)
    this.paymentService.createPayment(payObj).subscribe((response) => {
      if(response.status && response.options) {
        console.log('res.options', response.options);
        this.payWithRazor(response.options);
      } else {
        var obj = {}
        obj['status'] = false
        obj['message'] = "Failed"
        console.log('json objec', JSON.stringify(obj));
        if(window.webkit && window.webkit.messageHandlers) {
          window.webkit.messageHandlers.errorViewCallBack.postMessage(JSON.stringify(obj));
        }
        if(window.Android) {
          window.Android.finishTask(JSON.stringify(obj));
        }
      }
      },
      error => {
        console.log('Error', error);

      });
  }

  payWithRazor(optionVal) {
    const options: any = optionVal;
    options.handler = ((response, error) => {
      options.response = response;
      console.log('success payment', response);
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
      $("#errorModal").modal('show');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  paymentSuccess() {
    var obj = {}
    obj['merchantName'] = this.merchantName;
    obj['merchantId'] = this.merchantId;
    obj['amount'] = this.amount;
    obj['paymentId'] = this.paymentId;
    obj['rewardCount'] = this.rewardCount;
    obj['cashback'] = this.cashback;
    obj['scratchCard'] = this.scratchCard;
    var resObj = {}
    resObj['status'] = true
    resObj['message'] = "Success"
    resObj['result'] = obj
    console.log('json objec', JSON.stringify(resObj));
    $("#successModal").modal('hide');
    $("#errorModal").modal('hide');
    this.router.navigate(['/enrolledCourses']);
    // if(window.webkit && window.webkit.messageHandlers) {
    //   window.webkit.messageHandlers.webViewCallBack.postMessage(JSON.stringify(resObj));
    // } else if(window.Android) {
    //   window.Android.finishTask(JSON.stringify(resObj));
    // }
  }
  
  paymentFailed() {
    var resObj = {}
    resObj['status'] = false
    resObj['message'] = "Exit"
    console.log('json objec', JSON.stringify(resObj));
    // if(window.webkit && window.webkit.messageHandlers) {
    //   window.webkit.messageHandlers.errorViewCallBack.postMessage(JSON.stringify(resObj));
    // } else if(window.Android) {
    //   window.Android.finishTask(JSON.stringify(resObj));
    // } 
    $("#successModal").modal('hide');
    $("#errorModal").modal('hide');
    this.router.navigate(['/enrolledCourses']);
  }

  finishPayment() {
    var resObj = {}
    resObj['status'] = true
    resObj['message'] = "Exit"
    $("#successModal").modal('hide');
    $("#errorModal").modal('hide');
    console.log('json objec', JSON.stringify(resObj));
    if(window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.exitViewCallBack.postMessage(JSON.stringify(resObj));
    } else if(window.Android) {
      window.Android.finishTask(JSON.stringify(resObj));
    }
  }
}

