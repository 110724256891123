import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { AllApisService } from '../all-apis.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TeacherMapService } from '../zoom/teacher-subject/teacher-map.service';
import {SchoolServiceService} from '../school-info/school-service.service';

declare var $: any;


@Component({
  selector: 'app-student-impartus-map',
  templateUrl: './student-impartus-map.component.html',
  styleUrls: ['./student-impartus-map.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StudentImpartusMapComponent implements OnInit {

  constructor(public route: ActivatedRoute, public router: Router, private _location: Location, private allApiService: AllApisService, public notifier: NotifierService, private teacherServ: TeacherMapService, private serv:SchoolServiceService) { }
  schoolName: any;
  standard: any;
  board: any;
  enrollList: any;
  column= [];
  stdList = [];
  boardList = [];
  sectionObj = {};
  searchById: any;
  smsText: any;
  idArray = [];
  userIds = [];
  sectionList = [];
  selectedSection = [];
  selectedStudent = [];
  dropdownSettingsSection = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  allStudentList: Array<any> = [];
  studentList = [];
  showSelection = false;
  showButtons = false;
  ngOnInit() {
    if (typeof localStorage.getItem('schoolInfo') !== undefined && localStorage.getItem('schoolInfo') !== "") {
      this.schoolName = this.allApiService.getSchoolInfo().schoolName;
      this.fetchClassSection();
    }

    // this.fetchClassSection();
    this.column= [
      { data: 'sourceId', defaultContent: '<i>Not Set</i>', title: '<input name="select_all" value="sourceId" id="example-select-all" type="checkbox">' },
      { data: 'firstName', defaultContent: '<i>Not Set</i>', title: 'Student Name' },
      { data: 'standard', defaultContent: '<i>Not Set</i>',  title: 'Class' },
      { data: 'section', defaultContent: '<i>Not Set</i>', title: 'Section' },
      { data: 'rollNo', defaultContent: '<i>Not Set</i>', title: 'Roll No' },
      { data: 'impartus', defaultContent: '<i>Not Set</i>', title: 'Licence' }
    ]

  }


  fetchClassSection() {
    this.teacherServ.getClassSection()
      .subscribe(res => {
        console.log("class", res);
        var check = {};
        res.standardList.forEach(elem => {
          if (typeof check[elem.standard] === 'undefined') {
            this.stdList.push(elem.standard);
            check[elem.standard] = true;
          }
        })
        // this.stdList = res.standardList;
        this.sectionObj = res.sectionListObj;

      })
  }

  updateSectionList() {
    this.sectionList = this.sectionObj[this.standard];
  }

  updateEventSection(section: any) {
    this.selectedSection.push(section);
  }

  onSelectAllSection(evt: any) {
    console.log('section changed', evt);
    this.selectedSection = evt;
  }

  deleteSection(section: any) {
    console.log('section changed', section);
    for (var i = 0; i < this.selectedSection.length; i++) {
      if (this.selectedSection[i] === section) {
        this.selectedSection.splice(i, 1);
        break;
      }
    }
    this.studentList = []
    if (this.selectedSection && this.selectedSection.length && this.standard) {
      this.studentList = this.allStudentList.filter(c => (c['standard'] == this.standard && this.selectedSection.includes(c['section'])))
    }


  }

  //get list of all student of school
  getStudentList() {
    this.studentList = [];
    this.teacherServ.getStudents(this.standard, this.selectedSection)
      .subscribe(res => {
        console.log('res', res)
        if (res['status']) {
          this.allStudentList = res['studentList'];

          this.allStudentList.forEach(stu => {
            stu['display_text'] = `${stu['firstName']} ${stu['lastName']} (${stu['standard']}-${stu['section']})`;
            //this.studentList=this.studentList.concat({ item_id: stu['sourceId'], item_text: `${stu['firstName']} ${stu['lastName']} (${stu['standard']}-${stu['section']})`});
          })
          this.studentList = this.allStudentList;
          // console.log('aya', this.studentList);
          // this.column = res.column;
          this.getImpartusEnabledStudentList(this.standard,this.selectedSection)
          
        }
      })
    console.log('aya 2', this.studentList);
  }

  getImpartusEnabledStudentList(standard, section) {
    var idList=[];
    this.teacherServ.getStudentsFromStudentInfo(standard, section)
      .subscribe(res => {
        console.log('res', res)
        if (res['status']) {
          idList = res['result'];
          this.studentList.forEach(elem=>{
            if(idList.includes(elem.sourceId)){
              elem['impartus']='Enabled'
            }
            else elem['impartus']='Not Enabled'
          })
          console.log(this.studentList)
          this.populateTable(true);
          this.showSelection=true;
        }
      })
    
  }
  
  populateTable(active: boolean) {
    let that = this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: that.studentList,
        columns: that.column,
        scrollX: true,

        'columnDefs': [{
          'targets': 0,
          'searchable': false,
          'orderable': false,
          'className': 'dt-body-center',
          'render': function (data, type, full, meta) {
            var disabled = ''
            if (that.studentList.length > 0) {
              const student = that.studentList.find(stu => stu.sourceId === data);
              // if (student && student.disabled) {
              //   disabled = 'disabled'
              // } else {
              //   disabled = ''
              // }
              console.log(student);
            }
            return '<input type="checkbox" name="id[]"' + disabled + ' value="'
              + $('<div/>').text(data).html() + '">';

          }
        }],
        'order': [1, 'asc'],

        dom: 'Bfrtip',
        buttons: ['pageLength', 'copy', 'csv', 'excel'],
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, 'All']]
      });
    // $('.example tbody').on('click', 'tr i.send', function () {
    //    console.log("clicked............");
    //   var p = this.closest('tr');
    //   var data = $('.example').DataTable().row(p).index();
    //   that.sendSMSID(that.enrollList[data].id);
    // });
    // Handle click on "Select all" control
    $('#example-select-all').on('click', function () {
      console.log("selectAll")
      // Check/uncheck all checkboxes in the table
      var rows = example.rows({ 'search': 'applied' }).nodes();
      $('input[type="checkbox"]:not(:disabled)', rows).prop('checked', this.checked);
    });
    // Handle click on checkbox to set state of "Select all" control
    $('#example tbody').on('change', 'input[type="checkbox"]', function () {

      // If checkbox is not checked
      if (!this.checked) {
        var el = $('#example-select-all').get(0);
        // If "Select all" control is checked and has 'indeterminate' property
        if (el && el.checked && ('indeterminate' in el)) {
          // Set visual state of "Select all" control 
          // as 'indeterminate'
          el.indeterminate = true;
        }
      }

    });
    $('#frm-example').on('submit', function (e) {
      var form = this;

      // Iterate over all checkboxes in the table
      example.$('input[type="checkbox"]').each(function () {
        // If checkbox doesn't exist in DOM
        if (!$.contains(document, this)) {
          // If checkbox is checked
          if (this.checked && !this.disabled) {
            // Create a hidden element 
            $(form).append(
              $('<input>')
                .attr('type', 'hidden')
                .val(this.value),

            );
          }
        }
      });

      var tsetdta = $(form).serialize();
      console.log("testta", tsetdta);
      var test = tsetdta.toString().split("&id%5B%5D=").join("-").split("select_all=id").join("").split("id%5B%5D=").join("");

      var abc = test.split("-");
      that.idArray = abc;
      console.log("this.idArray", that.idArray);
      e.preventDefault();
    });

    $('#frm-example').on('submit', function (e) {
      var form = this;

      // Iterate over all checkboxes in the table
      example.$('input[type="checkbox"]').each(function () {
        // If checkbox doesn't exist in DOM
        if (!$.contains(document, this)) {
          // If checkbox is checked
          if (this.checked && !this.disabled) {
            // Create a hidden element 
            $(form).append(
              $('<input>')
                .attr('type', 'hidden')
                .val(this.value),

            );
          }
        }
      });

      var tsetdta = $(form).serialize();
      // console.log("testta", tsetdta);
      var test = tsetdta.toString().split("&id%5B%5D=").join("-").split("select_all=id").join("").split("id%5B%5D=").join("");

      var abc = test.split("-");
      that.idArray = abc;
      console.log("this.idArray", that.idArray);
      e.preventDefault();
      that.showButtons=true;
    });
  }

  addUser(){
    var self=this;
    var testIdList=this.idArray.map(ele=>parseInt(ele))
    var requiredStudentList=this.studentList.filter(ele=>testIdList.includes(ele.sourceId));

    console.log(requiredStudentList);
    this.serv.addImpartusUser(requiredStudentList)
    .subscribe(res=>{
      if(res['status']){
        this.notifier.notify('success','User added successfully');
      }
      else{
        this.notifier.notify('error','Something bad happened');
      }
    })
  }

  deleteUser(){
    var requiredStudentList=this.idArray.filter(ele=>ele !== 'select_all=sourceId');
    console.log(requiredStudentList);
    this.serv.deleteImpartusUser(requiredStudentList)
    .subscribe(res=>{
      if(res['status']){
        this.notifier.notify('success','User deleted successfully');
      }
      else{
        this.notifier.notify('error','Something bad happened');
      }
    })
  }


  backClicked() {
    this._location.back();
  }

}

