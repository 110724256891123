<div class="container">
  <div
    class="row finalList my-2"
    *ngIf="
      searchObj && searchObj.questionGroups && searchObj.questionGroups.length
    "
  >
    <div class="col-12 col-md-12">
      <h2 class="text-center font-weight-bold">Review And Publish Paper</h2>
    </div>
    <h5 *ngIf="searchObj.instructions && searchObj.instructions != ''">
      Instructions :
    </h5>
    <span
      *ngIf="searchObj.instructions && searchObj.instructions != ''"
      [innerText]="searchObj.instructions"
    >
    </span>
    <span style="float: left">
      <button type="button " class="btn sbbtn" (click)="editQuestion()">
        Edit Question Paper
      </button>
    </span>
    <div *ngFor="let grp of searchObj.questionGroups">
      <h5
        class="my-3"
        *ngIf="grp && grp.questionList && grp.questionList.length"
      >
        {{ grp?.groupTitleLeft
        }}<span style="float: right; font-size: 18px"
          >{{ grp?.groupTitleRight }}
          <strong>Total Marks - {{ grp.groupTotal }}</strong></span
        >
      </h5>
      <ul
        style="list-style: none"
        *ngIf="grp && grp.questionList && grp.questionList.length"
      >
        <li *ngFor="let ques of grp.questionList; let i = index">
          <span style="float: right; padding-right: 0px">
            <label class="fw-bold">Mark - {{ ques.marks }}</label>
          </span>
          <span class="fw-bold">Q.{{ i + 1 }} </span>-
          <span [innerText]="ques.questionText"></span>
          <br />
          <img
            *ngIf="ques.isImage && ques.imageUrl && ques.imageUrl != ''"
            [src]="ques.imageUrl"
          />

          <br *ngIf="ques.isImage && ques.imageUrl && ques.imageUrl != ''" />

          <ol type="A" style="margin-left: 20px">
            <li *ngFor="let option of ques.options">
              <img
                *ngIf="option.optionText.includes('http')"
                [src]="option.optionText"
              />
              <label *ngIf="!option.optionText.includes('http')">{{
                option.optionText
              }}</label>
            </li>
          </ol>
        </li>
      </ul>
    </div>
    <button
      type="button "
      id="sbbtn"
      class="btn mx-auto sbbtn"
      (click)="editQuestion()"
    >
      Edit Question Paper
    </button>
    <button
      style="margin-left: 20px"
      type="button "
      class="btn mx-auto"
      id="remove"
      (click)="openAddInstruction()"
    >
      Add/Edit Instructions
    </button>
    <button
      type="button "
      class="btn mx-auto sbbtn"
      id="sbbtn"
      (click)="checkQuestionTotal()"
    >
      Create Question Paper
    </button>
  </div>
</div>

<div class="modal fade" id="successModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Success</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="goBack()"
        >
          &times;
        </button>
      </div>

      <div class="modal-body">Question Paper Created 👍</div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          id="goback"
          (click)="goBack()"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-success"
          id="proceed"
          (click)="downloadApplication()"
        >
          Download
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Overflow Modal -->
<div
  class="modal fade"
  id="overflowModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header d-block mb-3">
        <h5 class="modal-title text-center" id="overflowModalLabel">
          Maximum marks where ({{ searchObj.maxMarks }}) but current total is
          ({{ searchObj.marksAlloted }})
        </h5>
      </div>

      <div class="modal-body mx-auto mb-5">
        <button type="button" class="btn" id="goback" (click)="editQuestion()">
          Remove Questions
        </button>
        <button
          type="button"
          class="btn"
          id="proceed"
          (click)="saveExamPaper()"
        >
          Proceed
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="addinsturction"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addinsturction"
  data-keyboard="false"
  data-backdrop="static"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Add Instructions Detail
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="cancelInstructionDetail()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-10 col-12">
          <div class="col-md-10 col-12 mb-2">
            <h6 class="mt-2">Instructions</h6>
            <textarea
              rows="10"
              cols="100"
              type="text"
              [(ngModel)]="searchObj.instructions"
              placeholder="Instructions"
              class="form-control form-control-sm"
              name="instructions"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="saveInstruction()"
          >
            Add/Edit Instructions
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
