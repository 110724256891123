import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AllApisService } from "../../all-apis.service";

@Injectable({
  providedIn: "root",
})
export class QuestionListService {
  baseurl = this.allApisService.baseurl + "/questions";
  searchObj = new searchKeys();
  constructor(
    private http: HttpClient,
    private allApisService: AllApisService
  ) {}

  getQuestions(obj: any) {
    return this.http.post<any>(
      this.baseurl + "/get",
      obj,
      this.allApisService.httpOptions
    );
  }
  getQuestionKeys(obj = null) {
    let params = new HttpParams();
    if (obj && obj["series"]) {
      params = params.append("series", obj["series"]);
    }
    if (obj && obj["subject"]) {
      params = params.append("subject", obj["subject"]);
    }
    if (obj && obj["std"]) {
      params = params.append("std", obj["std"]);
    }
    if (obj && obj["chapterNo"]) {
      params = params.append("chapterNo", obj["chapterNo"]);
    }

    this.allApisService.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/getQuestionKey",
      this.allApisService.httpOptions
    );
  }
  getAllPapers() {
    let userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let params = new HttpParams();
    params = params.append("userId", userDetail.sourceId);
    params = params.append("userRole", userDetail.roles[0]);
    params = params.append("schoolId", userDetail.smSchoolId);
    this.allApisService.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/allPaper",
      this.allApisService.httpOptions
    );
  }

  deletePaper(paperId: any) {
    let params = new HttpParams();
    params = params.append("paperId", paperId);
    this.allApisService.httpOptions.params = params;
    return this.http.delete<any>(
      this.baseurl + "/deletePaper",
      this.allApisService.httpOptions
    );
  }
  getClasses() {
    return this.http.get<any>(
      this.baseurl + "/getClass",
      this.allApisService.httpOptions
    );
  }
  saveExamPaper(reqObj) {
    let userDetail = JSON.parse(localStorage.getItem("userDetail"));
    let schoolInfo = JSON.parse(localStorage.getItem("schoolInfo"));
    if (userDetail) {
      reqObj["userId"] = userDetail.sourceId;
      reqObj["userRole"] = userDetail.roles[0];
      reqObj["schoolId"] = userDetail.smSchoolId;
    }
    if (schoolInfo) {
      reqObj["schoolName"] = schoolInfo.schoolName;
    } else {
      reqObj["schoolName"] = "Test";
    }
    console.log("reqObj from paper", reqObj);
    return this.http.post<any>(
      this.baseurl + "/saveExamPaper",
      reqObj,
      this.allApisService.httpOptions
    );
  }
  getSubject() {
    return this.http.get<any>(
      this.baseurl + "/getSubject",
      this.allApisService.httpOptions
    );
  }
  updateEditLesson(obj) {
    return this.http.patch<any>(
      this.baseurl + "/editLesson",
      obj,
      this.allApisService.httpOptions
    );
  }
  updateEditContent(obj) {
    return this.http.patch<any>(
      this.baseurl + "/editContent",
      obj,
      this.allApisService.httpOptions
    );
  }

  updateEditActivity(obj) {
    return this.http.patch<any>(
      this.baseurl + "/editActivity",
      obj,
      this.allApisService.httpOptions
    );
  }

  deleteLesson(obj) {
    return this.http.post<any>(
      this.baseurl + "/deleteLesson",
      obj,
      this.allApisService.httpOptions
    );
  }
  deleteContent(obj) {
    return this.http.post<any>(
      this.baseurl + "/deleteContent",
      obj,
      this.allApisService.httpOptions
    );
  }
  deleteActivity(obj) {
    return this.http.post<any>(
      this.baseurl + "/deleteActivity",
      obj,
      this.allApisService.httpOptions
    );
  }

  updateLink(obj) {
    return this.http.patch<any>(
      this.baseurl + "/editUrl",
      obj,
      this.allApisService.httpOptions
    );
  }
  getpdVideos() {
    return this.http.get<any>(
      this.baseurl + "/pdVideos",
      this.allApisService.httpOptions
    );
  }
}

export class searchKeys {
  std: string;
  subject: string;
  category: string;
  series: string;
  chapterNo: string;
  updatedMark = 0;
  instructions: string = "";
  topic: Array<string> = [];
  examName: string;
  maxMarks: Number;
  maxHours: Number;
  totalQuestion: number;
  questionGroups = [];
  marksAlloted = 0;
}
