<div>
    <section class="content-header">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active">School Licence List</li>
      </ol>
    </section>
    <br>
  </div>
  <div class="br-pagebody" style="background-color:white;" >
  

    <div class="col-md-12 showhideTable">
      <table class="display example" cellspacing="0" width="100%">
          <thead>
            <tr>
              <th>Serial No</th>
              <th>Max Attendee</th>
              <th>Purchase Date</th>
              <th>Expiry Date</th>
            </tr>
          </thead>
        </table>
  </div> 
  </div>
  
  
  