import { Component, OnInit } from "@angular/core";
import { AllApisService } from "../all-apis.service";
import { Router } from "@angular/router";
import { ConfirmDialogService } from "../shared/confirm-dialog/confirm-dialog.service";
import { NotifierService } from "angular-notifier";
import { YtplayerService } from "../shared/ytplayer/ytplayer.service";
import {
  QuestionListService,
  searchKeys,
} from "../master-admin/question-list/question-list.service";

declare var $: any;
@Component({
  selector: "app-select-questions",
  templateUrl: "./select-questions.component.html",
  styleUrls: ["./select-questions.component.css"],
})
export class SelectQuestionsComponent implements OnInit {
  questionList = [];
  tempQuesList = [];
  questionNeList = [];
  stdList = [];
  isMcq: boolean = false;
  isSa: boolean = false;
  isLa: boolean = false;
  isFb: boolean = false;
  categoryList = [];
  seriesList = [];
  subjectList = [];
  chapterList = [];
  topicList = [];
  editId: any;
  editTitle: any;
  editSequence: any;
  searchObj = new searchKeys();
  popup = false;
  linkList: any;
  deletelesson: boolean = false;
  videoLink: any;
  safeVideo: any;
  checkLink: any;
  editLink: string;
  newLink: string;
  editIndex: any;
  lessonIndex: any;
  deleteIndex: any;
  subjectMaps: any;
  editMarkGrpInd: any;
  editMarkQuesInd: any;
  updatedMark: number = 0;
  sectionList: any[];
  allCategoryList: any;
  allSubjectList: any;
  currentQuestionList = [];
  currentMark = 1;
  currentSequence = 1;
  currQuesGroup = "";
  quesGrpTitle = "";
  quesGrpMarks = "";
  quesGrpSequence = "";
  isEdit = false;
  hideLeftNav: boolean = false;
  constructor(
    private serv: QuestionListService,
    private allApiServ: AllApisService,
    private route: Router,
    private confirmBox: ConfirmDialogService,
    private notifier: NotifierService,
    private ytServ: YtplayerService
  ) {}

  ngOnInit(): void {
    let localHideLeftNav = localStorage.getItem("hideLeftNav");
    if (localHideLeftNav && localHideLeftNav == "Y") {
      this.hideLeftNav = true;
    }
    if (
      !this.serv.searchObj ||
      !this.serv.searchObj.std ||
      !this.serv.searchObj.subject
    ) {
      let localSearchObj = localStorage.getItem("searchObj");
      if (localSearchObj && localSearchObj != "undefined") {
        this.searchObj = JSON.parse(localSearchObj);
      }
    } else {
      this.searchObj = this.serv.searchObj;
    }
    this.fetchQuestions();
    let isEditCheck = localStorage.getItem("isEdit");
    if (isEditCheck && isEditCheck == "Y") {
      this.isEdit = true;
      localStorage.removeItem("isEdit");
    }
    if (
      !this.searchObj.questionGroups ||
      !this.searchObj.questionGroups.length
    ) {
      this.addQuestionSection("Short Question Answer", "", 1);
      this.addQuestionSection("Long Question Answer", "", 1);
      this.addQuestionSection("Fill in the blanks", "", 1);
      this.addQuestionSection("Multiple Choice Question", "", 1);
    }
  }

  fetchQuestions() {
    this.serv.getQuestions(this.searchObj).subscribe((res) => {
      this.questionList = res.result;
      this.questionList.forEach((question) => {
        question["selected"] = false;
        this.searchObj.questionGroups.forEach((selectedGroup) => {
          if (
            selectedGroup &&
            selectedGroup.questionList &&
            selectedGroup.questionList.length
          ) {
            selectedGroup.questionList.forEach((selectedQuestion) => {
              if (selectedQuestion._id == question._id) {
                question["selected"] = true;
              }
            });
          }
        });
        if (question.questionType == "SA") {
          this.isSa = true;
        }
        if (question.questionType == "LA") {
          this.isLa = true;
        }
        if (question.questionType == "FB") {
          this.isFb = true;
        }
        if (question.questionType == "MCQ") {
          this.isMcq = true;
        }
      });
      this.tempQuesList = [...this.questionList];
      this.questionNeList = this.tempQuesList.sort((a, b) => {
        if (a["questionType"] == "MCQ" && b["questionType"] != "MCQ") {
          return -1;
        } else if (b["questionType"] == "MCQ" && a["questionType"] != "MCQ") {
          return 1;
        } else if (a["questionType"] == "LA" && b["questionType"] != "LA") {
          return -1;
        } else if (b["questionType"] == "LA" && a["questionType"] != "LA") {
          return 1;
        } else if (a["questionType"] == "SA" && b["questionType"] != "SA") {
          return -1;
        } else if (b["questionType"] == "SA" && a["questionType"] != "SA") {
          return 1;
        } else if (a["questionType"] == "FB" && b["questionType"] != "FB") {
          return -1;
        } else if (b["questionType"] == "FB" && a["questionType"] != "FB") {
          return 1;
        } else {
          return 0;
        }
      });
    });
  }

  markCheck(ques, eve) {
    if (eve.target.checked) {
      this.currentQuestionList.push(ques);
    } else {
      let currQues = this.currentQuestionList.filter(
        (a) => a._id == ques._id
      )[0];
      this.currentQuestionList = this.currentQuestionList.filter(function (
        obj
      ) {
        return obj._id !== ques._id;
      });
    }
  }

  addQuestionList() {
    if (!this.currentQuestionList || !this.currentQuestionList.length) {
      return false;
    }
  }

  openMaximumMarksEditor() {
    $("#overflowModal").modal("hide");
    $("#updateMaximumMarksModal").modal("show");
  }

  updateMaximumMarks() {
    if (this.searchObj.maxMarks && this.searchObj.maxMarks > 0) {
    } else {
      if (this.searchObj.maxMarks && this.searchObj.maxMarks < 0) {
        return this.notifier.notify(
          "error",
          "Maximum Marks should be a positive value."
        );
      } else {
        return this.notifier.notify("error", "Please provide Maximum Marks.");
      }
    }
    $("#updateMaximumMarksModal").modal("hide");
    if (this.searchObj.marksAlloted == this.searchObj.maxMarks) {
      $("#alertModal").modal("show");
    }
  }

  closeModel() {
    $("#overflowModal").modal("hide");
  }

  closeQuestionModel() {
    $("#questionModal").modal("hide");
  }

  goToQuestionSet() {
    $("#alertModal").modal("hide");
    if (
      this.searchObj &&
      this.searchObj.questionGroups &&
      this.searchObj.questionGroups.length
    ) {
      this.serv.searchObj = this.searchObj;
      localStorage.setItem("searchObj", JSON.stringify(this.searchObj));
      if (this.hideLeftNav) {
        this.route.navigate(["/admin/save-exam-2"]);
      } else {
        this.route.navigate(["/admin/save-exam"]);
      }
    } else {
      this.notifier.notify("error", "please add questions");
    }
  }

  addCurrentMark(event) {
    this.currentMark = event.target.value;
  }

  checkQuestionTotal() {
    if (this.searchObj.marksAlloted > this.searchObj.maxMarks) {
      $("#overflowModal").modal("show");
    }
    if (this.searchObj.marksAlloted == this.searchObj.maxMarks) {
      $("#alertModal").modal("show");
    }
  }

  addToExam() {
    this.questionList.forEach((question) => {
      this.currentQuestionList.forEach((selected) => {
        if (selected._id == question._id) {
          question["selected"] = true;
        }
      });
    });
    let grpTotal = this.currentMark * this.currentQuestionList.length;
    this.searchObj.marksAlloted += grpTotal;
    this.searchObj.totalQuestion += this.currentQuestionList.length;

    let oldGroupTotal = 0;
    let oldGroups = this.searchObj.questionGroups;
    let questionList = [];

    let quesSeq = 1;

    this.currentQuestionList.forEach((ques) => {
      if (ques.questionType == "MCQ") {
        if (oldGroups[3].questionList && oldGroups[3].questionList.length) {
          questionList = oldGroups[3].questionList;
          quesSeq =
            (questionList[questionList.length - 1]["quesSequence"] || 0) + 1;
          ques["marks"] = this.currentMark;
          ques["quesSequence"] = quesSeq;
          ques["questionSetId"] = ques._id;
          oldGroupTotal = oldGroups[3]["groupTotal"];
          oldGroups[3]["groupTotal"] =
            Number(oldGroupTotal) + Number(this.currentMark);
          oldGroups[3].questionList.push(ques);
        } else {
          let newArray = [];
          ques["marks"] = this.currentMark;
          ques["quesSequence"] = 1;
          ques["questionSetId"] = ques._id;
          oldGroups[3]["groupTotal"] = this.currentMark;
          console.log("old group total from new ", oldGroups[3]["groupTotal"]);
          newArray.push(ques);
          oldGroups[3]["questionList"] = newArray;
          oldGroups[3]["groupSequence"] = `${this.currentSequence}`;
          this.currentSequence = this.currentSequence + 1;
        }
      }
      if (ques.questionType == "FB") {
        if (oldGroups[2].questionList && oldGroups[2].questionList.length) {
          questionList = oldGroups[2].questionList;
          quesSeq =
            (questionList[questionList.length - 1]["quesSequence"] || 0) + 1;
          ques["marks"] = this.currentMark;
          ques["quesSequence"] = quesSeq;
          ques["questionSetId"] = ques._id;
          oldGroupTotal = oldGroups[2]["groupTotal"];
          oldGroups[2]["groupTotal"] =
            Number(oldGroupTotal) + Number(this.currentMark);
          oldGroups[2].questionList.push(ques);
        } else {
          let newArray = [];
          ques["marks"] = this.currentMark;
          ques["quesSequence"] = 1;
          ques["questionSetId"] = ques._id;
          oldGroups[2]["groupTotal"] = this.currentMark;
          newArray.push(ques);
          oldGroups[2]["questionList"] = newArray;
          oldGroups[2]["groupSequence"] = `${this.currentSequence}`;
          this.currentSequence = this.currentSequence + 1;
        }
      }
      if (ques.questionType == "SA") {
        if (oldGroups[0].questionList && oldGroups[0].questionList.length) {
          questionList = oldGroups[0].questionList;
          quesSeq =
            (questionList[questionList.length - 1]["quesSequence"] || 0) + 1;
          ques["marks"] = this.currentMark;
          ques["quesSequence"] = quesSeq;
          ques["questionSetId"] = ques._id;
          oldGroupTotal = oldGroups[0]["groupTotal"];
          oldGroups[0]["groupTotal"] =
            Number(oldGroupTotal) + Number(this.currentMark);
          oldGroups[0].questionList.push(ques);
        } else {
          let newArray = [];
          ques["marks"] = this.currentMark;
          ques["quesSequence"] = 1;
          ques["questionSetId"] = ques._id;
          oldGroups[0]["groupTotal"] = this.currentMark;
          newArray.push(ques);
          oldGroups[0]["questionList"] = newArray;
          oldGroups[0]["groupSequence"] = `${this.currentSequence}`;
          this.currentSequence = this.currentSequence + 1;
        }
      }
      if (ques.questionType == "LA") {
        if (oldGroups[1].questionList && oldGroups[1].questionList.length) {
          questionList = oldGroups[1].questionList;
          quesSeq =
            (questionList[questionList.length - 1]["quesSequence"] || 0) + 1;
          ques["marks"] = this.currentMark;
          ques["quesSequence"] = quesSeq;
          ques["questionSetId"] = ques._id;
          oldGroupTotal = oldGroups[1]["groupTotal"];
          oldGroups[1]["groupTotal"] =
            Number(oldGroupTotal) + Number(this.currentMark);
          oldGroups[1].questionList.push(ques);
        } else {
          let newArray = [];
          ques["marks"] = this.currentMark;
          ques["quesSequence"] = 1;
          ques["questionSetId"] = ques._id;
          oldGroups[1]["groupTotal"] = this.currentMark;
          newArray.push(ques);
          oldGroups[1]["questionList"] = newArray;
          oldGroups[1]["groupSequence"] = `${this.currentSequence}`;
          this.currentSequence = this.currentSequence + 1;
        }
      }
    });

    this.searchObj.questionGroups = oldGroups;
    if (this.searchObj.marksAlloted > this.searchObj.maxMarks) {
      $("#overflowModal").modal("show");
    }
    if (this.searchObj.marksAlloted == this.searchObj.maxMarks) {
      $("#alertModal").modal("show");
    }
    this.currentMark = 1;
    this.currentQuestionList = [];
  }

  addQuestionSection(quesGrpTitle, quesGrpMarks, quesGrpSequence) {
    let currGroup = {};
    currGroup["groupTotal"] = 0;
    currGroup["groupTitleLeft"] = quesGrpTitle;
    currGroup["groupTitleRight"] = quesGrpMarks;
    currGroup["groupSequence"] = quesGrpSequence;
    if (this.searchObj.questionGroups) {
      this.searchObj.questionGroups.push(currGroup);
    } else {
      this.searchObj.questionGroups = [];
      this.searchObj.questionGroups.push(currGroup);
    }
  }

  editMarks(grpInd, quesInd) {
    this.editMarkGrpInd = grpInd;
    this.editMarkQuesInd = quesInd;
    $("#editMarksModal").modal("show");
  }

  closeEditMarks() {
    $("#editMarksModal").modal("hide");
  }

  updateMarks() {
    if (this.searchObj.updatedMark && this.searchObj.updatedMark > 0) {
    } else {
      if (this.searchObj.updatedMark && this.searchObj.updatedMark < 0) {
        return this.notifier.notify(
          "error",
          "Maximum Marks should be a positive value."
        );
      } else {
        return this.notifier.notify("error", "Please provide Maximum Marks.");
      }
    }
    let updatedMark = this.searchObj["updatedMark"];
    if (this.searchObj.questionGroups && this.searchObj.questionGroups.length) {
      let currGrp = this.searchObj.questionGroups[this.editMarkGrpInd];
      if (currGrp && currGrp.questionList && currGrp.questionList.length) {
        let oldmarks = currGrp.questionList[this.editMarkQuesInd]["marks"];
        currGrp.questionList[this.editMarkQuesInd]["marks"] = updatedMark;
        this.searchObj.marksAlloted -= oldmarks;
        this.searchObj.marksAlloted += updatedMark;
        this.searchObj.marksAlloted.toFixed(1);
        this.searchObj.questionGroups[this.editMarkGrpInd].groupTotal -=
          oldmarks;
        this.searchObj.questionGroups[this.editMarkGrpInd].groupTotal +=
          updatedMark;
        this.searchObj.questionGroups[this.editMarkGrpInd].groupTotal.toFixed(
          1
        );
        $("#editMarksModal").modal("hide");
        this.serv.searchObj = this.searchObj;
        localStorage.setItem("searchObj", JSON.stringify(this.searchObj));
        this.notifier.notify("success", "Question marks updated successfully.");
        this.searchObj["updatedMark"] = 0;
      }
    }
  }

  removeQuestion(grpInd, quesInd) {
    if (this.searchObj.questionGroups && this.searchObj.questionGroups.length) {
      let currGrp = this.searchObj.questionGroups[grpInd];
      if (currGrp && currGrp.questionList && currGrp.questionList.length) {
        this.searchObj.marksAlloted -= currGrp.questionList[quesInd]["marks"];
        this.searchObj.marksAlloted.toFixed(1);
        this.questionList.forEach((question) => {
          if (question._id == currGrp.questionList[quesInd]["_id"]) {
            question.selected = false;
          }
        });
        this.searchObj.questionGroups[grpInd].groupTotal -=
          currGrp.questionList[quesInd]["marks"];
        this.searchObj.questionGroups[grpInd].groupTotal.toFixed(1);
        currGrp.questionList.splice(quesInd, 1);
        this.searchObj.totalQuestion -= 1;
        this.serv.searchObj = this.searchObj;
        localStorage.setItem("searchObj", JSON.stringify(this.searchObj));
        this.notifier.notify("success", "Question removed successfully.");
      }
    }
  }

  editQuestion() {
    $("#overflowModal").modal("hide");
    $("#updateMaximumMarksModal").modal("hide");
    this.isEdit = true;
  }

  closeModelAlert() {
    $("#alertModal").modal("hide");
  }

  closeModelMaximumMark() {
    $("#updateMaximumMarksModal").modal("hide");
  }

  noEdit() {
    this.isEdit = false;
  }

  goBack() {
    $("#alertModal").modal("hide");
  }

  goToSelectMore() {
    // localStorage.setItem("hideLeftNav", "Y");
    this.route.navigate(["/admin//fetch-questions"], {
      queryParams: { editChapters: "editChapters" },
    });
    if (this.hideLeftNav) {
      this.route.navigate(["/admin/fetch-questions-2"], {
        queryParams: { editChapters: "editChapters" },
      });
    } else {
      this.route.navigate(["/admin/fetch-questions"], {
        queryParams: { editChapters: "editChapters" },
      });
    }
  }
}
