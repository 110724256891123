import { Component, OnInit } from '@angular/core';
import { UserInfoService } from './user-info.service';
import { NotifierService } from 'angular-notifier'
import { Router } from '@angular/router';


@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {
  user:userInfo;
  constructor(public notifier: NotifierService, private serv:UserInfoService, private router:Router) { }

  ngOnInit() {
    this.user=new userInfo();
  }

  saveDetails(){
    this.serv.addUserDetails(this.user)
    .subscribe(res=>{
      if(res.status) {
        this.notifier.notify("success", "Information saved successfully");
        this.user=new userInfo();
      } else {
        this.notifier.notify("error", "Some error occurred");
      }
    },
    err=>{
      this.notifier.notify("error", "Some error occurred");
    }
    )
  }

  userList() {
    this.router.navigate(['/userList']);
    return false;
  }

  moveToSchoolInfo() {
    this.router.navigate(['/schoolInfo']);
    return false;
  }
}

export class userInfo{
  email:string;
  clientId:string;
  clientSecret:string;
}
