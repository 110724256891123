import { Component, OnInit } from "@angular/core";
import { SchoolServiceService } from "../school-info/school-service.service";
import { AllApisService } from "src/app/all-apis.service";
import { Router } from "@angular/router";
import { ConfirmDialogService } from "src/app/shared/confirm-dialog/confirm-dialog.service";
import { BookContentListService } from "../book-content-list/book-content-list.service";
import { NotifierService } from "angular-notifier";
import { AnonymousSubject } from "rxjs-compat";

declare var $: any;
@Component({
  selector: "app-chatbot-usage-report",
  templateUrl: "./chatbot-usage-report.component.html",
  styleUrls: ["./chatbot-usage-report.component.css"],
})
export class ChatbotUsageReportComponent implements OnInit {
  dropdownSettings = {
    singleSelection: false,
    idField: "item_id",
    textField: "item_text",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  showLoader = false;
  loaderMessage = "";
  schoolDisplayList = [];
  allUsage = [];
  schoolList = [];
  details = [];
  schoolDetails: any;
  schoolIdString = "";

  constructor(
    private serv: SchoolServiceService,
    private servStudent: BookContentListService,
    private allApiServ: AllApisService,
    private route: Router,
    private confirmBox: ConfirmDialogService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.showLoader = true;
    this.loaderMessage = "Please wait fetching all the schools....";
    this.populateTable();
    this.getSchoolList();
  }

  getSchoolList() {
    this.serv.getSchoolList({ schoolType: "EL" }).subscribe((res) => {
      if (res) {
        console.log("res", res);
        this.schoolList = res.schoolList;
        this.schoolDisplayList = [];
        this.schoolList.forEach((ele) => {
          this.schoolDisplayList.push({
            item_id: ele.id,
            item_text: ele.name + " (" + ele.id + ")",
          });
        });
        this.showLoader = false;
      }
      this.showLoader = false;
    }),
      (err) => {
        this.showLoader = false;
        console.log("unable to fect schools");
      };
  }

  getUsageReport() {
    console.log("this.schoolDetails", this.schoolDetails);
    if (this.schoolDetails && this.schoolDetails[0].item_id == "") {
      return this.notifier.notify("error", "Please Select School");
    }
    this.showLoader = true;
    this.loaderMessage = "Please wait fetching usage details....";
    this.schoolIdString = "";
    for (let i = 0; i < this.schoolDetails.length; i++) {
      if (i == 0) {
        this.schoolIdString += this.schoolDetails[i].item_id;
      } else {
        this.schoolIdString =
          this.schoolIdString + "," + this.schoolDetails[i].item_id;
      }
    }
    console.log("schoolIdString", this.schoolIdString);
    this.servStudent.getAllUsage(this.schoolIdString).subscribe(
      (res) => {
        if (res.status) {
          this.showLoader = false;
          this.allUsage = res.usage;
          this.populateTable();
        } else {
          this.allUsage = [];
          this.showLoader = false;
          this.notifier.notify("info", "No data found.");
        }
      },
      (error) => {
        this.showLoader = false;
        this.notifier.notify("error", "Something went wrong.");
      }
    );
  }

  downloadExcel() {
    console.log("schoolIdString", this.schoolIdString);
    let url =
      this.allApiServ.baseurl +
      "/getStudentmitraExcel?id=" +
      this.schoolIdString;
    window.open(url, "_blank");
  }

  populateTable() {
    let action_btn =
      '<span style="padding-left:10px;cursor:pointer;"  class="view" title="View Details"><i class="fas fa-solid fa-eye"></i></span>';
    let self = this;
    if ($.fn.DataTable.isDataTable(".example1")) {
      $(".example1").DataTable().clear().destroy();
    }
    $(".showhideTable1").css("display", "block");
    const example = $(".example1").DataTable({
      data: this.allUsage,
      columns: [
        { data: "smSchoolId", defaultContent: "<i>Not Set</i>" },
        { data: "schoolName", defaultContent: "<i>Not Set</i>" },
        { data: "name", defaultContent: "<i>Not Set</i>" },
        { data: "sourceId", defaultContent: "<i>Not Set</i>" },
        { data: "viewedSeries.length", defaultContent: "<i>Not Set</i>" },
        {
          data: null,
          className: "center",
          render: function (data) {
            return action_btn;
          },
          defaultContent: action_btn,
        },
      ],
      // dom: "Blfrtip",
      pageLength: 25,
      lengthMenu: [
        [5, 10, 25, 50, 100, 500, -1],
        [5, 10, 25, 50, 100, 500, "All"],
      ],
    });
    $(".example1 tbody ")
      .off("click")
      .on("click", "tr span.view", function (event) {
        event.stopPropagation();
        var p = this.closest("tr");
        var data = $(".example1").DataTable().row(p).index();
        var actualData = self.allUsage[data];
        console.log("acutal data edit", actualData);
        self.viewDetails(actualData);
      });
  }

  viewDetails(data) {
    console.log("data", data);
    for (let i = 0; i < data.viewedSeries.length; i++) {
      data.viewedSeries[i]["min"] = Math.floor(
        data.viewedSeries[i].timeUsed / 60
      );
      data.viewedSeries[i]["sec"] = (
        data.viewedSeries[i].timeUsed % 60
      ).toFixed(2);
      if (!data.viewedSeries[i]["lastSeenDate"]) {
        data.viewedSeries[i]["lastSeenDate"] = "";
      }
    }
    console.log("updated viewedDoc", data.viewedSeries);
    this.details = data.viewedSeries;
    $("#errorModal").modal("show");
  }
}
