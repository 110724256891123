import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from "@angular/core";
import 'rxjs/Rx';
import {Observable} from 'rxjs';
import { AllApisService } from '../all-apis.service';

@Injectable({
  providedIn: 'root'
})
export class FileServiceService {

  
  constructor(private _http:HttpClient, private allApi: AllApisService){}

  downloadFile(file:String){
      var body = {filename:file};
      let url = this.allApi.baseurl + '/file/uploadFile'
      return this._http.post(url,body,{
          responseType : 'blob',
          headers:new HttpHeaders().append('Content-Type','application/json')
      });
  }
}

