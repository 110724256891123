<div *ngFor="let page of lessonPlan">
  <div class="main py-5 class">
    <div class="container subDiv p-xl-5 p-3">
      <div class="row">
        <div class="col-md-6 leftSec">
          <div class="row" id="AD">
            <div class="col-6 cnt">
              <p class="heading">
                Annual: {{ page.currentAnnualDay }}/{{ page.totalAnnualDays }}
              </p>
            </div>
            <div class="col-6 cnt">
              <p class="heading">
                Day: {{ page.currentChapterDay }}/{{ page.totalChapterDays }}
              </p>
            </div>
          </div>
          <div class="row" id="buddyImg">
            <img
              [src]="page.pageImageUrl"
              class="d-block d-md-none"
              alt="Image"
            />
          </div>
        </div>
        <div class="col-md-6 rightSec">
          <div class="row cnt" id="learnOc">
            <p class="heading">Learning Outcome</p>
            <span
              *ngIf="page['LEARNING_OUTCOMES'] != null"
              [innerHTML]="page['LEARNING_OUTCOMES']"
            ></span>
            <span *ngIf="page['LEARNING_OUTCOMES'] == null">-</span>
          </div>
          <div class="row cnt" id="impWd">
            <p class="heading">Important Words</p>
            <p class="subtitle" *ngIf="page.IMPORTANT_WORDS_DURATION != '0'">
              Duration: {{ page.IMPORTANT_WORDS_DURATION }}min
            </p>
            <div class="tip-container" *ngIf="page.IMPORTANT_WORDS != ''">
              <ul style="margin-left: 30px">
                <li>Today: {{ page.IMPORTANT_WORDS }}</li>
              </ul>
            </div>
            <span *ngIf="page.IMPORTANT_WORDS == ''">-</span>
          </div>
          <div class="row cnt" id="transTips">
            <p class="heading">Transactional Tips</p>
            <p class="subtitle" *ngIf="page.TRANSACTIONAL_TIPS_DURATION != '0'">
              Duration: {{ page.TRANSACTIONAL_TIPS_DURATION }}min
            </p>
            <div
              *ngIf="page.TRANSACTIONAL_TIPS && page.TRANSACTIONAL_TIPS != null"
            >
              <div
                class="tip-container"
                *ngFor="let content of page.TRANSACTIONAL_TIPS"
              >
                <span>{{ content.strategy }}</span>
                <span [innerHTML]="content.description"></span>
                <br />
              </div>
            </div>
            <span *ngIf="page.TRANSACTIONAL_TIPS == null">-</span>
          </div>
          <!-- <div class="row cnt" id="intrDisc">
            <p class="heading">Interactive Discussion:</p>
          </div> -->
          <div class="row cnt" id="cpc">
            <p class="heading">Class Pulse Check</p>
            <p class="subtitle" *ngIf="page.PULSE_CHECK_DURATION != '0'">
              Duration: {{ page.PULSE_CHECK_DURATION }}min
            </p>
            <span
              *ngIf="
                page.PULSE_CHECK &&
                page.PULSE_CHECK.length > 0 &&
                page.PULSE_CHECK[0] == '-'
              "
              >-</span
            >
            <div
              *ngIf="
                page.PULSE_CHECK &&
                page.PULSE_CHECK.length > 0 &&
                page.PULSE_CHECK[0] != '-'
              "
            >
              <div
                class="tip-container"
                *ngFor="let content of page?.PULSE_CHECK; let i = index"
                style="margin-left: 20px"
              >
                <span> {{ i + 1 }}.</span> <span [innerHTML]="content"></span
                ><br />
              </div>
            </div>
          </div>
          <div class="row cnt" id="practise">
            <p class="heading">Practise</p>
            <div class="tip-container" *ngIf="page.PRACTISE.length != 0">
              <ul>
                <li *ngFor="let question of page.PRACTISE">{{ question }}</li>
              </ul>
            </div>
            <span *ngIf="page.PRACTISE.length == 0">-</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
