<div class="br-pagebody" style="background-color: white">
  <div class="row row-sm" style="display: flex" *ngIf="!showLoader">
    <div class="col-md-12">
      <form #f="ngForm">
        <div class="row loopRowDiv" *ngIf="schoolList && schoolList.length > 1">
          <div class="col-md-2"><strong>Select School</strong></div>
          <div class="form-group col-md-5">
            <ng-multiselect-dropdown
            [placeholder]="''"
              [settings]="dropdownSettings"
              (onSelect)="updateEventSchool($event)"
              [data]="schoolDisplayList"
            >
            </ng-multiselect-dropdown>
            <!-- <select class="form-control form-control-sm" #schoolName="ngModel" name="schoolName" [(ngModel)]="schoolDetails" (ngModelChange)="getSchoolDetails()"> 
                        <option *ngFor="let school of schoolList" [ngValue]="school">{{school.name}}({{school.id}})</option>
                    </select> -->
          </div>

          <div
            class="col-md-2"
            *ngIf="schoolInfo.standardList && schoolInfo.standardList.length"
          >
            <strong>Select Class</strong>
          </div>
          <div
            class="form-group col-md-3"
            *ngIf="schoolInfo.standardList && schoolInfo.standardList.length"
          >
            <select
              [(ngModel)]="contentStandard"
              name="contentStandard"
              class="form-control form-control-sm"
              (ngModelChange)="getSubjectDetails()"
            >
              <option *ngFor="let std of contentClassList">{{ std }}</option>
            </select>
          </div>
        </div>
        <br />
        <h6>
          <strong
            ><span *ngIf="schoolInfo && schoolInfo.schoolName"
              >School: {{ schoolInfo.schoolName }}</span
            ><span *ngIf="contentStandard && contentStandard != ''"
              >, Class-{{ contentStandard }}</span
            ></strong
          >

          <button
            class="ms-2"
            *ngIf="contentStandard && contentStandard != ''"
            (click)="showAllQrCode()"
          >
            All QR
          </button>
        </h6>
        <br />
        <div class="col-md-12" *ngIf="contentStandard && contentStandard != ''">
          <div class="row m-0 p-t-0 p-b-0" style="margin-bottom: 5px">
            <div class="col-md-2"><strong>Session</strong></div>
            <div class="col-md-2"><strong>Subject</strong></div>
            <div class="col-md-1"><strong>Category</strong></div>
            <div class="col-md-2"><strong>Series</strong></div>
            <div class="col-md-1"><strong>Board</strong></div>
            <div class="col-md-2"><strong>License Code</strong></div>
            <!-- <div class="col-md-1"><strong>Cambridge</strong></div> -->
            <div class="col-md-2"><strong>Action</strong></div>
          </div>
          <div
            class="row loopRowDiv m-0 p-t-0 p-b-0"
            *ngFor="let obj of subjectList; let i = index"
          >
          <div class="form-group col-md-2" *ngIf="obj.session">
            {{ obj.session }}
          </div>
          <div class="form-group col-md-2" *ngIf="!obj.session">
            Not Set
          </div>
            <div class="form-group col-md-2">
              {{ obj.subject }}
            </div>

            <div class="form-group col-md-1">
              {{ obj.category }}
            </div>

            <div class="form-group col-md-2">
              {{ obj.series }}
            </div>

            <div class="form-group col-md-1">
              {{ obj.board }}
            </div>

            <div class="form-group col-md-2">
              {{ obj.licenseCode }}
              <button
                *ngIf="
                  obj.licenseCode && obj.licenseCode != '';
                  else templateName
                "
                (click)="showQrCode(obj.licenseCode)"
              >
                QR
              </button>
              <ng-template #templateName>
                <button>NULL</button>
              </ng-template>
            </div>
            <div class="form-group col-md-2">
              <span
                ><button class="btn" (click)="removeSubject(i)">
                  <i
                    class="fas fa-trash-alt text-danger"
                    title="remove subject"
                  ></i></button
              ></span>
            </div>
            <div></div>
          </div>

          <div
            class="row loopRowDiv m-0 p-t-0 p-b-0"
            *ngIf="this.contentStandard && this.contentStandard != ''"
          >

          <div class="form-group col-md-2">
            <select
              [(ngModel)]="newSubject.session"
              name="newSubject.session"
              class="form-control form-control-sm"
              (ngModelChange)="selectSession()"
            >
            <option value="" disabled selected hidden>Select Session</option>
            <option value="2017-18">2017-18</option>
            <option value="2018-19">2018-19</option>
            <option value="2019-20">2019-20</option>
            <option value="22020-21">2020-21</option>
            <option value="2021-22">2021-22</option>
            <option value="2022-23">2022-23</option>
            <option value="2023-24">2023-24</option>
            <option value="2024-25">2024-25</option>
            <option value="2025-26">2025-26</option>
            <option value="2026-27">2026-27</option>
            <option value="2027-28">2027-28</option>
            <option value="2028-29">2028-29</option>
            <option value="2029-30">2029-30</option>
            <option value="2030-31">2030-31</option>
            </select>
          </div>


            <div class="form-group col-md-2">
              <select
                [(ngModel)]="newSubject.subject"
                name="newSubject.subject"
                class="form-control form-control-sm"
                (ngModelChange)="updateCategoryList()"
              >
                <option *ngFor="let sub of currSubjectList">{{ sub }}</option>
              </select>
            </div>

            <div class="form-group col-md-1">
              <select
                [(ngModel)]="newSubject.category"
                name="newSubject.category"
                class="form-control form-control-sm"
                (ngModelChange)="updateSeriesList()"
              >
                <option *ngFor="let cat of currCategory">{{ cat }}</option>
              </select>
            </div>

            <div class="form-group col-md-2">
              <select
                [(ngModel)]="newSubject.series"
                name="newSubject.series"
                class="form-control form-control-sm"
              >
                <option *ngFor="let ser of currSeries">{{ ser }}</option>
              </select>
            </div>

            <div class="form-group col-md-1">
              <select
                [(ngModel)]="newSubject.board"
                name="newSubject.board"
                class="form-control form-control-sm"
              >
                <option *ngFor="let board of currBoard">{{ board }}</option>
              </select>
            </div>
            <div class="form-group col-md-2"></div>

            <div class="form-group col-md-2">
              <button
                class="btn"
                type="button"
                style="margin-left: auto; margin-top: -5px; margin-left: 5px"
                (click)="addNewSubject()"
              >
                <i
                  class="far fa-plus-square text-danger"
                  title="Add Subject"
                ></i>
              </button>
            </div>

            <!-- <div class="col-md-12 btnContainer">
               <div
                        class="" data-toggle="modal" data-target="#fineModal" (click)="addNewRow()"><i
                          class="fas fa-plus text-success"></i></div>
                </div>
              </div> -->
            <!-- Submit Buttons -->

            <!-- Submit Buttons -->
          </div>
        </div>
        <div
          *ngIf="contentStandard && contentStandard != ''"
          class="form-group col-md-12"
          style="text-align: center; padding: 10px"
        >
          <div class="form-group col-md-3">
            <button type="button" class="btn btn-primary" (click)="saveInfo()">
              <i class="fas fa-save"></i> Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <page-loader *ngIf="showLoader" [loaderMsg]="loaderMessage"></page-loader>
</div>

<div
  class="modal fade"
  id="pictureModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="PictureModel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">QR Code</h5>
        <button
          type="button"
          class="close"
          (click)="done()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="singleField">
          <div class="form-group">
            <label>License Code</label>
            <input
              type="text"
              [value]="licenseCode"
              id="myInput"
              readonly="true"
              class="form-control"
            />
          </div>
          <div class="qr-container">
            <ngx-qrcode
              [elementType]="elementType"
              [value]="licenseCode"
              cssClass="coolQRCode"
              errorCorrectionLevel="L"
            >
            </ngx-qrcode>

            <button (click)="downloadQRCode()">Download QRCode</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="pictureAllModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="pictureAllModel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">All QR Code</h5>
        <button
          type="button"
          class="close"
          (click)="done2()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div id="htmlData" class="modal-body">
        <div class="singleField" *ngFor="let qr of allQrUrlData">
          <div class="form-group">
            <label>License Code</label>
            <input
              type="text"
              [value]="qr.licenseCode"
              id="myInput"
              readonly="true"
              class="form-control"
            />
          </div>
          <div class="qr-container">
            <img class="img-responsive" alt="QR Code" [src]="qr.url" />
          </div>
        </div>
        <div class="col-md-4 text-right">
          <button class="btn btn-success btn-block" (click)="openPDF()">
            Download PDF
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
