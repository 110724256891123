import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { SchoolServiceService } from '../school-info/school-service.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-school-info-list',
  templateUrl: './school-info-list.component.html',
  styleUrls: ['./school-info-list.component.css']
})
export class SchoolInfoListComponent implements OnInit {

  constructor(public notifier: NotifierService, private serv: SchoolServiceService, private router: Router) { }

  pipe = new DatePipe('en-IN'); // Use your own locale
  schoolInfoList=[];

  ngOnInit() {
    this.getSchoolDetails();
  }


  populateTable(active:boolean) {
    let self=this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.schoolInfoList,
        columns: [
          { data: 'schoolId', defaultContent: '<i>Not Set</i>' },
          { data: 'schoolName', defaultContent: '<i>Not Set</i>' },
          { data: 'licenseCount', defaultContent: '<i>Not Set</i>' },
          { data: 'licenseDate', defaultContent: '<i>Not Set</i>' },
          { data: 'zoomPaid', defaultContent: '<i>Not Set</i>' },
          // { data: 'zoomFree', defaultContent: '<i>Not Set</i>' },
          { data: 'gotowebinarPaid', defaultContent: '<i>Not Set</i>' },
          { data: 'gtwOrganizerKey', defaultContent: '<i>Not Set</i>' },

        ],

        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']]
      });
     
  }

  moveToSchoolInfo() {
    this.router.navigate(['/schoolInfo']);
    return false;
  }

  getSchoolDetails(){
      this.serv.getSchoolInfoList()
      .subscribe(res=>{
        console.log("abc",res);
        var array=res.result;
        array.forEach(ele => {
          var obj = {}
          obj["schoolName"] = ele.schoolName;
          obj["schoolId"] = ele.smSchoolId;
          obj["licenseCount"] = ele.zoomLicenseDetail.length;
          obj["licenseDate"] = '';

          if(ele["zoomLicenseDetail"] && ele["zoomLicenseDetail"].length) {
            ele["zoomLicenseDetail"].forEach(ld => {
              if(obj["licenseDate"] !== '') {
                obj["licenseDate"] = obj["licenseDate"] + ', ';
              }

var myFormattedDate = this.pipe.transform(ld.licenseDate, 'shortDate');
              obj["licenseDate"] = obj["licenseDate"] + myFormattedDate;
            })
          } 
          

          if(ele.zoomPaid == true){
            obj["zoomPaid"] = "<font color='green'><b>Yes</b></font>";;
          }else{
            obj["zoomPaid"] = "<font color='red'><b>No</b></font>";
          }
          // if (ele.zoomFree == true) {
          //   obj["zoomFree"] = "<font color='green'><b>Yes</b></font>";;
          // } else {
          //   obj["zoomFree"] = "<font color='red'><b>No</b></font>";
          // }
          if (ele.gotowebinarPaid == true) {
            obj["gotowebinarPaid"] = "<font color='green'><b>Yes</b></font>";;
          } else {
            obj["gotowebinarPaid"] = "<font color='red'><b>No</b></font>";
          }
          obj["gtwOrganizerKey"] = ele.gtwOrganizerKey;

          this.schoolInfoList.push(obj);
        })
        
        console.log("check", this.schoolInfoList );
        this.populateTable(true);
      })
      
    }

}
