import { Component, OnInit } from "@angular/core";
import { SchoolServiceService } from "../school-info/school-service.service";
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import QRCode from "qrcode";
declare var $: any;
@Component({
  selector: "app-school-content-map",
  templateUrl: "./school-content-map.component.html",
  styleUrls: ["./school-content-map.component.css"],
})
export class SchoolContentMapComponent implements OnInit {
  licenseCode = "Techiediaries";
  elementType = "url";
  value = "Techiediaries";
  showLoader = true;
  loaderMessage = "Loading, please wait..";
  zoomUserList = [];
  gtmUserList = [];
  allQrUrlData = [];
  versionList = ["2019", "2020"];
  variantList = ["TRX", "TRS"];
  schoolInfo: school;
  schoolDetails: object;
  slotList = [
    { name: "fullDay", count: 0 },
    { name: "8-12", count: 0 },
    { name: "12-4", count: 0 },
    { name: "4-7", count: 0 },
  ];
  schoolList = [];
  schoolDisplayList = [];
  dropdownSettings = {
    singleSelection: true,
    idField: "item_id",
    textField: "item_text",
    allowSearchFilter: true,
  };
  teamInfo: any;
  classList: any;
  contentClassList: any;
  title = "app";
  subjectList: any;
  stdSubjectList: any;
  constructor(
    private router: Router,
    private serv: SchoolServiceService,
    public notifier: NotifierService
  ) {}

  ngOnInit() {
    this.schoolInfo = new school();
    this.serv.lessonServ.getClass().subscribe((res) => {
      if (res) {
        console.log("res for contentclasslist" , res);
        this.contentClassList = res["result"];
        this.getSchoolList();
      }
    }),
      (err) => {
        console.log("unable to fect schools");
      };
  }

  getSchoolList() {
    this.serv.getSchoolList({ schoolType: "EUPHEUS" }).subscribe((res) => {
      if (res) {
        console.log("res", res);
        this.schoolList = res.schoolList;
        this.schoolDisplayList = [];
        this.schoolList.forEach((ele) => {
          this.schoolDisplayList.push({
            item_id: ele.id,
            item_text: ele.name + " (" + ele.id + ")",
          });
        });
        if (this.schoolList && this.schoolList.length == 1) {
          this.schoolDetails = {};
          this.schoolDetails["id"] = this.schoolList[0].id;
          this.schoolDetails["name"] = this.schoolList[0].name;
          this.getSchoolDetails();
        } else {
          this.showLoader = false;
        }
      }
    }),
      (err) => {
        console.log("unable to fect schools");
      };
  }

  saveInfo() {
    //this.onSubmit();
    this.schoolInfo.schoolName = this.schoolDetails["name"];
    this.schoolInfo.smSchoolId = this.schoolDetails["id"];
    this.schoolInfo.city = this.schoolDetails["city"];
    this.schoolInfo.standardList.forEach((elem) => {
      if (elem["moreLicense"] && elem["moreLicense"] > 0) {
      }
    });
    console.log(this.schoolInfo);
    console.log(this.schoolDetails);
    this.serv.addSchoolDetail(this.schoolInfo).subscribe(
      (res) => {
        console.log(res);
        if (res.status) {
          this.notifier.notify("success", "Information saved successfully");
        } else {
          this.notifier.notify("error", res.message);
        }
      },
      (err) => {
        this.notifier.notify("error", "Something bad happend");
      }
    );
  }
  async getSchoolDetails() {
    this.schoolInfo = new school();
    this.schoolInfo.standardList = [];
    this.showLoader = true;
    this.loaderMessage = "fetching school detail.";
    if (
      this.schoolDetails !== null &&
      typeof this.schoolDetails !== "undefined" &&
      this.schoolDetails["id"] !== null &&
      typeof this.schoolDetails["id"] !== "undefined"
    ) {
      this.serv
        .getSchoolInfo(this.schoolDetails["id"])
        .subscribe(async (res) => {
          console.log(res);
          if (res["status"]) {
            this.schoolInfo = res["result"];
          }
          if (res.standardList) {
            this.classList = res.standardList;
          } else {
            this.classList = null;
          }
          if (res.subjectObj) {
            this.stdSubjectList = res.subjectObj;
          }
          console.log("this.classList", this.classList);
          if (
            (!this.schoolInfo.standardList ||
              !this.schoolInfo.standardList.length) &&
            this.classList
          ) {
            let standardLessonList = [];
            this.classList.forEach((std) => {
              let stdObj = {};
              stdObj["standard"] = std.standard;
              stdObj["contentStandard"] = "";
              stdObj["licenseCount"] = 0;
              stdObj["licenseAdded"] = 0;
              stdObj["licenseCode"] = "";
              stdObj["moreLicense"] = 0;

              standardLessonList.push(stdObj);
            });
            this.schoolInfo.standardList = standardLessonList;
          } else {
            let newStdList = [];
            this.classList.forEach((std) => {
              let slObj = this.schoolInfo.standardList.filter(
                (sl) => sl.standard == std.standard
              );
              if (slObj && slObj.length) {
                slObj[0]["moreLicense"] = 0;
                newStdList.push(slObj[0]);
              } else {
                let stdObj = {};
                stdObj["standard"] = std.standard;
                stdObj["contentStandard"] = "";
                stdObj["licenseCount"] = 0;
                stdObj["licenseAdded"] = 0;
                stdObj["licenseCode"] = "";
                stdObj["moreLicense"] = 0;
                newStdList.push(stdObj);
              }
            });
            this.schoolInfo.standardList = newStdList;
          }
          this.showLoader = false;
          for (let obj of this.schoolInfo.standardList) {
            if (obj.standard) {
              if (obj.licenseCount && obj.licenseCount > 0) {
                this.allQrUrlData.push({
                  licenseCode: obj.licenseCode,
                  std: obj.standard,
                  url: await QRCode.toDataURL(obj.licenseCode),
                });
              }
            }
          }
        });
    }
  }

  openPDF() {
    let DATA: any = document.getElementById("htmlData");
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 150;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL("image/png");
      let PDF = new jsPDF("p", "mm", "a4");
      let position = 0;
      PDF.addImage(FILEURI, "PNG", 0, position, fileWidth, fileHeight);
      PDF.save("AllQR.pdf");
    });
  }

  showAllQrCode() {
    $("#pictureAllModel").modal("show");
  }

  done2() {
    $("#pictureAllModel").modal("hide");
  }

  updateEventSchool(school: any) {
    console.log("seatcher changed", school);
    var arr = this.schoolList;
    this.schoolDetails = {};
    arr.forEach((ele) => {
      if (school.item_id == ele.id) {
        this.schoolDetails["id"] = ele.id;
        this.schoolDetails["name"] = ele.name;
        this.getSchoolDetails();
      }
    });
    console.log("aaaa", this.schoolInfo);
  }

  onSubmit() {
    console.log(this.schoolInfo);
  }

  backClicked() {
    this.router.navigate(["/masterAdmin/dashboard"]);
  }

  openSchoolList() {
    this.router.navigate(["/schoolInfoList"]);
    return false;
  }

  replaceHost() {
    this.router.navigate(["/reschedule"]);
    return false;
  }

  meetingDetails() {
    this.router.navigate(["/meetingDetails"]);
    return false;
  }

  userList() {
    this.router.navigate(["/userList"]);
    return false;
  }

  userInfo() {
    this.router.navigate(["/userInfo"]);
    return false;
  }

  resetRow(index) {
    let currObj = this.schoolInfo.standardList[index]; // Imp. to refreshing
    currObj["contentStandard"] = "";
    currObj["version"] = "";
    currObj["variant"] = "";
  }

  showQrCode(qrValue) {
    QRCode.toDataURL(qrValue)
      .then((url) => {
        console.log("fron qrcode lib", url);
      })
      .catch((err) => {
        console.error(err);
      });
    // console.log("testing qevalue", this.schoolInfo.standardList[2].licenseCode);
    // console.log(
    //   "testing qevalue class",
    //   this.schoolInfo.standardList[2].standard
    // );
    console.log("qrValue", qrValue);
    this.licenseCode = qrValue;
    $("#pictureModel").modal("show");
  }

  done() {
    $("#pictureModel").modal("hide");
  }

  downloadQRCode() {
    const fileNameToDownload = "image_qrcode";
    const base64Img =
      document.getElementsByClassName("coolQRCode")[0].children[0]["src"];
    fetch(base64Img)
      .then((res) => res.blob())
      .then((blob) => {
        // IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileNameToDownload);
        } else {
          // Chrome
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = fileNameToDownload;
          link.click();
        }
      });
  }
}

export class school {
  schoolName: string;
  city: string;
  smSchoolId: string;
  standardList: Array<standardLesson>;
  constructor() {}
}

export class standardLesson {
  standard: string;
  contentStandard: string;
  licenseCount: Number;
  licenseAdded: Number;
  licenseCode: string;
  moreLicense: Number;
  constructor() {}
}
