<!-- <iframe  [src]="safeSrc" width="560" height="315" frameborder="0" webkitallowfullscreen mozallowfullscreen
      allowfullscreen></iframe> -->

<div
  class="modal fade"
  id="displayModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="displayModel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Video Viewer</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="changeSource()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            id="videoViewer"
            style="width: 100%; height: 480px; border: none; text-align: center"
            src="/assets/img/loader-001.gif"
            class="embed-responsive-item"
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="pdfModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="pdfModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="pdfModal">Pdf Viewer</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body" id="iframe-body" style="text-align: center">
        <button
          class="btn btn-warning"
          id="iosPDFBtn"
          (click)="iosPdfDownload()"
          *ngIf="ios"
        >
          Pdf Download
        </button>
        <iframe
          id="pdfViewer"
          src="/assets/img/loader-001.gif"
          style="width: 100%; height: 480px; border: none; text-align: center"
          *ngIf="!ios"
        ></iframe>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="imgModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="imgModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="imgModal">Image Viewer</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body" id="iframe-body checkDevice">
        <iframe
          id="imgViewer"
          src="/assets/img/loader-001.gif"
          style="width: 100%; height: 480px; border: none; text-align: center"
        ></iframe>
      </div>
    </div>
  </div>
</div>
