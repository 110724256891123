import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AllApisService } from '../all-apis.service'


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  
  errorData: {};
  //fatchSchoolNames = this.allApi.schoolNameListUrl;
  batchPaymentUrl = "/batches/payment";
  feePaymentUrl = "/pay/feePayment"
  startPaymentUrl = "/pay/startPayment";
  createPaymentUrl = "/pay/createPayment";

  constructor(private _http: HttpClient,
              private allApi:AllApisService ) { }
 /*  User signup */

  createPayment(payObj: any) {
    var reqUrl = this.allApi.baseurl + this.createPaymentUrl;
    return this._http.post<any>(reqUrl, payObj).pipe(catchError(this.handleError));
  }

  batchPayment(obj: any) {
    var studentInfo = this.allApi.getStudentInfo();
    obj['studentInfo'] = studentInfo;
    var reqUrl = this.allApi.baseurl + this.batchPaymentUrl;
    return this._http.post<any>(reqUrl, obj, this.allApi.httpOptions);
  }

  confirmPayment(obj: any) {
    var reqUrl = this.allApi.baseurl + "/batches/confirm-pay";
    return this._http.post<any>(reqUrl, obj, this.allApi.httpOptions);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.

      // The response body may contain clues as to what went wrong,

      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message

    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }

}
