import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { AllApisService } from 'src/app/all-apis.service';


@Injectable({
  providedIn: 'root'
})
export class TeacherMapService {

  constructor(private http: HttpClient, private allApiService:AllApisService) { }
  getClassSection(){
    return this.http.get<any>(this.allApiService.baseurl+'/school/standards', this.allApiService.httpOptions);
  }
  getProgramSemester(){
    return this.http.get<any>(this.allApiService.baseurl+'/courses/programList', this.allApiService.httpOptions);
  }
  getSchoolClassSection(smSchoolId : any){
    let params = new HttpParams();
    params= params.append('smSchoolId', smSchoolId);
    this.allApiService.httpOptions.params = params;
    return this.http.get<any>(this.allApiService.baseurl+'/school/standards', this.allApiService.httpOptions);
  }
  
  getSubject(){
    return this.http.get<any>(this.allApiService.baseurl+'/lesson/getSubject',this.allApiService.httpOptions);
  }

  getProgramSubject(program = null, semester = null){
    let params = new HttpParams();
    params= params.append('program', program);
    params= params.append('semester', semester);
    this.allApiService.httpOptions.params = params;
    return this.http.get<any>(this.allApiService.baseurl+'/courses/getSubject',this.allApiService.httpOptions);
  }

  addTeacherSubMap(obj:any){
    return this.http.post<any>(this.allApiService.baseurl+'/teacher',{teacherSubList: obj},this.allApiService.httpOptions);
  }

  fetchList(schoolId: any = null){
    if(schoolId) {
      let params=new HttpParams();
      params=params.append('schoolId',schoolId);
      this.allApiService.httpOptions.params=params;
    }
    return this.http.get<any>(this.allApiService.baseurl+'/teacher/fetchSubList',this.allApiService.httpOptions);
  }

  fetchStudentList(obj:any){
    return this.http.post<any>(this.allApiService.baseurl+'/student/fetchCrdentialList',obj,this.allApiService.httpOptions);
  }
  removeAllotment(obj:any){
    return this.http.patch<any>(this.allApiService.baseurl+'/teacher/removeAllotment',obj,this.allApiService.httpOptions);
  }
  addTeacherMsDetail(obj:any){
    // let newObj = {teacherSubList : obj}
    return this.http.patch<any>(this.allApiService.baseurl+'/teacher/addMsDetail',{teacherSubList: obj},this.allApiService.httpOptions);
  }

  getStudents(standard, section) {
    var serverUrl = this.allApiService.baseurl + '/school/student';
    return this.http.post<any>(serverUrl,{standard: standard, section: section},this.allApiService.httpOptions);
    
  }

  enrolledStudent(standard, section) {
    var serverUrl = this.allApiService.baseurl + '/school/enrolledStudent';
    return this.http.post<any>(serverUrl,{standard: standard, section: section},this.allApiService.httpOptions);
    
  }

  getStudentsFromStudentInfo(standard, section) {
    var serverUrl = this.allApiService.baseurl + '/school/impartusStudents';
    return this.http.post<any>(serverUrl,{standard: standard, section: section},this.allApiService.httpOptions);
    
  }
}
