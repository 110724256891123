import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import {AllApisService} from '../all-apis.service';
import { TeacherMapService } from './teacher-subject/teacher-map.service';

@Injectable({
  providedIn: 'root'
})
export class ZoomService {
  baseUrl:string;
  httpOptions = {
    headers: new HttpHeaders(),
    params: new HttpParams()
  };
  httpOptionsInitial = {
    headers: new HttpHeaders(),
    params: new HttpParams()
  };
  

  constructor(private http: HttpClient, private allApiService:AllApisService, public teacherServ: TeacherMapService) {
    this.baseUrl=this.allApiService.getBaseUrl();
   }
 

  
  
  scheduleEvent(webinarObj:any, coorganizer:any, std:any, section:any, sessionEvent: any) {
    var schoolInfo = this.allApiService.getSchoolInfo();
    var url=this.allApiService.getBaseUrl();
    if(url===this.allApiService.baseurl+'/zoom' && sessionEvent.preferEmail==='Team')url=this.allApiService.baseurl+'/team';
    return this.http.post<any>(url+'/scheduleEvent', {webinarObj: webinarObj, coorganizer: coorganizer, std: std, section: section, sessionEvent: sessionEvent, schoolInfo: schoolInfo}, this.allApiService.httpOptions);
  }

  getUpdatedJoinLink(obj:any){
    obj['schoolInfo'] = this.allApiService.getSchoolInfo();
    console.log('link',this.baseUrl+'/updatedStartLink')
    return this.http.patch<any>(this.baseUrl+'/updatedStartLink',obj,this.allApiService.httpOptions);
  }

  
  
  getAttendees(webinarKey:any,hostEmail:string){
    // sending request from backend to avoid invalid cors request error
    return this.http.post<any>(this.baseUrl+'/fetchSessionAttendee',{ webinarKey:webinarKey,emailId:hostEmail},this.allApiService.httpOptions);
  }
   
  getWebinarSessions(webinarKey:any){
    // sending request from backend to avoid invalid cors request error
    return this.http.post<any>(this.baseUrl+'/fetchWebinarSessions',{ webinarKey:webinarKey},this.allApiService.httpOptions);
  }
// Local Apis

// fecth teacher list of school
  getTeachers(){
    return this.http.get<any>(this.allApiService.baseurl+'/teacher/fetch', this.allApiService.httpOptions);
  }

  getSchoolTeacher(schoolId:any){
    let params= new HttpParams();
    params = params.append('schoolId', schoolId);
    this.allApiService.httpOptions.params = params;
    return this.http.get<any>(this.allApiService.baseurl+'/teacher/fetch', this.allApiService.httpOptions);
  }
  getSubjects() {
    return this.http.get<any>(this.allApiService.baseurl+'/school/subjects', this.allApiService.httpOptions);
  }

  // fetch class section of school
  getClassSection(){
    return this.http.get<any>(this.allApiService.baseurl+'/classSection', this.allApiService.httpOptions);
  }
  
  // get all sessions of school (cancelled not included)
  getSessionsForSchool(){
    
    return this.http.get<any>(this.baseUrl+'/seminar', this.allApiService.httpOptions);
  }
  getSessionForUser(userId:any){
    let params=new HttpParams();
    params=params.append('userId',userId);
    this.allApiService.httpOptions.params=params;
    return this.http.get<any>(this.baseUrl+'/userSeminar',this.allApiService.httpOptions);
  }

  // save session(seminar) for school
  saveSession(obj:any){
    console.log("service layer");
    return this.http.post<any>(this.baseUrl+'/seminar',obj,this.allApiService.httpOptions);
  }
  

  // cancel session(seminar) for school
  deleteSession(obj:any){
    return this.http.patch<any>(this.baseUrl+'/deleteSeminar',obj,this.allApiService.httpOptions);
  }

  // get all sessions(seminar) information of a particular slotdate and slot
  getSessionInfo(slotDate:any,std:any, section:any){
    let params=new HttpParams();
    params=params.append('slotDate',slotDate);
    params=params.append('class',std);
    params=params.append('section',section);
    this.allApiService.httpOptions.params=params;
    return this.http.get<any>(this.baseUrl+'/seminarInfo',this.allApiService.httpOptions);
  }

  //add attendee 
  addAttendee(obj:any){
    return this.http.post<any>(this.baseUrl+'/attendee',obj,this.allApiService.httpOptions);
  }

  // update session of school (i.e. teacher and class section)
  updateSession(obj:any){
    return this.http.patch<any>(this.baseUrl+'/seminar',obj,this.allApiService.httpOptions);
  }

  // get students of a particular class and section of a school
  getStudent(std:any,section:any){
    let params=new HttpParams();
    // params=params.append('schoolId',"5e29455fd9956c2394793f50");
    params=params.append('class',std);
    params=params.append('section',section);
    this.allApiService.httpOptions.params=params;
    return this.http.get<any>(this.baseUrl+'/student', this.allApiService.httpOptions);
  }

  // get joining link of user
  getJoiningLink(sourceId:any,schoolId:any){
    let params=new HttpParams();
    // params=params.append('slotDate',slotDate);
    // params=params.append('slot',slot);
    params=params.append('sourceId',sourceId);
    params=params.append('schoolId',schoolId);
    this.allApiService.httpOptions.params=params;
    return this.http.get<any>(this.baseUrl+'/joinLink',this.allApiService.httpOptions);
  }

  getCredentialToken(){
    return this.http.get<any>(this.baseUrl+'/accessToken');
  }


  fetchSubject(std:any,section:any,teacher:any){
    let params=new HttpParams();
    params=params.append('class',std);
    params=params.append('section',section);
    params=params.append('teacherId',teacher);
    this.allApiService.httpOptions.params=params;
    return this.http.get<any>(this.allApiService.baseurl+'/teacher/fetchSubject',this.allApiService.httpOptions);
  }


  getRecording(meetingId:string,schoolInfo:any){
   
    return this.http.post<any>(this.baseUrl+'/getRecording',{meetingId:meetingId,schoolInfo:schoolInfo},this.allApiService.httpOptions);
  }

  getFilteredSessions(start:any,end:any, teacherId?: any){
    let params=new HttpParams();
    params=params.append('startDate',start);
    params=params.append('endDate',end);
    params=params.append('teacherId',teacherId);
    this.allApiService.httpOptions.params=params;
    return this.http.get<any>(this.baseUrl+'/seminarByDate',this.allApiService.httpOptions)
  }

  updateAttendeeLink(obj:any){
    return this.http.patch<any>(this.baseUrl+'/updateAttendeeLink',obj,this.allApiService.httpOptions);
  }
  
  getImpartusToken(externalId:any){
    let params=new HttpParams();
    params=params.append('externalId',externalId);
    this.allApiService.httpOptions.params=params;
    return this.http.get<any>(this.baseUrl+'/getImpartusToken',this.allApiService.httpOptions);
  }

  getTeacherImpartusToken(externalId:any){
    let params=new HttpParams();
    params=params.append('externalId',externalId);
    this.allApiService.httpOptions.params=params;
    return this.http.get<any>(this.baseUrl+'/getTeacherImpartusToken',this.allApiService.httpOptions);
  }

  getAdminImpartusToken(){
    
    return this.http.get<any>(this.baseUrl+'/getAdminImpartusToken',this.allApiService.httpOptions);
  }

  getImpartusAttendence(seminarId: any){
    let params=new HttpParams();
    params=params.append('seminarId',seminarId);
    this.allApiService.httpOptions.params=params;
    return this.http.get<any>(this.allApiService.getBaseUrl()+'/getAttendee',this.allApiService.httpOptions);
  }

  getMonthlyAttendence(obj){
    let url = this.allApiService.baseurl+'/impartus/monthlyAttendence'    
     return this.http.post<any>(url,obj,this.allApiService.httpOptions);
   }
 
}
