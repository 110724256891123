import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';


@Component({
  selector: 'app-intro-page',
  templateUrl: './intro-page.component.html',
  styleUrls: ['./intro-page.component.css']
})
export class IntroPageComponent implements OnInit {
  role:string
  studentCount:number
  constructor(private router: Router) { }

  ngOnInit(): void {
    if(localStorage.getItem('token')  && localStorage.getItem('role')) {
      if(localStorage.getItem('studentDetails')){
        this.studentCount=JSON.parse(localStorage.getItem('studentDetails')).length;
      }
      this.role=localStorage.getItem('role');
      this.callAfterSignIn();
     
    }
  }
  signInPage(){
    this.router.navigateByUrl('sign-in');
  }


  
  callAfterSignIn() {
   
    if (this.role === "Chrysallis Admin") {
      this.router.navigate(['/admin/dashboard/admin']);
    }
    else if(this.role === 'Master Admin'){
      this.router.navigate(['/masterAdmin/dashboard']);
    }
    else if(this.role === 'Teacher'){
      this.router.navigate(['/teacher/dashboard/teacherHomepage']);
    }
    else if(this.role === 'Parent'){
      if(this.studentCount === 1){
        this.router.navigate(['/student/dashboard/studentHomePage']);
      }
      else{
        this.router.navigate(['/student/select-student']);
      }
    
    }

    
  }
}
