import { Component, OnInit } from '@angular/core';
import { AllApisService} from '../all-apis.service';
import {LessonServiceService} from '../lesson-dashboard/lesson-service.service';
import { Router} from '@angular/router';
import { ConfirmDialogService } from '../shared/confirm-dialog/confirm-dialog.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {NotifierService} from 'angular-notifier'
import { YtplayerService } from '../shared/ytplayer/ytplayer.service';

declare var $:any;
@Component({
  selector: 'app-content-dashboard',
  templateUrl: './content-dashboard.component.html',
  styleUrls: ['./content-dashboard.component.css']
})
export class ContentDashboardComponent implements OnInit {
  contentList=[];
  lessonList=[];
  moduleId:string;
  linkList=[];
  editContentTitle:any;
  editWeek:any;
  editSesson:any;
  editSequence:any;
  editId:any;
  videoLink:any;
  safeVideo:any;
  checkLink:any;
  editLink:string;
  newLink:string;
  editIndex:any;
  contentIndex:any;
  deleteIndex:any;
  constructor(private route:Router, private allApiServ:AllApisService, private serv:LessonServiceService, private confirmBox:ConfirmDialogService, private sanitizer: DomSanitizer,private notifier:NotifierService, private ytServ:YtplayerService) { }

  ngOnInit(): void {
    if(this.allApiServ.lessonInfo!==null && typeof this.allApiServ.lessonInfo!=='undefined' && this.allApiServ.lessonInfo!==''){
      // console.log(this.allApiServ.lessonInfo);
      this.contentList=this.allApiServ.lessonInfo.lessonContents;
    }
    else{
      // console.log('cam here');
      this.moduleId=localStorage.getItem('moduleId');
      this.getLessonList();
    }
    
    this.populateTable2();
  }

  populateTable2() {
    let action_btn =  '<span class="links"><i style="padding-left:10px;position:pointer;" class="fas fa-eye fa-2x text-danger" title="Display Links"></i> </span> <span class="edit"><i style="padding-left:10px;position:pointer;"  class="fas fa-edit fa-2x text-info" title="Edit Details"></i></span> <span style="padding-left:10px;" class="delete text-danger" title="Delete"><i class="far fa-trash-alt" *ngIf="false"> </i></span> <span class="move"><i style="padding-left:10px;position:pointer;" class="fas fa-arrow-right fa-2x text-info" title="Move to Activites"></i>'
    let action_btn_without_delete =  '<span class="links"><i style="padding-left:10px;position:pointer;" class="fas fa-eye fa-2x text-danger" title="Display Links"></i> </span> <span class="edit"><i style="padding-left:10px;position:pointer;"  class="fas fa-edit fa-2x text-info" title="Edit Details"></i></span> <span class="move"><i style="padding-left:10px;position:pointer;" class="fas fa-arrow-right fa-2x text-info" title="Move to Activites"></i>'
    let self=this;
    if ($.fn.DataTable.isDataTable(".example1")) {
      $('.example1').DataTable().clear().destroy();
    };
    $('.showhideTable1').css('display', 'block');
    const example = $('.example1')
      .DataTable({
        data: this.contentList,
        columns: [
          
          { data: 'topicId', defaultContent: '<i>Not Set</i>' },
          { data: 'moduleId', defaultContent: '<i>Not Set</i>' },
          { data: 'title', defaultContent: '<i>Not Set</i>' },
          { data: 'sequence', defaultContent: '<i>Not Set</i>' },
          {
            data: null,
            className: 'center',
            render: function(data) { 
              if(data.contentActivities && data.contentActivities.length > 0) {
                return action_btn_without_delete 
              }
              else {
                return action_btn
              }
            },
            defaultContent: action_btn_without_delete
            
          } 

        ],
        dom: 'Bfrtip',
      //   buttons: [
      //     {
      //       extend: 'excel',
      //       filename: 'StudentResponse',
      //       title: 'Padho'
      //   },
      //   {
      //       extend: 'csv',
      //       filename: 'StudentResponse',
      //       title: 'Padho'
      //   },
      //   {
      //       extend: 'pdf',
      //       filename: 'StudentResponse',
      //       title: 'Padho'
      //   }
      // ],
      "order": [[ 0, "asc" ]],
        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']]
      });
      $('.example1 tbody ').on('click', 'tr span.move', function () {
        console.log("move...")
        var p = this.closest('tr');
        var data = $('.example1').DataTable().row(p).index();
        self.navigateToContents(data);
     });
     $('.example1 tbody').on('click', 'tr span.edit', function () {
      console.log("edit............");
     var p = this.closest('tr');
     var data = $('.example1').DataTable().row(p).index();
     self.editContentData(self.contentList[data]);
   });
     $('.example1 tbody ').on('click', 'tr span.links', function () {
      console.log("move...")
      var p = this.closest('tr');
      var data = $('.example1').DataTable().row(p).index();
      self.displayLinks(data);
   });
   $('.example1 tbody').on('click', 'tr span.delete', function () {
    console.log("edit............");
   var p = this.closest('tr');
   var data = $('.example1').DataTable().row(p).index();
   self.deleteIndex=data;
   self.deleteContentData(self.contentList[data]);
 });
      
      
  }

  deleteContentData(data:any){
    var obj={}
    obj["id"]=data._id;
    let self = this;

    this.confirmBox.confirmThis('Are you sure you want to delete the lesson content', 'Delete Content ?', function () {

      self.serv.deleteContent(obj)
    .subscribe(res=>{
     console.log("res",res)
     if(res['status']){
       self.notifier.notify('success','Deleted successfully');
       self.contentList.splice(self.deleteIndex,1);
       self.populateTable2();
     }
    })

    }, function () {
    })
  }

  editContentData(data:any){
    console.log("data", data);

    this.editId = data._id;
    this.editContentTitle = data.title;
    this.editWeek = data.week;
    this.editSequence = data.sequence;
    this.editSesson = data.session;
    
    $('#editModel').modal('show');
  }

  updateEditDetails(){
    var obj={}

    obj["id"]= this.editId;
    obj["title"] = this.editContentTitle;
    obj["sequence"] = this.editSequence;
    obj["week"] = this.editWeek;
    obj["session"] = this.editSesson;

    this.serv.updateEditContent(obj)
    .subscribe(res=>{
      console.log("content",res);
      if(res.status == true ){
        $('#editModel').modal('hide');
      }
      this.getLessonList();
    })
  }

  getLessonList(){
    this.serv.getLesson({_id:this.moduleId})
    .subscribe(res=>{
      if(res.status ){
        this.allApiServ.lessonInfo=res['result'][0];
        this.contentList=res["result"][0]["lessonContents"];
        this.populateTable2();
      }
    })
  }
  navigateToContents(evt:any){
    // this.allApiServ.lessonInfo=this.lessonList[evt];
    this.allApiServ.topicId=this.contentList[evt]["_id"];
    localStorage.setItem('topicId',this.contentList[evt]["_id"]);
    this.route.navigate(['/dashboard/activity'])
  }

  displayLinks(evt:any){
    this.linkList=this.contentList[evt]['links'];
    // this.popup=true;
    //this.populateTable();

    this.contentIndex=evt;
    $("#errorModal").modal('show');
  }



  backClicked(){
    this.route.navigate(['/dashboard/lesson']);
  }

  populateTable() {
    let action_btn =  '<i style="padding-left:10px,position:pointer;" class="fas fa-eye  text-info links" title="Display Links"></i> <i style="padding-left:10px,position:pointer;" class="fas fa-arrow-right  text-info move" title="Move to contents"></i>'
    let self=this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.linkList,
        columns: [
          
          { data: 'title', defaultContent: '<i>Not Set</i>' },
          { data: 'url', defaultContent: '<i>Not Set</i>' },
          { data: 'contentType', defaultContent: '<i>Not Set</i>' },
          

        ],
        // dom: 'Bfrtip',
     
      "order": [],
        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']]
      });
      
      
      
  }

  displayModel(evt:any){
    // window.location.href=this.linkList[evt]['url'];
    console.log("evt", evt)
    $("#errorModal").modal('hide');
    this.checkLink=this.linkList[evt]['url']
    this.ytServ.confirmThis(this.checkLink);
    
  }

  updateLink(){
    console.log('the link has been updated to',this.newLink);
    this.linkList[this.editIndex]['url']=this.newLink;
    console.log(this.linkList[this.editIndex]['url']);
    var obj={
      standard:this.allApiServ.lessonInfo['standard'],
      moduleId:this.allApiServ.lessonInfo['moduleId'],
      version:this.allApiServ.lessonInfo['version'],
      variant:this.allApiServ.lessonInfo['variant'],
      subject:this.allApiServ.lessonInfo['subject'],
      topicId:this.contentList[this.contentIndex]['topicId'],
      title:this.linkList[this.editIndex]['title'],
      url:this.newLink,
      contentType:this.linkList[this.editIndex]['contentType'],
    }
    $("#editModal").modal('hide');
    this.serv.updateLink(obj)
    .subscribe(res=>{
      if(res['status']){
        this.notifier.notify('success','Link has been updated successfully')
      }
      else{
        this.notifier.notify('error','Link update failed')
      }
    })
  }

  editModel(evt:any){
    $("#errorModal").modal('hide');
    this.editLink=this.linkList[evt]['url'];
    this.editIndex=evt;
    $("#editModal").modal('show');
  }
}
