import { Component, OnInit } from "@angular/core";
import { AllApisService } from "../all-apis.service";
import { Router } from "@angular/router";
import { ConfirmDialogService } from "../shared/confirm-dialog/confirm-dialog.service";
import { NotifierService } from "angular-notifier";
import { YtplayerService } from "../shared/ytplayer/ytplayer.service";
import {
  QuestionListService,
  searchKeys,
} from "../master-admin/question-list/question-list.service";

declare var $: any;
@Component({
  selector: "app-create-exam",
  templateUrl: "./create-exam.component.html",
  styleUrls: ["./create-exam.component.css"],
})
export class CreateExamComponent implements OnInit {
  questionList = [];
  stdList = [];
  categoryList = [];
  seriesList = [];
  subjectList = [];
  chapterList = [];
  topicList = [];
  editId: any;
  editTitle: any;
  editSequence: any;
  searchObj = new searchKeys();
  popup = false;
  linkList: any;
  deletelesson: boolean = false;
  videoLink: any;
  safeVideo: any;
  checkLink: any;
  editLink: string;
  newLink: string;
  editIndex: any;
  lessonIndex: any;
  deleteIndex: any;
  subjectMaps: any;
  sectionList: any[];
  allCategoryList: any;
  allSubjectList: any;
  hideLeftNav: boolean = false;
  constructor(
    private serv: QuestionListService,
    private allApiServ: AllApisService,
    private route: Router,
    private confirmBox: ConfirmDialogService,
    private notifier: NotifierService,
    private ytServ: YtplayerService
  ) {}

  ngOnInit(): void {
    let localHideLeftNav = localStorage.getItem("hideLeftNav");
    if (localHideLeftNav && localHideLeftNav == "Y") {
      this.hideLeftNav = true;
    }
    this.getClassList();
    this.getSubjectList();
    this.searchObj.totalQuestion = 0;
  }

  getClassList() {
    this.serv.getClasses().subscribe((res) => {
      this.stdList = res.result;
      console.log("this.stdList", this.stdList);
    });
  }

  getSubjectList() {
    this.serv.getSubject().subscribe((res) => {
      console.log("Subjects", res);
      var check = {};
      this.allSubjectList = res.subjects;
      this.allCategoryList = res.categoryList;
    });
  }

  goToChooseSubject() {
    if (this.searchObj.examName) {
      this.searchObj.examName = this.searchObj.examName.trim();
    }
    if (
      this.searchObj.examName &&
      this.searchObj.examName != "" &&
      this.searchObj.examName != " "
    ) {
    } else {
      return this.notifier.notify("error", "Please provide exam name.");
    }
    if (this.searchObj.maxMarks && this.searchObj.maxMarks > 0) {
    } else {
      if (this.searchObj.maxMarks && this.searchObj.maxMarks < 0) {
        return this.notifier.notify(
          "error",
          "Maximum Marks should be a positive value."
        );
      } else {
        return this.notifier.notify("error", "Please provide Maximum Marks.");
      }
    }

    if (this.searchObj.maxHours && this.searchObj.maxHours > 0) {
    } else {
      if (this.searchObj.maxHours && this.searchObj.maxHours < 0) {
        return this.notifier.notify(
          "error",
          "Exam duration has to be a positive value."
        );
      } else {
        return this.notifier.notify("error", "Please provide exam duration.");
      }
    }
    this.serv.searchObj = this.searchObj;
    localStorage.setItem("searchObj", JSON.stringify(this.searchObj));
    if (this.hideLeftNav) {
      this.route.navigate(["/admin/fetch-questions-2"]);
    } else {
      this.route.navigate(["/admin/fetch-questions"]);
    }
  }
}
