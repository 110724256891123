<div>
    <div class="breadcrumb">
        <div class="col-md-12">
            <div class="row row-sm" style="padding-top: 10px; margin: 0 auto; text-align: center;">
                <div class="col-md-12 gradBack">
                    <span style="float: left;padding-left: 12px;" (click)="backClicked()">
                        <i class="fas fa-arrow-left left-arr-icon fa-2x"></i>
                      </span>
                    <h4 style="font-size: 20px;">Instructional Enhancement Videos</h4>
                </div>
            </div>
        </div>

        <!-- <li class="breadcrumb-item active">Webinar List</li> -->
    </div>
    <br>
</div>

<div class="br-pagebody" style="background-color:white;">
    <div class="row row-sm" style="margin-left: 0;margin-right:0;">
        <div class="col-md-12 showhideTable1">
            <table class="display example1" cellspacing="0" width="100%">
                <thead>
                    <tr>

                        <th>S No</th>
                        <th>Category</th>
                        <th>Title</th>
                        <th>Url</th>
                        <th>Video For</th>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</div>
