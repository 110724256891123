import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {PageLoaderComponent } from './page-loader/page-loader.component'
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';
import { YtplayerComponent } from './ytplayer/ytplayer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { InvoiceTemplateComponent } from './invoice-template/invoice-template.component';
import { TranslatorModule } from '../../translator/index';


@NgModule({
  declarations: [PageLoaderComponent, ConfirmDialogComponent, YtplayerComponent, PageNotFoundComponent, InvoiceTemplateComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslatorModule.forRoot({
      defaultLang: 'en',
      storagePrefix: 'translate-language'
    })
  ],
  providers:[],
  exports: [
    CommonModule,
    FormsModule,
    TranslatorModule,
    PageLoaderComponent,
    ConfirmDialogComponent,
    YtplayerComponent,
    InvoiceTemplateComponent
  ]
})
export class SharedModule { }
