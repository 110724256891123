<div>
  <div class="breadcrumb" >
      <div class="col-md-12">
          <div class="row row-sm" style="padding-top: 10px; margin: 0 auto; text-align: center;">
              <div class="col-md-12 gradBack">
                <span style="float: left;padding-left: 12px;" (click)="backClicked()">
                  <i class="fas fa-arrow-left left-arr-icon fa-2x"></i>
                </span>
                  <h4 style="font-size: 20px;">Activities</h4>
                </div>
      </div>
      </div>
      
      <!-- <li class="breadcrumb-item active">Webinar List</li> -->
  </div>
  <br>
</div>
  
  <div class="br-pagebody" style="background-color:white;">
    <div class="row row-sm" style="margin-left: 0;margin-right:0;">
  
      <div class="col-md-12 showhideTable1">
        <table class="display example1" cellspacing="0" width="100%">
          <thead>
            <tr>
  
              <th>Topic Id</th>
              <th>Activity No</th>
              <th>Title</th>
              <th>Rubrics</th>
              <th>Action</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>

  <div class="modal fade" id="editModel" tabindex="-1" role="dialog" aria-labelledby="editModel" aria-hidden="true">
    <div class="modal-dialog modal-md " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit Content</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <h6>Activity No</h6>
            <input class="form-control form-control-sm" type="text" placeholder="Enter Activity No" name="editActivityNo"
              [(ngModel)]="editActivityNo">
          </div>
          <div class="form-group">
            <h6>Rubrics</h6>
            <input class="form-control form-control-sm" type="text" placeholder="Enter Rubrics" name="editRubrics"
              [(ngModel)]="editRubrics">
          </div>
          <div class="form-group">
            <h6>Sequence</h6>
            <input class="form-control form-control-sm" type="text" placeholder="Enter Sequence" name="editSequence"
              [(ngModel)]="editSequence">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="updateEditDetails()">Update</button>
          </div>
        </div>
  
      </div>
    </div>
  </div>
  <div class="modal fade" id="confirmationModel" tabindex="-1" role="dialog" aria-labelledby="editModel" aria-hidden="true">
    <div class="modal-dialog modal-md " role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel1">Confirm your action</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <h6>Please type 'Delete' in the box below</h6>
            <input class="form-control form-control-sm" type="text" placeholder="Enter your text" name="deleteSpel"
              [(ngModel)]="deleteSpelling">
          </div>
         
         
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="confirmSpelling()">Confirm</button>
          </div>
        </div>
  
      </div>
    </div>
  </div>