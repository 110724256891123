import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AllApisService} from '../all-apis.service';
import {NotifierService} from 'angular-notifier';
import { PlatformLocation } from '@angular/common';
import { Subject } from "rxjs";
import {YtplayerService} from '../shared/ytplayer/ytplayer.service';
import {LessonServiceService} from '../lesson-dashboard/lesson-service.service';
import {ActionPerformedService} from '../shared/action-performed.service';

declare var $:any;
@Component({
  selector: 'app-professional-development',
  templateUrl: './professional-development.component.html',
  styleUrls: ['./professional-development.component.css']
})
export class ProfessionalDevelopmentComponent implements OnInit {

  resetFormSubject: Subject<boolean> = new Subject<boolean>();
  activityList=[];
  content:string;
  contentList=[];
  contentId:string;
  lessonId:string;
  preview=false;
  tag = false;
  pdfSrc;
  urlList=[];
  link;
  studentDetail:any;
  showLoader: boolean = false;
  loaderMessage: string;
  schoolInfo:any;
  activateVideos=false;
  
  linkList=[];
  pendingActiveList=[];
  allActiveList=[];
  lessonInfo:any;
  activatePending=false;
  activateAll=false;
  checkLink:string;
  videoLink:string;
  loadComp=false;
  viewOption :any;
  userDetail : any;
  constructor(private location: PlatformLocation, private route :Router, private allApiServ:AllApisService, private activatedRoute:ActivatedRoute,  private notifier: NotifierService, private ytServ:YtplayerService, private serv : LessonServiceService, private actionPerformedServ: ActionPerformedService) { 
      location.onPopState(() => {
        $("#displayModel").modal('hide');
        $("#pdfModal").modal('hide');
        $("#imgModal").modal('hide');
      });
  }

  ngOnInit() {
    if(typeof localStorage.getItem('studentDetail')!=='undefined' && localStorage.getItem('studentDetail')!==''){
      this.studentDetail=JSON.parse(localStorage.getItem('studentDetail'));
      
    }
    if(typeof localStorage.getItem('userDetail')!=='undefined' && localStorage.getItem('userDetail')!==''){
      this.userDetail=JSON.parse(localStorage.getItem('userDetail'));
      
    }
    if(typeof localStorage.getItem('schoolInfo')!==undefined && localStorage.getItem('schoolInfo')!==""){
      this.schoolInfo=JSON.parse(localStorage.getItem('schoolInfo'));
      // if(this.schoolInfo) {
      //   this.team = this.schoolInfo.team;
      // }
    }
    this.viewOption = this.activatedRoute.snapshot.data.viewOption;
    this.getVideoList();
  }

  
  
  getVideoList(){
    this.serv.getpdVideos()
    .subscribe(res=>{
      if(res['status']){
        console.log(res['result']);
        let obj1;
        if(this.studentDetail) {
          obj1 = this.schoolInfo.standardList.filter(std => std.standard == this.studentDetail['student.std']);
        } else {
          obj1 = this.schoolInfo.standardList.filter(std => (std.variant && std.variant != ''));
        }
        console.log('log', obj1, res['result'])
        if (obj1 && obj1.length) {
          let variant = obj1[0].variant
          this.linkList=res['result'].filter(cl=> cl != null && cl.videoFor === this.viewOption && cl.variant == variant);  
        } else {
          this.linkList=res['result'].filter(cl=> cl != null && cl.videoFor === this.viewOption);      
        }
        }
    })
  }
   

  
  navigateToLink(evt:any){
    // window.location.href=this.linkList[evt]['url'];
    
    
    this.allApiServ.ytLink=this.linkList[evt]['url'].replace('https://www.youtube.com/watch?v=','https://www.youtube.com/embed/');
    this.route.navigate(['/player']);
  }

  activityModel(evt:any){
    let obj = {
      // standard : this.searchObj.standard,
      // subject : this.searchObj.subject,
      userId : this.userDetail.sourceId,
      // lessonId : this.searchObj.lessonId,
      role : this.linkList[evt]['videoFor'],
      action : 'Professional Development',
      lessonTitle : this.linkList[evt]['title']
    }
    if(this.viewOption == 'Parent') obj['action'] = 'Parent Guidance'
    // console.log(obj);
    this.actionPerformedServ.sendActionPerformed(obj)
    .subscribe(res => {
      if(res['status'])console.log('success');
    })
    this.checkLink=this.linkList[evt]['url']
    console.log(this.checkLink);
    this.ytServ.confirmThis(this.checkLink);

  }

  
 

}



