import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { AllApisService } from '../all-apis.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.css']
})
export class StudentDashboardComponent implements OnInit {
  std:string;
  section:string;
  studentId:string;
  studentInfo:any;
  schoolName:any
  constructor(private activeRoute: ActivatedRoute,private router: Router, private allApiService:AllApisService, private _location: Location) { }

  ngOnInit() {
    // if (this.activeRoute.queryParams) {
    //   this.activeRoute.queryParams.subscribe(params => {
    //     this.std=params.std;
    //     this.section=params.section;
    //     this.studentId=params.studentId;
    //   })
    // }
    if(typeof localStorage.getItem('schoolInfo')!==undefined && localStorage.getItem('schoolInfo')!==""){
      this.schoolName = this.allApiService.getSchoolInfo().schoolName;
    }
    if(typeof localStorage.getItem('studentDetail')!==undefined && localStorage.getItem('studentDetail')!==""){
      this.studentInfo=JSON.parse(localStorage.getItem('studentDetail'));
      console.log(this.studentInfo);
      this.std=this.studentInfo.student.std;
        this.section=this.studentInfo.student.section;
        this.studentId=this.studentInfo.sourceId;
    }
    
  }


  navigateToAssessmentPerformance(){
    // console.log();
    this.router.navigate(['/studentResult'], { queryParams: {studentId:this.studentId, std:this.std, section:this.section} });
  }

  navigateToAnswer(){
    // console.log();
    this.router.navigate(['/assessmentList'], { queryParams: { studentId:this.studentId, std:this.std, section:this.section} });
  }

  finishTask() {
    var resObj = {}
    resObj['status'] = true
    resObj['message'] = "Exit"
    resObj['result'] = {}
    console.log('json objec', JSON.stringify(resObj));
    if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.exitViewCallBack.postMessage(JSON.stringify(resObj));
    }
    if (window.Android) {
      window.Android.finishTask(JSON.stringify(resObj));
    }
  }

  backClicked() {
    this._location.back();
  }

}
