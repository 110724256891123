import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import {AllApisService} from '../all-apis.service';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(private http: HttpClient, private allApiService:AllApisService) { }

  addUserDetails(obj:any){
    return this.http.post<any>(this.allApiService.baseurl+'/saveUserInfo',obj,this.allApiService.httpOptions);
  }

  getUserList(){
    return this.http.get<any>(this.allApiService.baseurl+'/userList',this.allApiService.httpOptions);
  }
}
