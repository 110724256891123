import { Component, OnInit } from "@angular/core";
import { FileSelectDirective, FileUploader } from "ng2-file-upload";
import { FileServiceService } from "./file-service.service";
import { saveAs } from "file-saver";
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { AllApisService } from "src/app/all-apis.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { NotifierService } from "angular-notifier";

@Component({
  selector: "app-importexcel",
  templateUrl: "./importexcel.component.html",
  styleUrls: ["./importexcel.component.css"],
})
export class ImportexcelComponent {
  uri = "";
  uploader: FileUploader; // = new FileUploader({url:this.uri});

  attachmentList: any = [];

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  mimeType: any;
  viewOption: any;
  schoolName: any = "";
  testId: string;
  templateType: string;
  instructions: boolean = false;
  session: string = "2023-24";
  constructor(
    private _fileService: FileServiceService,
    public _allApiService: AllApisService,
    private router: Router,
    private route: ActivatedRoute,
    private allApiService: AllApisService,
    private _location: Location,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    if (
      typeof localStorage.getItem("schoolInfo") !== undefined &&
      localStorage.getItem("schoolInfo") !== ""
    ) {
      //this.schoolName = this.allApiService.getSchoolInfo().schoolName;
    }
    this.viewOption = this.route.snapshot.data.viewOption;
    if (this.viewOption === "book-content") {
      this.templateType = "Book Content";
      let params = new HttpParams();
      params = params.append("session", this.session);
      this.allApiService.httpOptions.params = params;
      console.log("this.allApiService", this.allApiService.httpOptions);
      this.uri = this._allApiService.baseurl + "/import/bookContent";
    } else if (this.viewOption === "curriculum") {
      this.templateType = "Curriculum";
      this.uri = this._allApiService.baseurl + "/import/bookCurriculumId";
    } else if (this.viewOption === "module-topic") {
      this.templateType = "Module Topic";
      this.uri = this._allApiService.baseurl + "/import/uploadModuleTopic";
    } else if (this.viewOption === "module-activity") {
      this.templateType = "Module Activity";
      this.uri = this._allApiService.baseurl + "/import/uploadModuleActivity";
    } else if (this.viewOption === "pdVideos") {
      this.templateType = "Instructional Enhancement Videos";
      this.uri = this._allApiService.baseurl + "/import/uploadpdVideos";
    } else if (this.viewOption === "teacher") {
      this.templateType = "Teacher Team Credentials";
      this.uri = this._allApiService.baseurl + "/import/uploadCredentialFile";
    } else if (this.viewOption === "student") {
      this.templateType = "Student Team Credentials";
      this.uri =
        this._allApiService.baseurl + "/import/uploadStudentCredentialFile";
    } else if (this.viewOption === "questions") {
      this.uri = `${this._allApiService.baseurl}/import/uploadQuestions`;
    } else if (this.viewOption == "practicePaper") {
      this.uri = `${this._allApiService.baseurl}/file/addPracticePaper`;
    } else {
      this.templateType = "Module";
      this.uri = this._allApiService.baseurl + "/import/uploadModule";
    }
    console.log("viewpoint", this.viewOption);
    //"headers": [{ 'Authorization': 'Bearer ' + localStorage.getItem('token')}]
    this.uploader = new FileUploader({
      url: this.uri,
      authToken: "Bearer " + localStorage.getItem("token"),
      headers: [{ name: "session", value: this.session }],
    });
    //this.uploader.options.headers  =
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      this.attachmentList.push(JSON.parse(response));
      this.notifier.notify("Success", "Data has been uploaded");
    };
  }

  selectSession() {
    console.log("update");
    this.uploader = new FileUploader({
      url: this.uri,
      authToken: "Bearer " + localStorage.getItem("token"),
      headers: [{ name: "session", value: this.session }],
    });
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      this.attachmentList.push(JSON.parse(response));
      this.notifier.notify("Success", "Data has been uploaded");
    };
  }

  download(index) {
    var filename = this.attachmentList[index].uploadname;

    this._fileService.downloadFile(filename).subscribe(
      (data) => saveAs(data, filename),
      (error) => console.error(error)
    );
  }

  uploadContent() {
    this.router.navigate(["/masterAdmin/upload-S3"]);
  }

  finishTask() {
    var resObj = {};
    resObj["status"] = true;
    resObj["message"] = "Exit";
    resObj["result"] = {};
    console.log("json objec", JSON.stringify(resObj));
    if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.exitViewCallBack.postMessage(
        JSON.stringify(resObj)
      );
    }
    if (window.Android) {
      window.Android.finishTask(JSON.stringify(resObj));
    }
  }

  backClicked() {
    this._location.back();
  }

  downloadTemplate() {
    if (this.viewOption === "book-content")
      window.location.href = this._allApiService.baseurl + "/file/bookContent";
    else if (this.viewOption === "curriculum")
      window.location.href =
        this._allApiService.baseurl + "/file/addCurriculumId";
    else if (this.viewOption === "module-topic")
      window.location.href = this._allApiService.baseurl + "/file/moduleTopic";
    else if (this.viewOption === "module-activity")
      window.location.href =
        this._allApiService.baseurl + "/file/moduleActivity";
    else if (this.viewOption === "pdVideos")
      window.location.href = this._allApiService.baseurl + "/file/pdVideo";
    else if (this.viewOption === "teacher")
      window.location.href =
        this._allApiService.baseurl + "/file/teamCredentials";
    else if (this.viewOption === "student")
      window.location.href =
        this._allApiService.baseurl + "/file/studentTeamCredentials";
    else if (this.viewOption === "paper")
      window.location.href =
        this._allApiService.baseurl + "/file/assessmentTemplate";
    else if (this.viewOption === "editPaper")
      window.location.href =
        this._allApiService.baseurl +
        "/file/editAssessmentTemplate?testId=" +
        this.testId;
    else if (this.viewOption === "questions")
      window.location.href = this._allApiService.baseurl + "/file/questions";
    else window.location.href = this._allApiService.baseurl + "/file/module";
  }
}
