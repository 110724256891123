import { Component, OnInit } from "@angular/core";
import { AllApisService } from "../all-apis.service";
import { Router } from "@angular/router";
import { ConfirmDialogService } from "../shared/confirm-dialog/confirm-dialog.service";
import { NotifierService } from "angular-notifier";
import { YtplayerService } from "../shared/ytplayer/ytplayer.service";
import {
  QuestionListService,
  searchKeys,
} from "../master-admin/question-list/question-list.service";

declare var $: any;
@Component({
  selector: "app-save-exam",
  templateUrl: "./save-exam.component.html",
  styleUrls: ["./save-exam.component.css"],
})
export class SaveExamComponent implements OnInit {
  questionList = [];
  stdList = [];
  categoryList = [];
  seriesList = [];
  subjectList = [];
  chapterList = [];
  topicList = [];
  editId: any;
  editTitle: any;
  editSequence: any;
  searchObj = new searchKeys();
  popup = false;
  linkList: any;
  deletelesson: boolean = false;
  videoLink: any;
  safeVideo: any;
  checkLink: any;
  editLink: string;
  newLink: string;
  editIndex: any;
  lessonIndex: any;
  deleteIndex: any;
  subjectMaps: any;
  sectionList: any[];
  allCategoryList: any;
  allSubjectList: any;
  currentQuestionList = [];
  currentMark = 1;
  marksAlloted = 0;
  examPaperId: any;
  hideModel: boolean;
  hideLeftNav: boolean = false;
  constructor(
    private serv: QuestionListService,
    private allApiServ: AllApisService,
    private route: Router,
    private confirmBox: ConfirmDialogService,
    private notifier: NotifierService,
    private ytServ: YtplayerService
  ) {}

  ngOnInit(): void {
    let localHideLeftNav = localStorage.getItem("hideLeftNav");
    if (localHideLeftNav && localHideLeftNav == "Y") {
      this.hideLeftNav = true;
    }
    if (
      !this.serv.searchObj ||
      !this.serv.searchObj.std ||
      !this.serv.searchObj.subject
    ) {
      let localSearchObj = localStorage.getItem("searchObj");
      if (localSearchObj && localSearchObj != "undefined") {
        this.searchObj = JSON.parse(localSearchObj);
      }
    } else {
      this.searchObj = this.serv.searchObj;
    }
    console.log("this.searchObj", this.searchObj);
  }

  goBack() {
    $("#successModal").modal("hide");
    if (this.hideLeftNav) {
      this.route.navigate(["/admin/create-exam-2"]);
    } else {
      this.route.navigate(["/admin/create-exam"]);
    }
  }

  editQuestion() {
    $("#overflowModal").modal("hide");
    localStorage.setItem("isEdit", "Y");
    if (this.hideLeftNav) {
      this.route.navigate(["/admin/select-questions-2"]);
    } else {
      this.route.navigate(["/admin/select-questions"]);
    }
  }

  checkQuestionTotal() {
    if (this.searchObj.marksAlloted > this.searchObj.maxMarks) {
      $("#overflowModal").modal("show");
    } else {
      this.saveExamPaper();
    }
  }

  saveExamPaper() {
    $("#overflowModal").modal("hide");
    if (
      this.searchObj &&
      this.searchObj.questionGroups &&
      this.searchObj.questionGroups.length
    ) {
      this.serv.saveExamPaper(this.searchObj).subscribe((res) => {
        if (res.status) {
          this.examPaperId = res.examPaperId;
          console.log("result from paper", res);
          $("#successModal").modal("show");
        } else {
          this.notifier.notify("error", "Error, Please try again");
        }
        this.stdList = res.result;
        console.log("this.stdList", this.stdList);
      });
    }
  }

  openAddInstruction() {
    $("#addinsturction").modal({
      backdrop: "static",
      keyboard: false,
    });
    $("#addinsturction").modal("show");
  }

  cancelInstructionDetail() {
    this.searchObj.instructions = "";
    ($("#addinsturction") as any).modal("hide");
  }

  saveInstruction() {
    ($("#addinsturction") as any).modal("hide");
  }

  downloadApplication() {
    this.hideModel = true;
    window.location.href =
      this.allApiServ.baseurl +
      "/downloads/questionPaper?examPaperId=" +
      this.examPaperId;
    $("#successModal").modal("hide");
    if (this.hideLeftNav) {
      this.route.navigate(["/admin/create-exam-2"]);
    } else {
      this.route.navigate(["/admin/create-exam"]);
    }
  }
}
