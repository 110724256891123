import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { TeacherMapService } from '../zoom/teacher-subject/teacher-map.service';
import { ZoomService } from '../zoom/zoom.service';
import { last } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AllApisService } from '../all-apis.service';
import {ConfirmDialogService} from '../shared/confirm-dialog/confirm-dialog.service'
declare var $:any;

@Component({
  selector: 'app-student-credentials',
  templateUrl: './student-credentials.component.html',
  styleUrls: ['./student-credentials.component.css']
})
export class StudentCredentialsComponent implements OnInit {
  standard:any;
  teacherSubList =[];
  teacherList = [];
  teacherListEmail = [];
  stdList = [];
  sectionList = [];
  selectedSection = [];
  requestSectionList = [];
  sectionObj = {};
  dropdownSettings = {
    singleSelection: true,
    idField: 'item_id',
    textField: 'item_text',
    allowSearchFilter: true
  };
  dropdownSettingsSection  = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  std:string;
  displayList=[];
  showLoader: boolean = false;
  schoolInfo:any;
  team=false;
  constructor(private serv: TeacherMapService, private zoomServ: ZoomService, public notifier: NotifierService, private router: Router, private allApiService:AllApisService, private confirmBox:ConfirmDialogService) { }

  ngOnInit() {
    
    
    this.fetchClassSection();
    this.getStudentCredentialList();
    if(typeof localStorage.getItem('schoolInfo')!==undefined && localStorage.getItem('schoolInfo')!==""){
      this.schoolInfo=JSON.parse(localStorage.getItem('schoolInfo'));
      if(this.schoolInfo.team){
        this.team=true;
        
      } 
    }
  }




  fetchClassSection() {
    this.serv.getClassSection()
      .subscribe(res => {
        console.log("class", res);
        var check = {};
        res.standardList.forEach(elem => {
          if (typeof check[elem.standard] === 'undefined') {
            this.stdList.push(elem.standard);
            check[elem.standard] = true;
          }
        })
        // this.stdList = res.standardList;
        this.sectionObj = res.sectionListObj;

      })
  }

  updateSectionList() {
    this.sectionList = this.sectionObj[this.standard];
  }

  updateEventSection(section: any) {
    this.selectedSection.push(section);
  }

  onSelectAllSection(evt: any) {
    console.log('section changed', evt);
    this.selectedSection = evt;
  }

  deleteSection(section: any) {
    console.log('section changed', section);
    for (var i = 0; i < this.selectedSection.length; i++) {
      if (this.selectedSection[i] === section) {
        this.selectedSection.splice(i, 1);
        break;
      }
    }
    
  }



  


  populateTable(active:boolean) {
    let action_btn =  '<i style="padding-left:10px,position:pointer;" class="far fa-trash-alt text-danger text-info delete" title="Delete"></i>'
    let self=this;
    if ($.fn.DataTable.isDataTable(".example")) {
      $('.example').DataTable().clear().destroy();
    };
    $('.showhideTable').css('display', 'block');
    const example = $('.example')
      .DataTable({
        data: this.displayList,
        columns: [
          
          { data: 'name', defaultContent: '<i>Not Set</i>' },
          { data: 'class', defaultContent: '<i>Not Set</i>' },
          { data: 'section', defaultContent: '<i>Not Set</i>' },
          { data: 'username', defaultContent: '<i>Not Set</i>' },
          { data: 'password', defaultContent: '<i>Not Set</i>' },
          // {
          //   data: null,
          //   className: 'center',
          //   defaultContent: action_btn,
            
          // } 

        ],
        // dom: 'Bfrtip',
        //  buttons: [],

        lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']]
      });
      
      
  }

  getStudentCredentialList(){
    this.displayList=[];
    var obj={
      std:this.standard,
      section:this.selectedSection
    }
    console.log(obj);
    this.serv.fetchStudentList(obj)
    .subscribe(res=>{
      console.log('student list', res);
      if(res["status"]){
        res["result"].forEach(elem=>{
          let obj={
            name:elem["firstName"],
            class:elem["std"],
            section:elem["section"],
            username:elem["teamUsername"],
            password:elem["teamPassword"]
          }
          if(typeof elem["lastName"]!== 'undefined' && elem["lastName"]!== '')obj['name']+=" "+elem["lastName"]
          this.displayList.push(obj);
        })
        this.populateTable(true);
      }
     
    })
  }



  
  


  scheduledList() {
    this.router.navigate(['/webinarList']);
    return false;
  }

  moveToSessionList(){
    this.router.navigate(['/webinarList']);
  }

  moveToWebinarSetting(){
    this.router.navigate(['/admin/dashboard/admin']);
  }

  navigateToImport(){
    this.router.navigate(['/importExl/student']);
  }
}

