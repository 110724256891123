<body class="">

  <div class="br-logo">
    <a href="">
      <img src="../../../assets/Navars-Edutech-logo.png">
    </a>
  </div>
  <div class="search-bar">
    <div class="search-icon">
      <i class="searchIcons ion-search"></i>
    </div>
    <input type="text" placeholder="What are you looking for...">
    <div class="close-search">
      <i class="searchIcons ion-ios-close"></i>
    </div>
  </div>
  <div class="br-sideleft overflow-y-auto">

    <div class="br-sideleft-menu ">
      <a href='#/teacher/dashboard/teacherHomepage' class="br-menu-link">
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon2" src="../../../assets/img/apps.png">
          <img class="activeIcon2" src="../../../assets/img/apps_active.png">
          <span class="menu-item-label">Dashboard</span>
        </div>
      </a>

      <a href='#/teacher/dashboard/coursePlanner' class="br-menu-link">
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon1" src="../../../assets/img/book.png">
          <img class="activeIcon1" src="../../../assets/img/book_active.png">
          <span class="menu-item-label">Coursework & Planner</span>
        </div>
      </a>
      <a href='#/teacher/dashboard/teacherLiveClasses' class="br-menu-link">
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon3" src="../../../assets/img/video.png">
          <img class="activeIcon3" src="../../../assets/img/video_active.png">
          <span class="menu-item-label">Schedule Live Classes</span>
        </div>
      </a>

      <a href='#/teacher/dashboard/studentResponse' class="br-menu-link">
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon4" src="../../../assets/img/viewStudentRespones.png">
          <img class="activeIcon4" src="../../../assets/img/progressAtcive.png">
          <span class="menu-item-label">View Student Response</span>
        </div>
      </a>
      <a href='#/teacher/dashboard/progressReport' class="br-menu-link">
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon5" src="../../../assets/img/porgressReport.png">
          <img class="activeIcon5" src="../../../assets/img/progressActive.png">
          <span class="menu-item-label">Progress Report</span>
        </div>
      </a>

      <a href='#/dashboard/teacherProfessionalDevelopment' class="br-menu-link">
        <div class="br-menu-item" aria-disabled="true">
          <img class="deactiveIcon6" src="../../../assets/img/porgressReport.png">
          <img class="activeIcon6" src="../../../assets/img/progressActive.png">
          <span class="menu-item-label">Professional Development</span>
        </div>
      </a>
      <button  class="btn helpButton"><a style="text-decoration: none; color:#1AB67A;" href="tel:(+91) 9999999999"> Help Desk No <br><i class="fas fa-headset" style="padding-right:10px ;"></i>(+91) 9884488580</a> </button>
    </div>
    <!--div class="sidebar-footer"> <a href="" class="link" data-toggle="tooltip" title="" data-original-title="Settings"><i class="fa fa-cog fa-spin"></i></a> <a href="" class="link" data-toggle="tooltip" title="" data-original-title="Email"><i class="fa fa-envelope"></i></a> <a href="" class="link" data-toggle="tooltip" title="" data-original-title="Logout"><i class="fa fa-power-off"></i></a> </div-->
  </div>


  <div class="br-header">
    <div class="br-header-left">
      <div class="navicon hidden-md-down">
        <!-- <a id="btnLeftMenu" href="">
            <i class="fas fa-user-alt"></i>
          </a> -->
      </div>
      <div class="navicon hidden-md-down" >
        <a id="btnAppsMenu" href="javascript:void(0);" data-toggle="dropdown" role="button">
          <i class="icon ion-android-apps tx-22"></i>
        </a>
      </div>
      <div class="navicon hidden-lg-up">
        <a id="btnLeftMenuMobile" href="">
          <i class="fas fa-bars"></i>
        </a>
      </div>
    </div>
    <!-- <div class="br-header-right">
      <div class="navicon-Middle">
        <a href="">
            <i class="fa fa-bell" aria-hidden="true"></i>
          </a>
      </div>
    </div> -->
    <div class="br-header-right">
      <div class="navicon m-3">
        <a href="">
          <i class="fa fa-bell fa-lg" aria-hidden="true"></i>
        </a>
      </div>
      <div class="nameLogo m-3">
          <h6 class="nameFirst">M</h6>
      </div>
      <nav class="nav">
        <div class="dropdown">
          <a href="" class="nav-link nav-link-profile" data-toggle="dropdown">
            <a class="logged-name" style="font-size: 12px;color: #172D4A;">Welcome</a>
            <br>
             <p class="logged-name loginuserName">{{loginuserName}}</p>
            <i class="fas fa-angle-down angleDownICon" style="position: absolute;" *ngIf="role == 'Teacher'"></i></a>
          <!--  <i class="fa fa-user-circle-o" aria-hidden="true" style="font-size: 20px;
               margin-top: 4px;"></i> </a> -->
          <div class="dropdown-menu dropdown-menu-header wd-250 scale-up" *ngIf="role == 'Teacher'">
            <!-- <div class="tx-center">
                  <a href="">
                    <img src="../assets/img1.png" class="wd-80 rounded-circle" alt="">
                  </a>
                  <h6 class="mg-t-15 mg-b-5 tx-gray-800">Student Public School</h6>
                  <p class="tx-12 tx-gray-600">youremail@domain.com</p>
                </div> -->
            <hr>
            <ul class="list-unstyled user-profile-nav">
              <!-- <li>
                    <a href="">
                      <i class="icon ion-ios-people"></i>Teacher Count</a>
                  </li>
                  <li>
                    <a href="">
                      <i class="icon ion-ios-people"></i>Student Count</a>
                  </li>
                  <li>
                    <a href="">
                      <i class="icon ion-android-textsms"></i> SMS Balance</a>
                  </li>
                  <li>
                    <a href="">
                      <i class="icon ion-ios-gear"></i> Global Settings</a>
                  </li>
                  <li>
                    <a href="">
                      <i class="icon ion-locked"></i>Change Password</a>
                  </li> -->
              <li>
                <a href="#/sign-in" (click)='logOut()'>
                  <i class="icon ion-power"></i> Sign Out</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>