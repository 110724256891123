<div class="br-pagebody">
  <div class="row row-sm">
    <div class="scroll col-md-12 table-responsive showhideTable">
      <table
        id="tableID"
        class="example display nowrap"
        cellspacing="0"
        width="100%"
        scrollX="true"
      >
        <thead>
          <tr>
            <td hidden>sr no</td>
            <td>Class</td>
            <td>Subject</td>
            <td>Exam Name</td>
            <td>Duration(in hour)</td>
            <td>Total Marks</td>
            <td>Marks Alloted</td>
            <td>Total Questions</td>
            <td>Creation Date</td>
            <td>Download Paper</td>
            <td>Download Doc</td>
            <td>Answer Sheet</td>
            <td>Action</td>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</div>
