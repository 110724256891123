import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';  
import { Subject } from 'rxjs/Subject';  

@Injectable({
  providedIn: 'root'
})
export class YtplayerService {
  private subject = new Subject<any>();
  constructor() { }
  confirmThis(link: string ){  
    console.log("came here", link);
    this.setConfirmation(link);  
}  
setConfirmation(link: string) {  
  console.log("came here2");
    let that = this;  
    this.subject.next({  
        type: "confirm",  
        link: link, 
        
    });  
    console.log(this.subject);

}  

getMessage(): Observable<any> {  
  console.log('pika')
    return this.subject.asObservable();  
} 
}
