import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AllApisService } from "../all-apis.service";

@Injectable({
  providedIn: "root",
})
export class LessonServiceService {
  baseurl = this.allApisService.baseurl + "/lesson";
  constructor(
    private http: HttpClient,
    private allApisService: AllApisService
  ) {}

  getLesson(obj: any) {
    return this.http.post<any>(
      this.baseurl + "/lessonList",
      obj,
      this.allApisService.httpOptions
    );
  }
  getClass() {
    return this.http.get<any>(
      this.baseurl + "/getClass",
      this.allApisService.httpOptions
    );
  }
  getSubject() {
    return this.http.get<any>(
      this.baseurl + "/getSubject",
      this.allApisService.httpOptions
    );
  }
  updateEditLesson(obj) {
    return this.http.patch<any>(
      this.baseurl + "/editLesson",
      obj,
      this.allApisService.httpOptions
    );
  }
  updateEditContent(obj) {
    return this.http.patch<any>(
      this.baseurl + "/editContent",
      obj,
      this.allApisService.httpOptions
    );
  }

  updateEditActivity(obj) {
    return this.http.patch<any>(
      this.baseurl + "/editActivity",
      obj,
      this.allApisService.httpOptions
    );
  }

  deleteLesson(obj) {
    return this.http.post<any>(
      this.baseurl + "/deleteLesson",
      obj,
      this.allApisService.httpOptions
    );
  }
  deleteContent(obj) {
    return this.http.post<any>(
      this.baseurl + "/deleteContent",
      obj,
      this.allApisService.httpOptions
    );
  }
  deleteActivity(obj) {
    return this.http.post<any>(
      this.baseurl + "/deleteActivity",
      obj,
      this.allApisService.httpOptions
    );
  }

  updateLink(obj) {
    return this.http.patch<any>(
      this.baseurl + "/editUrl",
      obj,
      this.allApisService.httpOptions
    );
  }
  getpdVideos() {
    return this.http.get<any>(
      this.baseurl + "/pdVideos",
      this.allApisService.httpOptions
    );
  }
}
