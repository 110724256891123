<div style="width: 20%;">
  <app-navigation-panel-admin val='studentCredentials'></app-navigation-panel-admin>
</div>

  <div class="container-fluid br-mainpanel">
  
  <div class="col-md-12" *ngIf="!showLoader">
    
<div class="card ">
    <div class="card-body">
        <h5 class="card-title">Student Team Credential</h5>
        <div class="col-md-12">
            <button class="btn btn-primary" (click)="navigateToImport()">
                Import Excel </button>
        </div>
    </div>
</div>

<div class="card ">
    <div class="card-body">
        <div class="row">
            <h5 class="card-title">Student Team Credential List</h5>
              <div class="col-md-12">
                <div class="row">
                  <div class="form-group col-md-2">
                    <select #stdName="ngModel" name="stdName" [(ngModel)]="standard"
                      (change)="updateSectionList()" class="form-control form-control-sm">
                      <option [value]="">Select class</option>
                      <option *ngFor="let std of stdList" [value]="std">{{std}}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-2 form-control-sm">
                    <ng-multiselect-dropdown
                    name="section"
                    [placeholder]="'Select Section'" 
                    [data]="sectionList"
                    [(ngModel)]="selectedSection"
                     [settings]="dropdownSettingsSection"
                     (onSelect)="updateEventSection($event)"
                     (onDeSelect)="deleteSection($event)"> 
                    </ng-multiselect-dropdown> 
                  
                  </div>
                    <div class="form-group col-md-3 form-control-sm"><button class="btn btn-warning commonBtn" (click)="getStudentCredentialList()"><i class="fa fa-refresh" aria-hidden="true"></i>Find</button></div>
                </div>
                </div>
            
        
        </div>
        
        <div class="col-md-12 showhideTable">
            <table class="display example" cellspacing="0" width="100%">
                <thead>
                  <tr>
                    
                    <th>Name</th>
                    <th>class</th>
                    <th>section</th>
                    <th>Team Username</th>
                    <th>Password</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
              </table>
        </div>
    </div>
</div>

  </div>
  </div>
  
  <page-loader *ngIf="showLoader"></page-loader>