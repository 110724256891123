<div>
  <div class="breadcrumb" >
      <div class="col-md-12">
          <div class="row row-sm" style="padding-top: 10px; margin: 0 auto; text-align: center;">
              <div class="col-md-12 gradBack">
                <span style="float: left;padding-left: 12px;" (click)="backClicked()">
                  <i class="fas fa-arrow-left left-arr-icon fa-2x"></i>
                </span>
                  <h4 style="font-size: 20px;">School Teacher Subject Assignment</h4>
                </div>
      </div>
      </div>
      
      <!-- <li class="breadcrumb-item active">Webinar List</li> -->
  </div>
</div>
  
  <div class="br-pagebody" style="background-color:white;">
    <div class="row row-sm" style="display:flex;">
      <div class="col-md-12">
        <form #f="ngForm">      
            <div class="row loopRowDiv">  
              <div class="col-md-2">
                <strong>Select School</strong>
              </div>
                <div class="form-group col-md-6">
                    <ng-multiselect-dropdown
                        [data]="schoolDisplayList"
                        [settings]="dropdownSettings"
                        (onSelect)="updateEventSchool($event)"> 
                    </ng-multiselect-dropdown>
                    <!-- <select class="form-control form-control-sm" #schoolName="ngModel" name="schoolName" [(ngModel)]="schoolDetails" (ngModelChange)="getSchoolDetails()"> 
                        <option *ngFor="let school of schoolList" [ngValue]="school">{{school.name}}({{school.id}})</option>
                    </select> -->
                </div>
            </div>
            </form>
            <div class="col-md-12">
              <div class="card ">
                <div class="card-body">
                    <div class="row">
                        <h5 class="card-title">Teacher subject allotment List
                        <button class="btn btn-warning commonBtn m-2 btn-sm" (click)="getTeacherSubjectList()"><i class="fas fa-sync" aria-hidden="true"></i> Update List</button>
                    </h5>
                    </div>
                    
                    <div class="col-md-12 showhideTable">
                        <table class="display example" cellspacing="0" width="100%">
                            <thead>
                              <tr>
                                
                                <th>Name</th>
                                <th>Class</th>
                                <th>Section</th>
                                <th>Subject</th>
                                <!-- <th>Action</th> -->
                              </tr>
                            </thead>
                          </table>
                    </div>
                </div>
            </div>
          </div>
              
       

      </div>
      </div>
    </div>
