<head>
  <meta charset="utf-8" />
  <title>Chatbot</title>
  <!-- <link rel="stylesheet" href="style.css"> -->
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <!-- Google Fonts Link For Icons -->
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
  />
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0"
  />
  <!-- <script src="script.js" defer></script> -->
</head>
<div
  [class]="false ? 'container br-mainpanel' : 'container'"
  style="background-image: url('../../assets/chatbot/Web-Lightbg.png')"
>
  <!-- <div
    class="row pl-1"
    style="
      margin-top: 2rem;
      margin-left: 2rem;
      display: flex;
      align-items: center;
    "
  >
    <img
      src="../../assets/chatbot/studentmitra-logo1.svg"
      class="headingLogo"
    />
    <h3 class="ml-2 heading" style="vertical-align: middle; flex: 1">
      Please select a book to start
      <span style="color: #f7912a; font-style: italic"
        >your learning journey</span
      >
    </h3>
  </div> -->
  <header>
    <div class="logo-container">
      <img
        src="../../assets/chatbot/studentmitra_circle.svg"
        class="mainLogo-top"
      />
      <h4 class="studentmitra-header-top">
        <span style="color: white">STUDENT</span
        ><span style="color: #f7912a">MITRA</span>
      </h4>
      <p class="series-header-top">
        Please select a book to start
        <span style="font-style: italic">your learning journey</span>
      </p>
    </div>
  </header>
  <div class="row" style="margin: 2rem 2rem" *ngIf="isSeries">
    <div
      class="card col-5 col-md-2"
      *ngFor="let currentSeries of allSeries; let i = index"
    >
      <div
        class="main-title"
        (click)="openChatBot(currentSeries.code, currentSeries.series)"
      >
        <h5 class="sub-title">{{ currentSeries.series }}</h5>
      </div>
      <div
        class="image-container"
        (click)="openChatBot(currentSeries.code, currentSeries.series)"
      >
        <img
          class="card-img"
          [src]="getSubjectIcon(currentSeries.code)"
          alt="Card image"
          (click)="openChatBot(currentSeries.code, currentSeries.series)"
        />
      </div>
    </div>
  </div>
  <h2 *ngIf="!isSeries">No series in this school</h2>
</div>
<div class="modal" id="uploadVideoModel" tabindex="-1">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content" id="modal-container">
      <div class="modal-header" id="modalHeader">
        <!-- <img src="../../assets/modal/upload.svg" class="main-title-image" /> -->
        <h5 class="modal-title" id="modal-main-title">Select Avatar</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="doneUploadVideo()"
          id="modal-title-button"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <p class="model-note mb-3 ps-3">
          I am best represented with the below Avatar
        </p> -->
        <div class="image-row">
          <div class="col-4" (click)="selectPicture('1')">
            <img
              src="../../assets/chatbot/avatar1.svg"
              alt="Picture 1"
              width="100"
            />
          </div>
          <div class="col-4" (click)="selectPicture('2')">
            <img
              src="../../assets/chatbot/avatar2.svg"
              alt="Picture 2"
              width="100"
            />
          </div>
          <div class="col-4" (click)="selectPicture('3')">
            <img
              src="../../assets/chatbot/avatar3.svg"
              alt="Picture 3"
              width="100"
            />
          </div>
        </div>
        <div class="image-row">
          <div class="col-4" (click)="selectPicture('4')">
            <img
              src="../../assets/chatbot/avatar4.svg"
              alt="Picture 4"
              width="100"
            />
          </div>
          <div class="col-4" (click)="selectPicture('5')">
            <img
              src="../../assets/chatbot/avatar5.svg"
              alt="Picture 5"
              width="100"
            />
          </div>
          <div class="col-4" (click)="selectPicture('6')">
            <img
              src="../../assets/chatbot/avatar6.svg"
              alt="Picture 6"
              width="100"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<body [ngClass]="{ 'show-chatbot fullscreen': showChatbot }">
  <!-- <button class="chatbot-toggler" (click)="closeChatbot()">
    <span class="material-symbols-rounded">mode_comment</span>
    <span class="material-symbols-outlined">close</span>
  </button> -->
  <div
    class="chatbot"
    #chatbot
    id="chatbot-screen"
    style="background-image: url('../../assets/chatbot/Web-Lightbg.png')"
  >
    <header>
      <div class="logo-container">
        <img
          src="../../assets/chatbot/studentmitra_circle.svg"
          class="mainLogo"
        />
        <span
          class="close-btn material-symbols-outlined"
          (click)="closeChatbot()"
          >close</span
        >
        <h4 class="studentmitra-header">StudentMitra</h4>
        <p class="series-header">{{ selectedSeries }}</p>
      </div>
    </header>
    <!-- <div class="blue-row"></div>
    <div class="orange-row">
      <h4 class="series-name">{{ selectedSeries }}</h4>
    </div> -->

    <ul class="chatbox" #chatbox>
      <li class="chat incoming">
        <span class="material-symbols-outlined"
          ><img src="../../assets/chatbot/chatbotavatar.svg" />
        </span>
        <p style="color: white; margin-top: 40px; font-size: 0.8rem">
          Hi there 👋<br />How can I help you today?
        </p>
      </li>
    </ul>
    <div class="chat-input">
      <textarea
        placeholder="Enter a message..."
        spellcheck="false"
        required
        #chatInput
        (input)="onInputChange()"
        (keydown)="onKeyDown($event)"
      ></textarea>
      <span
        class="download-pdf"
        (click)="downloadChatAsText()"
        style="visibility: visible"
        title="Download chat"
      >
        <i class="fa fa-download"></i>
      </span>
      <span
        id="send-btn"
        class="material-symbols-rounded"
        style="visibility: visible"
        (click)="handleChat()"
        >send</span
      >
    </div>
  </div>
</body>
