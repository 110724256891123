<div style="width: 20%;" *ngIf='viewOption==="Parent"'>
    <app-navigation-panel val='forParents'></app-navigation-panel>
</div>

<div style="width: 20%;" *ngIf='viewOption==="Teacher"'>
    <app-navigation-panel-teacher val='professionalDevelopment'></app-navigation-panel-teacher>
</div>
<div class="container-fluid br-mainpanel">
    <div class="row">
        <div class="col-md-12">
            <p class="pageTitle">Dashboard</p>
            <p class="pageSubtitle" *ngIf='viewOption==="Teacher"'>Professional Development</p>
            <p class="pageSubtitle" *ngIf='viewOption==="Parent"'>Parent Videos</p>
        </div>



        <div class="col-md-12" >
            <div class="singleRowContainer m-2" *ngFor='let link of linkList; let i=index;'>
                <div class="row row-sm sigleActiveClassContainer">
                    <div class="form-group col-md-12">
                        <p class="activeClassCardHeader"> {{link?.title}} </p>
                        
                    </div>
                    <div class="form-group col-md-12" (click)="activityModel(i)">
                        <a class="activeClassCardAnchor">Click to see video -></a>
                    </div>
                    
                </div>
            </div>
        </div>

    </div>
</div>

<page-loader *ngIf="showLoader" [loaderMsg]="loaderMessage"></page-loader>