<div class="modal fade" id="pdfPreview" tabindex="-1" role="dialog" aria-labelledby="pdfPreviewTitle" aria-hidden="true" style="padding-top: 70px;">
    <div class="modal-dialog modal-xl " role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="pdfPreviewTitle">Invoice</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" id="iframe-body" style="text-align: center;">
                <div id="wholeTable">
                    <div class="header">
                        <h3>Tax Invoice</h3>
                    </div>
                    <table class="headerTable">
                        <tr>
                            <td rowspan="2" style="width: 10%">
                                <img src="../../assets/Navars-Edutech-logo.png" style="width: 100px;">
                            </td>
                            <td rowspan="2" style="width: 40%;font-size: 16px;"><span style="font-weight: bold;">EZ
                                    Vidya Private Limited</span><br>
                                #61, Kamdar Nagar, 2nd Street, Nungambakkam, Chennai.
                                Tamil Nadu , India - 600 034.
                                GSTIN/UIN: 33AABCE0872E1Z4
                                State Name : Tamil Nadu, Code : 33
                                CIN: U72200TN2001PTC46998</td>
                            <td style="width: 30%">Invoice No.<br>CH/{{transactionDetails.invoiceNo}}/20-21</td>
                            <td style="width: 20%">Dated:<br>{{transactionDetails.date}}</td>
                        </tr>
                        <tr style="height: 45px;">
                            <td></td>
                            <td></td>
                        </tr>
                    </table>
                    <table class="billUser">
                        <tr>
                            <td colspan="2" style="width: 52.2%;"><span style="font-weight: bold;">Buyer / Bill
                                    to: {{transactionDetails.merchant}}</span></td>
                            <td></td>
                            <td></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td style="width: 14%;"><span style="font-weight: bold;">School Name</span></td>
                            <td style="width:38.3%">{{transactionDetails.schoolInfo?.schoolName}}</td>
                            <td colspan="3">Email ID: </td>
                        </tr>
                        <tr>
                            <td style="width: 14%;">Address line 1</td>
                            <td style="width:38.3%">{{transactionDetails.schoolInfo?.address?.line1}}</td>
                            <td colspan="3">Contact Number:</td>
                        </tr>
                        <tr>
                            <td style="width: 14%;">Address line 2</td>
                            <td style="width:38.3%">{{transactionDetails.schoolInfo?.address?.line2}}</td>
                            <td style="width:16%"></td>
                            <td style="width:16%"></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="width: 14%;">City</td>
                            <td style="width:38.3%">{{transactionDetails.schoolInfo?.address?.city}}</td>
                            <td style="width:16%"></td>
                            <td style="width:16%"></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="width: 14%;">State</td>
                            <td style="width:38.3%">{{transactionDetails.schoolInfo?.address?.state}}</td>
                            <td style="width:16%"></td>
                            <td style="width:16%"></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="width: 14%;">Pincode</td>
                            <td style="width:38.3%">{{transactionDetails.schoolInfo?.address?.pincode}}</td>
                            <td style="width:16%"></td>
                            <td style="width:16%"></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="width: 14%;">GSTIN2:</td>
                            <td style="width:38.3%">{{transactionDetails.schoolInfo?.gstIn}}</td>
                            <td style="width:16%"></td>
                            <td style="width:16%"></td>
                            <td></td>
                        </tr>
                    </table>
                    <table class="descriptionTable">
                        <tr style="font-weight: bold;text-align: center;">
                            <td style="width: 37.9%;">Description of Goods and Services</td>
                            <td style="width: 14.3%">HSN/SAC</td>
                            <td style="width:16%">No. of License / Unit</td>
                            <td style="width:16%">Rate per License / Unit</td>
                            <td>Amount</td>
                        </tr>
                        <tr style="font-weight: bold;text-align: center;padding:5px 10px"
                            *ngFor=" let product of transactionDetails.product">
                            <td style="width: 37.9%;">{{product.productType}}</td>
                            <td style="width: 14.3%"></td>
                            <td style="width:16%">{{product.quantity}}</td>
                            <td style="width:16%">{{product.ppq}}</td>
                            <td>{{product.amount}}</td>
                        </tr>

                        <tr style="font-weight: bold;text-align: center;">
                            <td style="width: 37.9%;">Total</td>
                            <td style="width: 14.3%"></td>
                            <td style="width:16%"></td>
                            <td style="width:16%">Total</td>
                            <td>{{transactionDetails.amount}}</td>
                        </tr>
                    </table>

                    <table class="totalDetailTable">
                        <tr>
                            <td style="width: 12.6%;"></td>
                            <td style="width: 12.6%;"></td>
                            <td style="width: 12.6%;"></td>
                            <td style="width: 14.3%"></td>
                            <td style="width:16%">SGST</td>
                            <td style="width:16%">9%</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="width: 12.6%;"></td>
                            <td style="width: 12.6%;"></td>
                            <td style="width: 12.6%;"></td>
                            <td style="width: 14.3%"></td>
                            <td style="width:16%">CGST</td>
                            <td style="width:16%">9%</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td style="width: 12.6%;"></td>
                            <td style="width: 12.6%;"></td>
                            <td style="width: 12.6%;"></td>
                            <td style="width: 14.3%"></td>
                            <td style="width:16%">IGST</td>
                            <td style="width:16%">18%</td>
                            <td>{{transactionDetails.gst}}</td>
                        </tr>
                        <tr>
                            <td style="width: 12.6%;"></td>
                            <td style="width: 12.6%;"></td>
                            <td style="width: 12.6%;"></td>
                            <td style="width: 14.3%"></td>
                            <td colspan="2" style="font-weight: bold;">Total Amount</td>
                            <td>{{transactionDetails.total}}</td>
                        </tr>
                        <tr>
                            <td style="text-align: left;height: 50px;border: 1px solid;vertical-align: top" colspan="7">
                                <u style="font-weight: bold;">Amount Chargeable (in words):</u><span
                                    style="padding-left: 5px;">{{this.transactionDetails['wordAmount']}}</span></td>
                        </tr>
                        <tr>
                            <td colspan="4">{{transactionDetails.description}}</td>
                            <td colspan="3" style="font-weight: bold;text-align: left;">
                                <div>For EZ Vidya Private Limited</div>
                                <div style="height: 62px;"></div>
                                <div>Authorised Signatory</div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="7"></td>
                        </tr>
                        <tr>
                            <td colspan="7">This is a Computer Generated Invoice and Receipt which doesn't requried
                                signature.</td>
                        </tr>
                    </table>
                    <div style="margin-top: 10px;">
                        <table style="width: 50%" class="totalDetailTable">
                            <tr>
                                <td colspan="3"><b>Payment Receipt</b></td>
                            </tr>
                            <tr style="font-weight: bold;">
                                <td style="width: 30%;">Transaction ID</td>
                                <td style="width: 30%;">Amount</td>
                                <td>Transaction Status</td>
                            </tr>
                            <tr style="font-weight: bold;">
                                <td style="width: 30%;">{{transactionDetails.paymentId}}</td>
                                <td style="width: 30%;">{{transactionDetails.total}}</td>
                                <td>{{transactionDetails.paymentStatus}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>