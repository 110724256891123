import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationPanelComponent } from './navigation-panel/navigation-panel.component';
import { NavigationPanelAdminComponent } from './navigation-panel-admin/navigation-panel-admin.component';
import { NavigationPanelTeacherComponent } from './navigation-panel-teacher/navigation-panel-teacher.component';




@NgModule({
  declarations: [NavigationPanelComponent, NavigationPanelAdminComponent, NavigationPanelTeacherComponent],
  imports: [
    CommonModule
  ],
  exports : [NavigationPanelComponent, NavigationPanelAdminComponent, NavigationPanelTeacherComponent]
})
export class NavigationBarModule { }
