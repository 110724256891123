<div class="container">
  <!-- Breadcrumb step -->
  <div class="row">
    <h3 class="title my-3">Let's Create Question Set</h3>
    <div class="col-12 col-md-12 parent my-3 d-flex">
      <div class="child1 col-2 col-md-2">
        <span class="rounded-circle rcircle p-3 pb-2 pt-2">1</span>
        <label>Create Exam</label>
      </div>
      <div class="child1 col-1 col-md-1">
        <hr class="between" />
      </div>
      <div class="child1 col-2 col-md-2">
        <span class="rounded-circle rcircle p-3 pb-2 pt-2" id="activecircle"
          >2</span
        >
        <label>Choose Subject</label>
      </div>
      <div class="child1 col-1 col-md-1">
        <hr class="between" />
      </div>
      <div class="child1 col-2 col-md-2">
        <span class="rounded-circle rcircle p-3 pb-2 pt-2">3</span>
        <label>Select Questions</label>
      </div>
      <div class="child1 col-1 col-md-1">
        <hr class="between" />
      </div>
      <div class="child1 col-3 col-md-3">
        <span class="rounded-circle rcircle p-3 pb-2 pt-2">4</span>
        <label>Create Question Set</label>
      </div>
    </div>
  </div>

  <!-- <div class="row my-3"> -->
  <form class="row my-3">
    <div *ngIf="!edit" class="form-group col-md-3">
      <label for="inputState" class="form-label">Select Class</label>
      <select
        #stdName_var="ngModel"
        name="std"
        [(ngModel)]="searchObj.std"
        class="form-select form-control form-control-sm"
        (change)="updateSubject()"
      >
        <option [value]="">Select Class</option>
        <option *ngFor="let std of stdList" [selected]="searchObj.std == std">
          {{ std }}
        </option>
      </select>
    </div>
    <div *ngIf="edit" class="form-group col-md-3">
      <label for="inputState" class="form-label">Selected Class</label>
      <input
        #examname_var="ngModel"
        name="selectedClass"
        id="inputState"
        type="text"
        readonly
        placeholder="Selected Class"
        [(ngModel)]="searchObj.std"
        class="form-input form-control form-control-sm"
      />
    </div>

    <div *ngIf="!edit" class="form-group col-md-3">
      <label for="inputState" class="form-label">Select Subject</label>
      <select
        #subject_var="ngModel"
        name="subject"
        [(ngModel)]="searchObj.subject"
        class="form-select form-control form-control-sm"
        (change)="updateSeriesList()"
      >
        <option [value]="">Select Subject</option>
        <option
          *ngFor="let sub of subjectList"
          [selected]="searchObj.subject == sub"
        >
          {{ sub }}
        </option>
      </select>
    </div>
    <div *ngIf="edit" class="form-group col-md-3">
      <label for="inputState" class="form-label">Selected Subject</label>
      <input
        #examname_var="ngModel"
        name="Selected Subject"
        id="inputState"
        type="text"
        readonly
        placeholder="Selected Subject"
        [(ngModel)]="searchObj.subject"
        class="form-input form-control form-control-sm"
      />
    </div>
    <div class="form-group col-md-3">
      <label for="inputState" class="form-label">Select Series</label>
      <select
        #series_var="ngModel"
        name="series"
        [(ngModel)]="searchObj.series"
        class="form-select form-control form-control-sm"
        (change)="updateChapterList()"
      >
        <option [value]="">Select Series</option>
        <option
          *ngFor="let ser of seriesList"
          [selected]="searchObj.series == ser"
        >
          {{ ser }}
        </option>
      </select>
    </div>
    <div class="form-group col-md-3">
      <label for="inputState" class="form-label">Select Chapters</label>
      <select
        #chapter_var="ngModel"
        name="chapterNo"
        [(ngModel)]="searchObj.chapterNo"
        class="form-select form-control form-control-sm"
        (change)="updateTopicList()"
      >
        <option [value]="">Select Chapters</option>
        <option
          *ngFor="let chapterNo of bookChapterNos"
          [value]="chapterNo"
          [selected]="searchObj.chapterNo == chapterNo"
        >
          {{ getChapterName(chapterNo) }}
        </option>
      </select>
    </div>

    <div class="form-group col-md-3">
      <label for="inputState" class="form-label">Select Topics</label>
      <ng-multiselect-dropdown
        [placeholder]="'Select Topics'"
        [settings]="dropdownSettings"
        [data]="topicList"
        [(ngModel)]="searchObj.topic"
        name="topic"
      >
      </ng-multiselect-dropdown>
    </div>

    <div class="form-group col-md-3">
      <label for="inputState" class="form-label">Exam Name</label>
      <input
        #examname_var="ngModel"
        name="examName"
        type="text"
        id="inputState"
        readonly
        placeholder="Exam Name"
        [(ngModel)]="searchObj.examName"
        class="form-input form-control form-control-sm"
      />
    </div>
    <button
      type="button"
      class="btn col-md-1 btn-sm"
      id="sbbtn"
      (click)="goToSelectQuestons()"
    >
      &#8594;
    </button>
  </form>

  <!-- </div> -->
</div>
<div
  id="alertModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  data-keyboard="false"
  data-backdrop="static"
  style="margin-top: 0px; padding: 0px"
>
  <!-- class="modal hide fade"    data-controls-modal="#alertModal" -->
  <div class="modal-dialog modal-lg" style="margin-top: 0px; padding: 0px">
    <div style="margin-top: 0px; padding: 0px">
      <div style="margin-top: 0px; padding: 0px">
        <page-loader
          *ngIf="showLoader"
          [loaderMsg]="loaderMessage"
        ></page-loader>
      </div>
    </div>
  </div>
</div>
