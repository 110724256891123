import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { AllApisService } from "../all-apis.service";

@Injectable({
  providedIn: "root",
})
export class MasterAdminService {
  baseurl = this.allApiService.baseurl + "/s3Upload";

  constructor(
    private http: HttpClient,
    private allApiService: AllApisService
  ) {}

  saveFile(reqFile: any, folder: any, userName: any) {
    let formData = new FormData();
    if (reqFile) {
      formData.append("content_file", reqFile);
    }
    formData.append("folder", folder);
    formData.append("userName", userName);
    return this.http.post<any>(
      this.baseurl + "/addFile",
      formData,
      this.allApiService.httpOptions
    );
  }

  saveContent(reqFile: any) {
    let formData = new FormData();
    if (reqFile) {
      formData.append("content_file", reqFile);
    }
    return this.http.post<any>(
      this.baseurl + "/addContent",
      formData,
      this.allApiService.httpOptions
    );
  }

  getFolders(userName: any) {
    let params = new HttpParams();
    params = params.append("userName", userName);
    this.allApiService.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/getFolders",
      this.allApiService.httpOptions
    );
  }

  saveToDb(reqObj: any) {
    return this.http.post<any>(
      this.baseurl + "/saveTODb",
      reqObj,
      this.allApiService.httpOptions
    );
  }

  getAllContent(userName: any) {
    let params = new HttpParams();
    params = params.append("userName", userName);
    this.allApiService.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/allContent",
      this.allApiService.httpOptions
    );
  }

  getS3Url(url: any) {
    let params = new HttpParams();
    params = params.append("url", url);
    this.allApiService.httpOptions.params = params;
    return this.http.get<any>(
      this.baseurl + "/getS3Url",
      this.allApiService.httpOptions
    );
  }
}
